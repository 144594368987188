import React from 'react';

interface Props {

}

const PurchasesPurchaseHistoryTab = ({}: Props) => {
    return (
        <h3>Purchase History</h3>
    )
}

export default PurchasesPurchaseHistoryTab;