import React, {useState} from 'react';
import * as yup from "yup";
import {Alert, Button, Card, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {
    CustomerAddressFormState,
    CustomerFormAction,
    CustomerFormActionType
} from "../../../../../services/customer/CustomerFormService";
import {CaretDownFill, CaretUpFill, Trash} from "react-bootstrap-icons";

interface Props {
    addresses: CustomerAddressFormState[]
    dispatch: (x: CustomerFormAction) => void
}

const CustomerAddressesForm = ({addresses, dispatch}: Props) => {

    const [closedIds, setClosedIds] = useState<string[]>([]);

    const schema = yup.object().shape({
        lineOne: yup.string().required("Line One is required"),
        lineTwo: yup.string(),
        lineThree: yup.string(),
        lineFour: yup.string(),
        lineFive: yup.string(),
        postCode: yup.string(),
    });

    const addressUpdate = (id: string, field: 1 | 2 | 3 | 4 | 5 | 6, value: string) => {
        dispatch({
            type: CustomerFormActionType.ADDRESS_UPDATE,
            value: value,
            id: id,
            field: field
        });
    }

    return (
        <Card className="justify-content-md-center productForm_card">
            <Card.Body className="productForm_card_sectionContainer">
                Addresses
            </Card.Body>
            {addresses.map((a) => {
                return <Card.Body>
                    <Formik
                        onSubmit={() => {
                        }}
                        validationSchema={schema}
                        initialValues={{
                            lineOne: a.lineOne,
                            lineTwo: a.lineTwo,
                            lineThree: a.lineThree,
                            lineFour: a.lineFour,
                            lineFive: a.lineFive,
                            postCode: a.postCode,
                        }}
                    >
                        {({
                              handleChange,
                              handleBlur,
                              values = {
                                  lineOne: a.lineOne,
                                  lineTwo: a.lineTwo,
                                  lineThree: a.lineThree,
                                  lineFour: a.lineFour,
                                  lineFive: a.lineFive,
                                  postCode: a.postCode,
                              },
                              touched,
                              errors
                          }) => (
                            <Form className="customerForm_card_form" noValidate>
                                <Form.Label style={{ width: '100%' }}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                        <div>
                                            {closedIds.includes(a.id) ?
                                                <CaretDownFill
                                                    onClick={() => setClosedIds(closedIds.filter(c => c != a.id))} /> :
                                                <CaretUpFill onClick={() => {
                                                    let ids = [...closedIds]
                                                    ids.push(a.id)
                                                    setClosedIds(ids)
                                                }}/>}
                                            {!!a.lineOne && <span style={{ marginLeft: '0.5em' }}>{a.lineOne} </span>}
                                            {!a.lineOne && <span style={{ marginLeft: '0.5em' }}>Address </span>}
                                        </div>
                                        <Trash className={"customerForm_deleteIcon"} onClick={() => {
                                            dispatch({
                                                type: CustomerFormActionType.REMOVE_ADDRESS,
                                                value: a.id
                                            })
                                        }}/>
                                    </div>
                                </Form.Label>
                                {!closedIds.includes(a.id) && <div>

                                    <Form.Group as={Row} className="mb-3" controlId="customerAddressForm.lineOne">
                                        <Form.Control
                                            required
                                            className="form-input"
                                            type="text"
                                            name="lineOne"
                                            placeholder="Line One"
                                            value={a.lineOne}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                addressUpdate(a.id, 1, e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                        {touched.lineOne && errors.lineOne && (
                                            <Alert variant="danger">
                                                {errors.lineOne}
                                            </Alert>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerAddressForm.lineTwo">
                                        <Form.Control
                                            className="form-input"
                                            type="text"
                                            name="lineTwo"
                                            placeholder="Line Two"
                                            value={a.lineTwo}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                addressUpdate(a.id, 2, e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerAddressForm.lineThree">
                                        <Form.Control
                                            className="form-input"
                                            type="text"
                                            name="lineThree"
                                            placeholder="Line Three"
                                            value={a.lineThree}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                addressUpdate(a.id, 3, e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerAddressForm.lineFour">
                                        <Form.Control
                                            className="form-input"
                                            type="text"
                                            name="lineFour"
                                            placeholder="Line Four"
                                            value={a.lineFour}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                addressUpdate(a.id, 4, e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerAddressForm.lineFive">
                                        <Form.Control
                                            className="form-input"
                                            type="text"
                                            name="lineFive"
                                            placeholder="Line Five"
                                            value={a.lineFive}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                addressUpdate(a.id, 5, e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="customerAddressForm.postCode">
                                        <Form.Control
                                            className="form-input"
                                            type="text"
                                            name="postCode"
                                            placeholder="Postcode"
                                            value={a.postCode}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                addressUpdate(a.id, 6, e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </Form.Group>
                                </div>}
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            })}
            <Button style={{width: 'fit-content', margin: 16}} onClick={() => dispatch({
                type: CustomerFormActionType.NEW_ADDRESS
            })}>Add Address</Button>
        </Card>
    )
}

export default CustomerAddressesForm;