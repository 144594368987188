import React from "react";
import AppPage from "../components/page/AppPage";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import AllStatementsTable from "./parts/alltable/AllStatementsTable";
interface Props {}
const Statements = ({}: Props) => {
  return (
    <AppPage>
      <>
        <h3 className={"heading"}>Statements</h3>

        <Breadcrumb>
          <BreadcrumbItem active>Statements</BreadcrumbItem>
        </Breadcrumb>
        <AllStatementsTable />
      </>
    </AppPage>
  );
};

export default Statements;
