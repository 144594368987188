import React, {useEffect, useState} from "react";
import AppPage from "../../components/page/AppPage";
import {
    Breadcrumb,
    BreadcrumbItem,
    Container,
    Spinner,
} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {CUSTOMERS} from "../../PlatformStack";
import {
    Customer,
    CustomerService,
} from "../../../../services/customer/CustomerService";
import {
    PagedCustomerContacts,
    CustomerContactService,
} from "../../../../services/customer/CustomerContactService";
import {
  CustomerAddressService, PagedCustomerAddress,
} from "../../../../services/customer/CustomerAddressService";
import {logApiError} from "../../../../services/api/ApiUtils";
import CustomerTabs from "../parts/CustomerTabs";


interface Props {
    activeTab?: string;
}

const SpecificCustomerDetails = ({activeTab}: Props) => {
    const {id} = useParams();
    const navigate = useNavigate();

    const {getCustomer} = CustomerService();
    const {getAddresses} = CustomerAddressService();
    const {getContacts} = CustomerContactService();
    const [loading, setLoading] = useState<boolean>();
    const [customer, setSetCustomer] = useState<Customer>();
    const [customerAddresses, setCustomerAddress] = useState<PagedCustomerAddress>();
    const [customerContact, setCustomerContact] = useState<PagedCustomerContacts>();

    const loadCustomer = async (customerId?: string) => {
        if (loading || !customerId) {
            return;
        }

        try {
            const resp = await getCustomer(customerId);
            setSetCustomer(resp);
        } catch (e) {
            logApiError("Error loading Customer.", e);
        }

        try {
            const customerAddressesResp = await getAddresses(customerId);
            setCustomerAddress(customerAddressesResp);
        } catch (e) {
            logApiError("Error Customer Address.", e);
        }
        try {
            const customerContactResp = await getContacts(customerId);
            setCustomerContact(customerContactResp);
        } catch (e) {
            logApiError("Error Customer Contact.", e);
        }

        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        loadCustomer(id).finally(() => setLoading(false));
    }, []);

    return (
        <AppPage>
            <Container>
                {loading && (
                    <>
                        <Spinner animation={"border"}/>
                        <p>Loading...</p>
                    </>
                )}
                {!loading && id && customer && customerContact && customerAddresses &&(
                    <>
                        <h3 className="heading">{customer.name}</h3>

                        <Breadcrumb>
                            <BreadcrumbItem onClick={() => navigate(CUSTOMERS.uri)}>
                                Customers
                            </BreadcrumbItem>
                            <BreadcrumbItem active>{customer.name} </BreadcrumbItem>
                        </Breadcrumb>

                        <CustomerTabs
                            customer={customer}
                            customerContact={customerContact}
                            customerAddresses={customerAddresses}
                            activeKey={activeTab}
                        />
                    </>
                )}
            </Container>
        </AppPage>
    );
};

export default SpecificCustomerDetails;
