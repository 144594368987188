import { PagedResource } from "../api/common/Types";
import ApiClient from "../api/ApiClient";
import { Data } from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import { OrderLine } from "../order/OrderService";
import { CustomerAddress } from "../customer/CustomerAddressService";

export interface QuoteTable {
    active: QuoteTableRow[];
    settled: QuoteTableRow[];
}

export interface QuoteDetail {
    id?: string;
    documentId: string;
    ref: string;
    status: string;
    note: string;
    created: Date;
    expires: Date;

    customerName: string;
    shippingAddress: CustomerAddress;
    billingAddress: CustomerAddress;

    orderId: string;
    lines: OrderLine[];

    total: string;
    totalWithoutTax: string;
    taxAmount: string;
}

export interface QuoteTableRow {
    orderId: string;
    quoteId: string;
    customerId: string;
    lineCount: number;
    totalPrice: string;
    customerName: string;
    status: string;
    ref: string;

    expires: string;
}

export interface OrderedItems extends Data {
    title: string;
    unitPrice: number;
    quantity: number;
    length: number;
    totalPrice: string;
}

export interface QuoteBase {
    id?: string;
    customerId: string;
    documentId: string;
    ref: string;
    status: string;
    note: string;
    expires: Date;
}

export interface Quote extends QuoteBase {
    id: string;
}

export interface PagedQuote extends PagedResource {
    resource: Quote[];
}

export const QuoteService = () => {
    const getAll = async (): Promise<PagedQuote> => {
        return ApiClient.getInstance().post<PagedQuote>(`/api/1/quotes-search`, {
            size: 100
        });
    };

    const get = async (quoteId: string): Promise<QuoteDetail> => {
        return ApiClient.getInstance().get<QuoteDetail>(`/api/1/quotes/${quoteId}`);
    };

    const getTable = async (): Promise<QuoteTable> => {
        return ApiClient.getInstance().get<QuoteTable>(`/api/1/quote-table`);
    };

    const create = async (
        customerId: string,
        status: string,
        note: string
    ): Promise<Quote> => {
        return ApiClient.getInstance().post<Quote>("/api/1/quotes", {
            customerId,
            status,
            note
        });
    };

    const update = async (quoteId: string, quote: QuoteBase): Promise<Quote> => {
        return ApiClient.getInstance().put<Quote>(`/api/1/quote/${quoteId}`, quote);
    };

    return {
        getQuotes: getAll,
        getQuote: get,
        getQuoteTable: getTable,
        createQuote: create,
        updateQuote: update
    };
};
