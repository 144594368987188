import { useEffect, useState } from "react";
import RefreshableSearchablePageableTable from "../../../../../components/table/refreshableSearchablePageableTable/RefreshableSearchablePageableTable";
import { CustomerInvoiceTableService } from "../../../../../services/customer/CustomerInvoiceTableService";
import { InvoiceTableRow } from "../../../../../services/invoice/InvoiceService";

interface Props {
  customerId: string;
  updateInvoiceRow: (value: InvoiceTableRow) => void;
}

const CustomerInvoiceTable = ({ customerId, updateInvoiceRow }: Props) => {
  const { getInvoicesForTable, columns, filter } =
    CustomerInvoiceTableService();

  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceTableRow[]>([]);
  const [data, setData] = useState<InvoiceTableRow[]>([]);
  const [cursor, setCursor] = useState<object>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    setData(invoices);
  }, [invoices]);

  const onCheckboxClick = (invoice: InvoiceTableRow) => {
    updateInvoiceRow(invoice);
  };
  const showContactForm = (custId: string, quoteId: string) => {
    console.log(custId, quoteId);
  };

  const loadTable = async (size: number) => {
    setInvoices([]);
    let invoiceTable = await getInvoicesForTable(
      setLoading,
      size,
      customerId,
      onCheckboxClick,
      showContactForm,
      cursor
    );
    setInvoices(invoiceTable.rows);
    setTotalCount(invoiceTable.totalCount);
    setPageCount(invoiceTable.pageCount);
    setCursor(invoiceTable.cursor);
  };

  const search = (val: string) => {
    setData(filter(invoices, val));
  };

  return (
    <RefreshableSearchablePageableTable
      totalCount={totalCount}
      pageCount={pageCount}
      data={data}
      columns={columns}
      loading={loading}
      loadTable={loadTable}
      filterFunction={(searchValue) => search(searchValue)}
    />
  );
};

export default CustomerInvoiceTable;
