import React, {useEffect, useState} from 'react';
import AppPage from "../../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem, Container, Spinner} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {PRODUCTS} from "../../PlatformStack";
import {Product, ProductService} from "../../../../services/product/ProductService";
import {logApiError} from "../../../../services/api/ApiUtils";
import ProductForm from "../parts/form/ProductForm";

interface Props {

}

const EditProduct = ({}: Props) => {

    const { id } = useParams();
    const navigate = useNavigate();

    const { getProduct } = ProductService();

    const [loading, setLoading] = useState<boolean>();
    const [product, setProduct] = useState<Product>();

    useEffect(() => {
        if (loading || !id) {
            return;
        }

        const loadProduct = async (productId: string) => {
            setLoading(true);
            try {
                const resp = await getProduct(productId);
                setProduct(resp);
            } catch (e) {
                logApiError('Error loading product.', e);
            } finally {
                setLoading(false);
            }
        }

        loadProduct(id);
    }, []);

    return (
        <AppPage>
            <Container>
                {loading && <>
                    <Spinner animation={"border"} />
                    <p>Loading...</p>
                </>}
                {!loading && id && product && <>
                    <h3 className="heading">{product.title}</h3>

                    <Breadcrumb>
                        <BreadcrumbItem onClick={() => navigate(PRODUCTS.uri)}>Products</BreadcrumbItem>
                        <BreadcrumbItem active>{product.title}</BreadcrumbItem>
                    </Breadcrumb>

                    <ProductForm product={product} />
                </>}
            </Container>
        </AppPage>
    )
}

export default EditProduct;