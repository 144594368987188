import { Nav, NavDropdown, Navbar } from "react-bootstrap";

import { NavLink as Link } from "react-router-dom";
import { InvykLogo } from "../../../../components/logo/InvykLogo";
import {
  CUSTOMERS,
  INVOICES,
  MATERIALS,
  PRODUCTS,
  QUOTES,
  STATEMENTS,
} from "../../PlatformStack";

import { List } from "react-bootstrap-icons";
import "./AppNavBar.scss";

interface Props {
  logOut: () => void;
}

const AppNavBar = ({ logOut }: Props) => {
  return (
    <Navbar bg="light" expand="lg" className="appNavBar">
      <Navbar.Brand as={Link} to={PRODUCTS.uri}>
        <InvykLogo
          style={{
            fontSize: "1.5em",
            lineHeight: "1.5em",
            marginBottom: "0",
          }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {/*<Nav.Link as={Link} to={DASHBOARD.uri}>Dashboard</Nav.Link>*/}
          <Nav.Link as={Link} to={PRODUCTS.uri}>
            Products
          </Nav.Link>
          {/*<Nav.Link as={Link} to={PRICING.uri}>Pricing</Nav.Link>*/}
          <Nav.Link as={Link} to={CUSTOMERS.uri}>
            Customers
          </Nav.Link>
          {/*<Nav.Link as={Link} to={PURCHASES.uri}>Purchases</Nav.Link>*/}
          <Nav.Link as={Link} to={QUOTES.uri}>
            Quotes
          </Nav.Link>
          <Nav.Link as={Link} to={INVOICES.uri}>
            Invoices
          </Nav.Link>
          <Nav.Link as={Link} to={STATEMENTS.uri}>
            Statements
          </Nav.Link>
          <Nav.Link as={Link} to={MATERIALS.uri}>
            Materials
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>

      <NavDropdown
        id="accountOptions"
        className="profileDropDown"
        title={<List size={30} />}
        align="end"
      >
        <NavDropdown.Item onClick={logOut}>Log Out</NavDropdown.Item>
      </NavDropdown>
    </Navbar>
  );
};

export default AppNavBar;
