import {
  StatementService,
  StatementTablePage,
  StatementTableRow,
  StatementTable,
} from "./StatementService";
import { logApiError } from "../api/ApiUtils";
import { Envelope } from "react-bootstrap-icons";

export const StatementTableService = () => {
  const { getStatementTable } = StatementService();

  const getStatementsForTable = async (
    setLoading: (x: boolean) => void,
    displayCustomerContactModal: (custId: string, statementId: string) => void,
    size: number,
    cursor?: object
  ): Promise<StatementTablePage> => {
    setLoading(true);
    try {
      const statementTable: StatementTablePage = await getStatementTable(
        size,
        cursor
      );
      return {
        totalCount: statementTable.totalCount,
        pageCount: statementTable.pageCount,
        page: statementTable.page,
        cursor: statementTable.cursor,
        size: statementTable.size,
        resource: statementsToRows(
          statementTable.resource.rows,
          displayCustomerContactModal
        ),
      };
    } catch (e) {
      logApiError("Error loading statements.", e);
    } finally {
      setLoading(false);
    }
    return new Promise(() => []);
  };
  const statementsToRows = (
    statementTable: StatementTableRow[],
    displayCustomerContactModal: (custId: string, statementId: string) => void
  ): StatementTable => {
    return {
      rows: statementTable.map((statement) => {
        return {
          ...statement,
          actions: (
            <>
              <Envelope
                style={{ marginRight: "0.2em" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (displayCustomerContactModal) {
                    displayCustomerContactModal(
                      statement.customerId,
                      statement.statementId
                    );
                  }
                }}
              />
            </>
          ),
        };
      }),
    };
  };
  const filter = (
    statements: StatementTableRow[],
    searchValue: string
  ): StatementTableRow[] => {
    if (!searchValue) {
      return statements;
    }
    return statements.filter(
      (statement) =>
        statement.id.toLowerCase().includes(searchValue.toLowerCase()) ||
        statement.customerName
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        statement.status.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  return {
    getStatementsForTable,
    filter,
    columns: [
      {
        title: "Ref",
        key: "ref",
        style: {
          width: "20%",
        },
      },
      {
        title: "Customer Name",
        key: "customerName",
      },
      {
        title: "Statement Type",
        key: "statementType",
      },

      {
        title: "To Date",
        key: "toDate",
      },
      {
        title: "From Date",
        key: "fromDate",
      },
      {
        title: "Amount Due",
        key: "amountDue",
      },
      {
        title: "",
        key: "actions",
        style: {
          width: "10%",
          textAlign: "end" as "end",
        },
      },
    ],
    statementPaymentColumns: [
      {
        title: "Select",
        key: "actions",
        style: {
          width: "10%",
        },
      },
      {
        title: "Ref",
        key: "ref",
        style: {
          width: "20%",
        },
      },
      {
        title: "Customer Name",
        key: "customerName",
      },
      {
        title: "Statement Type",
        key: "statementType",
      },

      {
        title: "To Date",
        key: "toDate",
      },
      {
        title: "From Date",
        key: "fromDate",
      },
      {
        title: "Amount Due",
        key: "amountDue",
      },
    ],
    lineItemsColumns: [
      {
        title: "Line Item",
        key: "ref",
        style: {
          width: "50%",
        },
      },
      {
        title: "Date",
        key: "createdDate",
      },
      {
        title: "Price",
        key: "price",
      },
      {
        title: "Balance",
        key: "balance",
      },
    ],
    lineItemsResources: [
      {
        title: "Product",
        key: "title",
        style: {
          width: "50%",
        },
      },
      {
        title: "Length",
        key: "length",
      },
      {
        title: "Number of Items",
        key: "quantity",
      },
      {
        title: "Item Price",
        key: "unitPrice",
      },

      {
        title: "Total Price",
        key: "totalPrice",
      },
    ],
  };
};
