import React from 'react';
import {CenteredCardPage} from "../../../components/layout/centeredCardPage/CenteredCardPage";
import {RegisterForm} from "../parts/register/RegisterForm";

export const Register = () => {

    return (
        <CenteredCardPage>
            <RegisterForm />
        </CenteredCardPage>
    );
};