import ApiClient from "../api/ApiClient";
import {Tag} from "../tag/TagService";
import {Variant} from "./ProductVariantService";
import {PagedResource} from "../api/common/Types";

export interface ProductBase {
    id?: string
    title: string
    description?: string
    tags?: Tag[]
    variants?: Variant[]
}

export interface Product extends ProductBase {
    id: string
}

export interface PagedProduct extends PagedResource {
    resource: Product[]
}

export const ProductService = () => {

    const getAll = (async (): Promise<PagedProduct> => {
        return ApiClient.getInstance().post<PagedProduct>(`/api/1/products-search`, {
            size: 100
        });
    });

    const search = (async (search: string): Promise<PagedProduct> => {
        return ApiClient.getInstance().post<PagedProduct>(`/api/1/products-search`, {
            search
        });
    });

    const get = (async (productId: string): Promise<Product> => {
        return ApiClient.getInstance().get<Product>(`/api/1/products/${productId}`);
    });

    const create = (async (title: string, description?: string): Promise<Product> => {
        return ApiClient.getInstance().post<Product>('/api/1/products', {
            title,
            description
        });
    });

    const update = (async (productId: string, product: ProductBase): Promise<Product> => {
        return ApiClient.getInstance().put<Product>(`/api/1/products/${productId}`, product);
    });

    return {
        getProducts: getAll,
        searchProducts: search,
        getProduct: get,
        createProduct: create,
        updateProduct: update
    }
}
