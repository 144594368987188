import ApiClient from "../api/ApiClient";
import {PagedResource} from "../api/common/Types";

export interface Payment {
    id: string
    documentId?: string
    ref: string
    source: string
    includes: Includes[]
    timestamp: string
    amount: number
    amountDisplayString: string
    customerId: string
    orderId?: string
}

export interface Includes {
    type: string
    typeId?: string
}

export interface PagedPayments extends PagedResource {
    resource: Payment[]
}


export const PaymentService = () => {

    const getAll = (async (): Promise<PagedPayments> => {
        return ApiClient.getInstance().post<PagedPayments>(`/api/1/payments-search`, {
            size: 100
        });
    });

    const getAllByCustomerId = (async (customerId: string): Promise<PagedPayments> => {
        return ApiClient.getInstance().post<PagedPayments>(`/api/1/payments-search`, {
            size: 100,
            customerId: customerId
        });
    });

    const get = (async (paymentId: string): Promise<Payment> => {
        return ApiClient.getInstance().get<Payment>(`/api/1/payments/${paymentId}`);
    });

    const create = (async (customerId: string, source: string, note: string, includes: Includes[], amount: number): Promise<Payment> => {
        return ApiClient.getInstance().post<Payment>('/api/1/payments', {
            customerId,
            source,
            note,
            includes,
            amount
        });
    });

    const deleteP = (async (paymentId: string, reason?: string): Promise<Payment> => {
        return ApiClient.getInstance().put<Payment>(`/api/1/payments/${paymentId}`, {
            reason: reason
        });
    });

    return {
        getPayments: getAll,
        getPaymentsByCustomerId: getAllByCustomerId,
        getPayment: get,
        createPayment: create,
        deletePayment: deleteP
    }
}
