import React from 'react';
import {Form, Row} from "react-bootstrap";
import {PriceBandFormInstance} from "./PriceBands";

interface Props {
    updateDefault: (id: string) => void
    updateBandName: (id: string, newValue: string) => void
    priceBand: PriceBandFormInstance
}

const PriceBandForm = ({ updateDefault, updateBandName, priceBand }: Props) => {
    return (
        <>
            <Form.Group as={Row} className="mb-3" controlId={`priceForm.${priceBand.id}.title`}>
                <Form.Label>
                    Name
                </Form.Label>
                <Form.Control
                    required
                    className="form-input"
                    type="text"
                    name="title"
                    value={priceBand.name}
                    placeholder="Name"
                    onChange={(e) => {
                        updateBandName(priceBand.id, e.target.value);
                    }}
                />
                <Form.Check type="radio"
                            name="priceForm.default"
                            id={`priceForm.default.${priceBand.id}`}
                            label="Default band"
                            checked={priceBand.isDefault}
                            onChange={(e) => {
                                updateDefault(priceBand.id)
                            }} />
            </Form.Group>
        </>
    )
}

export default PriceBandForm;