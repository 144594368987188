import { Data } from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import { logApiError } from "../api/ApiUtils";
import { Product } from "./ProductService";
import { ReactElement } from "react";
import {
  PagedVariants,
  ProductVariantService,
  Variant,
} from "./ProductVariantService";
import { Pencil, Trash } from "react-bootstrap-icons";

export interface VariantRow extends Data {
  id: string;
  code: string;
  title: string;
  actions: ReactElement;
}

export interface VariantTablePage {
  totalCount: number;
  pageCount: number;
  page: number;
  rows: VariantRow[];
}

export const VariantsTableService = () => {
  const { getVariantsForProduct } = ProductVariantService();

  const getVariants = async (
    setLoading: (value: boolean) => void,
    openModal: (x: Variant) => void,
    deleteVariant: (vId: string) => void,
    product: Product
  ): Promise<VariantRow[]> => {
    setLoading(true);
    try {
      const pagedVariants: PagedVariants = await getVariantsForProduct(
        product.id
      );
      return variantsToRows(openModal, deleteVariant, pagedVariants.resource);
    } catch (e) {
      logApiError("Error loading variants.", e);
    } finally {
      setLoading(false);
    }
    return new Promise(() => []);
  };

  const variantsToRows = (
    openModal: (x: Variant) => void,
    deleteVariant: (vId: string) => void,
    variants?: Variant[]
  ): VariantRow[] => {
    if (!variants) {
      return [];
    }
    return variants.map((variant) => {
      return {
        id: variant.id,
        code: variant.code,
        title: variant.title,
        actions: (
          <>
            <Pencil
              style={{ marginRight: "0.2em" }}
              onClick={() => openModal(variant)}
            />
            <Trash onClick={() => deleteVariant(variant.id)} />
          </>
        ),
      };
    });
  };

  const filter = (
    variants: VariantRow[],
    searchValue: string
  ): VariantRow[] => {
    if (!searchValue) {
      return variants;
    }
    return variants.filter(
      (variant) =>
        variant.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        variant.code.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  return {
    getVariants,
    filter,
    columns: [
      {
        title: "Code",
        key: "code",
        style: {
          width: "25%",
        },
      },
      {
        title: "Title",
        key: "title",
      },
      {
        title: "",
        key: "actions",
        style: {
          width: "10%",
          // no idea - https://github.com/typestyle/typestyle/issues/281
          textAlign: "end" as "end",
        },
      },
    ],
  };
};
