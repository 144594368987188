import {Credentials} from "../credientials/CredentialsService";
import {basicAuthHeaderForCredentials} from "../api/ApiUtils";
import {useStore} from "../state/useStore";
import ApiClient from "../api/ApiClient";

export type Session = {
    idToken: string
}

export const SessionService = () => {

    const { updateToken } = useStore();

    const createSession: (credentials: Credentials) => Promise<boolean> = (async (credentials) => {
        let response = await ApiClient.getInstance().post<Session>('/api/1/session', {}, {
            withCredentials: true,
            headers: {
                Authorization: basicAuthHeaderForCredentials(credentials)
            }
        })

        updateToken('Bearer ' + response.idToken)
        return true;
    });

    return {
        createSession
    }
}
