import React, {useEffect, useState} from 'react';
import AppPage from "../../components/page/AppPage";
import CategoryForm from "../parts/forms/CategoryForm";
import {Breadcrumb, BreadcrumbItem, Container, Spinner} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {CATEGORIES, CATEGORY} from "../../PlatformStack";
import {CategoryBase, CategoryService} from "../../../../services/category/CategoryService";
import {logApiError} from "../../../../services/api/ApiUtils";

interface Props {

}

const AddCategory = ({}: Props) => {

    const { id } = useParams();
    const navigate = useNavigate();

    const { getCategory } = CategoryService();

    const [loading, setLoading] = useState<boolean>();
    const [category, setCategory] = useState<CategoryBase>();

    useEffect(() => {
        if (loading || !id) {
            return;
        }

        const loadCategory = async (categoryId: string) => {
            setLoading(true);
            try {
                const resp = await getCategory(categoryId);
                setCategory(resp);
            } catch (e) {
                logApiError('Error loading category.', e);
            } finally {
                setLoading(false);
            }
        }

        loadCategory(id);
    }, []);

    return (
        <AppPage>
            <Container>
                {loading && <>
                    <Spinner animation={"border"} />
                    <p>Loading...</p>
                </>}
                {!loading && id && category && <>
                    <h3 className="heading">Edit: {category.name}</h3>

                    <Breadcrumb>
                        <BreadcrumbItem onClick={() => navigate(CATEGORIES.uri)}>Categories</BreadcrumbItem>
                        <BreadcrumbItem onClick={() => navigate(CATEGORY.navigate([id]))}>{category.name}</BreadcrumbItem>
                        <BreadcrumbItem active>Edit</BreadcrumbItem>
                    </Breadcrumb>

                    <CategoryForm category={category}/>
                </>}
            </Container>
        </AppPage>
    )
}

export default AddCategory;