import React, {CSSProperties, useEffect, useState} from 'react';
import {Button, Col, FormControl, InputGroup, Row, Spinner, Stack, Table} from "react-bootstrap";
import {ArrowClockwise, PlusCircleFill, Search} from "react-bootstrap-icons";

import './RefreshableSearchableTable.scss';

export interface Props {
    data: Data[]
    columns: Column[]
    loading: boolean
    filterFunction: (searchValue: string) => void
    onRowClick?: (row: object) => void,
    navigateToAddNewForm?: () => void
    refresh?: () => void
}

export interface Column {
    title: string
    key: string
    style?: CSSProperties
}

export interface Data {
    [index: string]: string | any;
    id: string
}

const RefreshableSearchableTable = ({
                                        data,
                                        columns,
                                        loading,
                                        filterFunction,
                                        onRowClick,
                                        refresh,
                                        navigateToAddNewForm
                                    }: Props) => {

    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        filterFunction(searchValue);
    }, [searchValue])

    return (
        <>
            <Row>
                <Col xs={0} lg={4}/>
                <Col xs={12} lg={8}>
                    <Stack direction="horizontal" gap={3} className={'refreshableSearchableTable_actions'}>
                        {navigateToAddNewForm &&
                            <Button onClick={navigateToAddNewForm} variant={"success"}>
                                <PlusCircleFill />
                            </Button>
                        }
                        <Button onClick={refresh} disabled={loading}>
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                            {!loading && <ArrowClockwise />}
                        </Button>
                        <InputGroup>
                            <InputGroup.Text>
                                <Search />
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Search..."
                                aria-label="Search"
                                onChange={(e) => setSearchValue(e.target.value)}
                                className={"refreshableSearchableTable_searchInput"}
                            />
                        </InputGroup>
                    </Stack>
                </Col>

            </Row>
            <Table striped hover>
                <thead>
                    <tr>
                        {columns.map((column) => {
                            return <th key={column.key}>{column.title}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {loading && <tr>
                        <td>Loading...</td>
                        <td />
                    </tr>}
                    {!loading && data.length > 0 && data.map((row) => {
                        return <tr onClick={() => onRowClick && onRowClick(row)} className={"refreshableSearchableTable_categoryRow"}>
                            {columns.map((column) => {
                                 return <td key={`${column.key}-${row.id}`} style={column.style}>{row[column.key]}</td>
                             })}
                        </tr>
                    })}
                    {(!loading && (!data || data.length == 0)) && <tr>
                        <td><i>No data to display</i></td>
                        {columns.map((col) => {
                            return <td />
                        })}
                    </tr>}
                </tbody>
            </Table>
        </>
    )
}

export default RefreshableSearchableTable;