import React from 'react';
import AppPage from "../../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem, Container} from "react-bootstrap";
import {VIEW_CUSTOMER_PAYMENT} from "../../PlatformStack";
import {useNavigate, useParams} from "react-router-dom";
import AddPaymentTabs from "../parts/AddPaymentTabs";

interface Props {

}

const NewPayment = ({}: Props) => {

    const navigate = useNavigate();
    const {customerId} = useParams();
    const {customerName} = useParams();


    return (
        <AppPage>
            <Container>
                <h3 className="heading">Add New Payment</h3>
                <Breadcrumb>
                    {customerId && <BreadcrumbItem onClick={() => navigate(VIEW_CUSTOMER_PAYMENT.navigate([customerId]))}>Payments</BreadcrumbItem>}
                    <BreadcrumbItem active>Add</BreadcrumbItem>
                </Breadcrumb>
                {(customerId && customerName) && <AddPaymentTabs customerId={customerId} customerName={customerName}/>}
            </Container>
        </AppPage>
    )
}

export default NewPayment;