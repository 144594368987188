import React from 'react';
import {Tab, Tabs} from "react-bootstrap";
import ProductTab from "./tabs/ProductTab";
import {Category} from "../../../../services/category/CategoryService";

interface Props {
    category: Category
    reload: () => void
}

const CategoryTabs = ({category, reload}: Props) => {
    return (
        <Tabs defaultActiveKey="products" id="category-tabs" className="mb-3">
            <Tab eventKey="products" title="Products">
                <ProductTab category={category} reload={reload} />
            </Tab>
        </Tabs>
    )
}

export default CategoryTabs;