import React, {useEffect, useReducer, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {MATERIALS} from "../../../PlatformStack";
import {logApiError} from "../../../../../services/api/ApiUtils";
import {Alert, Button, Form, Row} from "react-bootstrap";
import {MaterialFormActionType, MaterialFormService} from "../../../../../services/materials/MaterialFormService";
import {Material} from "../../../../../services/materials/MaterialService";
import * as yup from "yup";
import {Formik} from "formik";

interface Props {
    material?: Material
}

const MaterialForm = ({material}: Props) => {

    const { saveMaterial, reducer, initialState } = MaterialFormService();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const [state, dispatch] = useReducer(reducer, initialState);

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        price: yup.number().required("Price is required")
    });

    useEffect(() => {
        if (!material) {
            return;
        }

        dispatch({
            type: MaterialFormActionType.MATERIAL_LOADED,
            value: material
        });
    }, [material]);

    const save = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            await saveMaterial(state);
            navigate(MATERIALS.uri)
        } catch (e) {
            logApiError('Error saving material.', e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button
                    variant={"outline-danger"}
                    style={{ marginRight: '0.5em', width: 'auto' }}
                    onClick={() => navigate(MATERIALS.uri)}>
                    Cancel
                </Button>
                <Button style={{ width: 'auto' }} onClick={save}>{material ? 'Update' : 'Create'}</Button>
            </Row>
            <Row>
                <Formik
                    onSubmit={() => {}}
                    validationSchema={schema}
                    enableReinitialize={true}
                    initialValues={{
                        name: state.name,
                        price: state.price
                    }}
                >
                    {({
                          handleChange,
                          handleBlur,
                          touched,
                          errors
                      }) => (
                        <Form className="materialForm_card_form" noValidate>
                            <Form.Group as={Row} className="mb-3" controlId="materialForm.name">
                                <Form.Label>
                                    Name
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="form-input"
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={state.name}
                                    onBlur={handleBlur}
                                    disabled={!!material}
                                    onChange={(e) => {
                                        dispatch({
                                            type: MaterialFormActionType.NAME_UPDATE,
                                            value: e.target.value
                                        });
                                        handleChange(e);
                                    }}
                                />
                                {touched.name && errors.name && (
                                    <Alert variant="danger">
                                        {errors.name}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="materialForm.price">
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                    className="form-input"
                                    placeholder="Price"
                                    name="price"
                                    type="number"
                                    value={state.price}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        dispatch({
                                            type: MaterialFormActionType.PRICE_UPDATE,
                                            value: Number.parseFloat(e.target.value)
                                        });
                                        handleChange(e);
                                    }}
                                />
                                {touched.price && errors.price && (
                                    <Alert variant="danger">
                                        {errors.price}
                                    </Alert>
                                )}
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Row>
        </>
    )
}

export default MaterialForm;