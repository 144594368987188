import { useState } from "react";
import { Button, Form, Row, Spinner, Table } from "react-bootstrap";
import { logApiError } from "../../../../services/api/ApiUtils";
import {
  OrderFormAction,
  OrderFormActionType,
} from "../../../../services/order/OrderFormService";
import {
  OrderItemSearchResult,
  OrderItemService,
} from "../../../../services/orderitem/OrderItemService";

interface Props {
  dispatch: (x: OrderFormAction) => void;
  onItemSelected: (vId: string) => void;
}

const ProductSearch = ({ dispatch, onItemSelected }: Props) => {
  const { searchOrderItems } = OrderItemService();

  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isAddMiscellenousItem, setIsAddMiscellenousItem] =
    useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<OrderItemSearchResult[]>(
    []
  );
  const handleMiscItemAdd = () => {
    setIsAddMiscellenousItem(true);
    setSearchValue("");
    dispatch({
      value: {
        miscItemName: searchValue,
        quantity: 0,
        price: 0,
      },
      type: OrderFormActionType.MISC_ITEM_ADDED,
    });
  };

  const search = async (value: string) => {
    setSearchValue(value);
    if (value === "") {
      setSearchResults([]);
      return;
    }
    if (value.length < 3) {
      return;
    }
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      let results = await searchOrderItems(value);
      setSearchResults(results);
    } catch (e) {
      logApiError("Error when searching products", e);
    } finally {
      setLoading(false);
    }
  };

  const rowClick = (orderItem: OrderItemSearchResult) => {
    setSearchValue("");
    setSearchResults([]);
    dispatch({
      value: {
        orderItem,
      },
      type: OrderFormActionType.ORDER_ITEM_SELECTED,
    });
    onItemSelected(orderItem.variant.id);
  };

  return (
    <div>
      <Form.Group as={Row} className="mb-3" controlId="orderForm.search">
        <Form.Label>Search</Form.Label>
        <span style={{ fontSize: "0.75em" }}>
          <i>Will automatically search when you input 3 characters</i>
        </span>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "95%" }}>
            <Form.Control
              required
              className="form-input"
              type="text"
              name="search"
              value={searchValue}
              placeholder="Search..."
              onChange={(e) => search(e.target.value)}
            />
            {loading && <Spinner animation="border" />}
          </div>
        </div>
      </Form.Group>
      <br />
      {searchValue && searchValue.length > 0 && (
        <p
          onClick={(e) => {
            e.preventDefault();
            handleMiscItemAdd();
          }}
          style={{ cursor: "pointer" }}
        >
          <strong> + Add Misc Order Item: {searchValue}</strong>
        </p>
      )}

      <br />
      <hr></hr>
      <Table>
        <tbody>
          {searchResults &&
            searchResults.map((orderItem) => {
              return (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => rowClick(orderItem)}
                >
                  <td style={{ width: "35%" }}>{orderItem.variant.code}</td>
                  <td style={{ width: "35%" }}>{orderItem.product.title}</td>
                  <td>{orderItem.variant.title}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default ProductSearch;
