import {Formik} from "formik";
import React, {useEffect, useReducer, useState} from "react";
import {Button, Form, Modal, Row} from "react-bootstrap";
import {CustomerContact} from "../../../../../services/customer/CustomerContactService";
import {
  CustomerContactFormActionType,
  CustomerContactFormService,
} from "../../../../../services/customer/CustomerContactFormService";
import {logApiError} from "../../../../../services/api/ApiUtils";

interface AddCustomerContactModalProps {
  show: boolean;
  close: () => void;
  contacts: CustomerContact;
  customerId: string;
  onContactChange: () => void;
}

const AddCustomerContactModal = ({
  show,
  close,
  contacts,
  customerId,
  onContactChange,
}: AddCustomerContactModalProps) => {
  const {
    createCustomerContact,
    updateCustomerContact,
    reducer,
    initialState,
  } = CustomerContactFormService();

  const closeModal = () => {
    dispatch({
      type: CustomerContactFormActionType.MODAL_CLOSED,
    });
    close();
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    dispatch({
      type: CustomerContactFormActionType.MODAL_LOADED,
      value: contacts,
    });
  }, [contacts]);

  const handleCustomerContactChange = async (isCreate: boolean) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      if (isCreate) {
        await createCustomerContact(customerId, {
          name: state.name,
          email: state.email,
          phoneNumber: state.phoneNumber,
        });
      } else {
        await updateCustomerContact(customerId, {
          id: state.id,
          name: state.name,
          email: state.email,
          phoneNumber: state.phoneNumber,
        });
      }
      closeModal();
      onContactChange();
    } catch (e) {
      logApiError("Error creating customer contact", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={closeModal} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add New Customer Contact Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          onSubmit={() => {}}
          initialValues={{
            name: state.name,
            email: state.email,
            phoneNumber: state.phoneNumber,
          }}
        >
          {({ handleChange, handleBlur, errors }) => (
            <Form className="customerForm_card_form" noValidate>
              <div>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerContactForm.name"
                >
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={state.name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerContactFormActionType.NAME_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerContactForm.email"
                >
                  <Form.Control
                    className="form-input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={state.email}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerContactFormActionType.EMAIL_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerContactForm.phone"
                >
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={state.phoneNumber}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerContactFormActionType.PHONE_NUMBER_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() =>
            state.id
              ? handleCustomerContactChange(false)
              : handleCustomerContactChange(true)
          }
        >
          {state.id ? "Update" : "Create New"}
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerContactModal;
