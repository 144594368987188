import React, {useEffect, useState} from 'react';
import AppPage from "../../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem, Container, Spinner} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {PRODUCTS} from "../../PlatformStack";
import {Product, ProductService} from "../../../../services/product/ProductService";
import {logApiError} from "../../../../services/api/ApiUtils";
import ProductTabs from "../parts/ProductTabs";
import {ProductEquation, ProductEquationService} from "../../../../services/product/ProductEquationService";

interface Props {

}

const SpecificProduct = ({}: Props) => {

    const { id } = useParams();
    const navigate = useNavigate();

    const { getProduct } = ProductService();
    const { getProductEquation } = ProductEquationService();

    const [loading, setLoading] = useState<boolean>();
    const [product, setProduct] = useState<Product>();
    const [equation, setEquation] = useState<ProductEquation>();

    const loadProduct = async (productId?: string) => {
        if (loading || !productId) {
            return;
        }

        try {
            const resp = await getProduct(productId);
            setProduct(resp);
        } catch (e) {
            logApiError('Error loading product.', e);
        }

        try {
            const equationResp = await getProductEquation(productId);
            setEquation(equationResp);
        } catch (e) {
            logApiError('Error loading product equation.', e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        loadProduct(id)
            .finally(() => setLoading(false))
    }, []);

    return (
        <AppPage>
            <Container>
                {loading && <>
                    <Spinner animation={"border"} />
                    <p>Loading...</p>
                </>}
                {!loading && id && product && <>
                    <h3 className="heading">{product.title}</h3>

                    <Breadcrumb>
                        <BreadcrumbItem onClick={() => navigate(PRODUCTS.uri)}>Products</BreadcrumbItem>
                        <BreadcrumbItem active>{product.title}</BreadcrumbItem>
                    </Breadcrumb>

                    <ProductTabs product={product} equation={equation} />
                </>}
            </Container>
        </AppPage>
    )
}

export default SpecificProduct;