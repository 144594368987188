import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ApiClient, { ApiError } from "../services/api/ApiClient";
import { getApiError } from "../services/api/ApiUtils";
import { useStore } from "../services/state/useStore";
import {
  ACCOUNT_LIST,
  CONFIRM_EMAIL,
  CREATE_ACCOUNT,
  LOGIN,
  REGISTER,
} from "./onboarding/OnBoardingStack";
import { Login } from "./onboarding/pages/Login";
import { Register } from "./onboarding/pages/Register";
import PlatformLoading from "./platform/PlatformLoading";
import {
  ADD_CATEGORY,
  ADD_CUSTOMER, ADD_INVOICE,
  ADD_PRODUCT, ADD_QUOTE,
  CATEGORIES,
  CATEGORY,
  CUSTOMERS,
  EDIT_CATEGORY,
  EDIT_CUSTOMER,
  EDIT_MATERIAL,
  EDIT_PRODUCT,
  INVOICES,
  MATERIALS,
  NEW_MATERIAL,
  NEW_PAYMENT,
  PRICING,
  PRODUCT,
  PRODUCTS,
  PURCHASES,
  QUOTES,
  STATEMENTS,
  VIEW_CUSTOMER,
  VIEW_CUSTOMER_PAYMENT,
  VIEW_CUSTOMER_STATEMENT,
  VIEW_INVOICE,
  VIEW_QUOTE,
  VIEW_STATEMENT,
  VIEW_STATEMENT_CUSTOMER
} from "./platform/PlatformStack";
import Categories from "./platform/categories/Categories";
import AddCategory from "./platform/categories/subpages/AddCategory";
import EditCategory from "./platform/categories/subpages/EditCategory";
import SpecificCategory from "./platform/categories/subpages/SpecificCategory";
import Customers from "./platform/customers/Customers";
import AddCustomer from "./platform/customers/subpages/AddCustomer";
import EditCustomer from "./platform/customers/subpages/EditCustomer";
import SpecificCustomerDetails from "./platform/customers/subpages/SpecificCustomerDetails";
import Invoices from "./platform/invoices/Invoices";
import SpecificInvoice from "./platform/invoices/subpages/SpecificInvoice";
import Materials from "./platform/materials/Materials";
import EditMaterial from "./platform/materials/subpages/EditMaterial";
import NewMaterial from "./platform/materials/subpages/NewMaterial";
import NewPayment from "./platform/payments/subpages/NewPayment";
import Pricing from "./platform/pricing/Pricing";
import Products from "./platform/products/Products";
import AddProduct from "./platform/products/subpages/AddProduct";
import EditProduct from "./platform/products/subpages/EditProduct";
import SpecificProduct from "./platform/products/subpages/SpecificProduct";
import Purchases from "./platform/purchases/Purchases";
import Quotes from "./platform/quotes/Quotes";
import SpecificQuote from "./platform/quotes/subpages/SpecificQuote";
import Statements from "./platform/statements/Statements";
import SpecificStatement from "./platform/statements/subpages/SpecificStatement";
import { ToastContainer } from "react-toastify";
import { ListAccounts } from "./onboarding/pages/ListAccounts";
import { ConfirmEmail } from "./onboarding/pages/ConfirmEmail";
import CreateAccount from "./onboarding/pages/CreateAccount";
import AddInvoice from "./platform/invoices/subpages/AddInvoice";
import AddQuote from "./platform/quotes/subpages/AddQuote";

export const App = () => {
  const navigate = useNavigate();

  new ApiClient((error: any) => {
    let errorResponse: ApiError = getApiError(error);
    if (errorResponse?.errorCode === "E0001") {
      navigate(LOGIN.uri);
    }
    throw error;
  }, process.env.REACT_APP_API_URL);

  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path={LOGIN.uri} element={<Login />} />
        <Route path={REGISTER.uri} element={<Register />} />
        <Route path={ACCOUNT_LIST.uri} element={<ListAccounts />} />
        <Route path={CONFIRM_EMAIL.uri} element={<ConfirmEmail />} />
        <Route path={CREATE_ACCOUNT.uri} element={<CreateAccount />} />

        <Route path={CATEGORY.uri} element={<SpecificCategory />} />
        <Route path={CATEGORIES.uri} element={<Categories />} />
        <Route path={ADD_CATEGORY.uri} element={<AddCategory />} />
        <Route path={EDIT_CATEGORY.uri} element={<EditCategory />} />
        <Route path={CUSTOMERS.uri} element={<Customers />} />
        <Route path={ADD_CUSTOMER.uri} element={<AddCustomer />} />
        <Route path={EDIT_CUSTOMER.uri} element={<EditCustomer />} />
        <Route
          path={VIEW_CUSTOMER.uri}
          element={<SpecificCustomerDetails />}
        />
        <Route
          path={VIEW_CUSTOMER_STATEMENT.uri}
          element={<SpecificCustomerDetails activeTab={"Statements"} />}
        />
        <Route
          path={VIEW_CUSTOMER_PAYMENT.uri}
          element={<SpecificCustomerDetails activeTab={"payments"} />}
        />
        <Route path={PRODUCT.uri} element={<SpecificProduct />} />
        <Route path={PRODUCTS.uri} element={<Products />} />
        <Route path={ADD_PRODUCT.uri} element={<AddProduct />} />
        <Route path={EDIT_PRODUCT.uri} element={<EditProduct />} />
        <Route path={PRICING.uri} element={<Pricing />} />
        <Route path={PURCHASES.uri} element={<Purchases />} />
        <Route path={MATERIALS.uri} element={<Materials />} />
        <Route path={NEW_MATERIAL.uri} element={<NewMaterial />} />
        <Route path={EDIT_MATERIAL.uri} element={<EditMaterial />} />
        <Route path={INVOICES.uri} element={<Invoices />} />
        <Route path={ADD_INVOICE.uri} element={<AddInvoice />} />
        <Route path={VIEW_INVOICE.uri} element={<SpecificInvoice />} />
        <Route path={QUOTES.uri} element={<Quotes />} />
        <Route path={ADD_QUOTE.uri} element={<AddQuote />} />
        <Route path={VIEW_QUOTE.uri} element={<SpecificQuote />} />
        <Route path={VIEW_STATEMENT.uri} element={<SpecificStatement />} />
        <Route
          path={VIEW_STATEMENT_CUSTOMER.uri}
          element={<SpecificStatement />}
        />
        <Route path={STATEMENTS.uri} element={<Statements />} />
        <Route path={NEW_PAYMENT.uri} element={<NewPayment />} />

        <Route path="*" element={<PlatformLoading />} />
      </Routes>
      <ToastContainer autoClose={3000} />
    </>
  );
};
