import React, {useEffect, useReducer} from 'react';

import './CustomerForm.scss';
import {
    CustomerFormActionType,
    CustomerFormService,
    MissingCodeError
} from "../../../../../services/customer/CustomerFormService";
import {Button, Col, Row} from "react-bootstrap";
import CustomerInfoForm from "./CustomerInfoForm";
import {toast} from "react-toastify";
import {logApiError} from "../../../../../services/api/ApiUtils";
import {Customer} from "../../../../../services/customer/CustomerService";
import {useNavigate} from "react-router-dom";
import {CUSTOMERS} from "../../../PlatformStack";
import {PriceBandService} from "../../../../../services/pricing/PriceBandService";
import {PriceBandAssignmentService} from "../../../../../services/pricing/PriceBandAssignmentService";
import CustomerPricingForm from "./CustomerPricingForm";
import CustomerAddressesForm from "./CustomerAddressForm";
import CustomerContactsForm from "./CustomerContactsForm";
import {CustomerAddressService} from "../../../../../services/customer/CustomerAddressService";
import {CustomerContactService} from "../../../../../services/customer/CustomerContactService";

interface Props {
    customer?: Customer
}

const CustomerForm = ({ customer }: Props) => {

    const navigate = useNavigate();

    const { reducer, initialState, save } = CustomerFormService();
    const { getAddresses } = CustomerAddressService();
    const { getContacts } = CustomerContactService();
    const { getPriceBands } = PriceBandService();
    const { getPriceBandAssignmentForCustomer } = PriceBandAssignmentService();

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const loadPriceBands = async () => {
            let pagedPriceBand = await getPriceBands();
            if (pagedPriceBand.resource) {
                dispatch({
                    type: CustomerFormActionType.BANDS_LOADED,
                    value: pagedPriceBand.resource
                });
                loadPriceBandAssignment();
            }
        }

        loadPriceBands()
            .catch((e) => logApiError('Error loading price bands.', e))
    }, []);

    useEffect(() => {
        if (!customer) {
            return;
        }

        dispatch({
            type: CustomerFormActionType.CUSTOMER_LOADED,
            value: customer
        });
        loadPriceBandAssignment();
        loadContacts();
        loadAddresses();
    }, [customer])

    const saveCustomer = () => {
        save(state)
            .then(() => {
                toast.success('Customer saved.');
                navigate(CUSTOMERS.uri);
            })
            .catch((e) => {
                if (e === MissingCodeError) {
                    toast.error("Please provide a customer code to save.");
                    return;
                }
                logApiError('Error saving customer.', e)
            })
    }

    const loadPriceBandAssignment = async () => {
        if (!customer || !customer.id) {
            return;
        }

        let assignment = await getPriceBandAssignmentForCustomer(customer.id);
        if (assignment) {
            dispatch({
                type: CustomerFormActionType.ASSIGNMENTS_LOADED,
                value: assignment
            })
        }
    }

    const loadAddresses = async () => {
        if (!customer || !customer.id) {
            return;
        }

        let addresses = await getAddresses(customer.id);
        if (addresses.resource && addresses.resource.length > 0) {
            dispatch({
                type: CustomerFormActionType.CUSTOMER_ADDRESSES_LOADED,
                value: addresses.resource
            })
        }
    }

    const loadContacts = async () => {
        if (!customer || !customer.id) {
            return;
        }

        let contacts = await getContacts(customer.id)
        if (contacts.resource && contacts.resource.length > 0) {
            dispatch({
                type: CustomerFormActionType.CUSTOMER_CONTACTS_LOADED,
                value: contacts.resource
            })
        }
    }

    return (
        <>
            <Row className="customerForm_actionBtnHeader">
                <Col xs={12}>
                    <Button style={{ float: 'right' }} onClick={saveCustomer}>{customer ? 'Update' : 'Create'}</Button>
                    <Button variant={"outline-danger"} style={{ marginRight: '0.5em', float: 'right' }}>Cancel</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <CustomerInfoForm info={state.info} dispatch={dispatch} />
                    <CustomerPricingForm pricing={state.pricing} dispatch={dispatch} />
                </Col>
                <Col xs={12} lg={6}>
                    <CustomerContactsForm contacts={state.contacts} dispatch={dispatch} />
                    <CustomerAddressesForm addresses={state.addresses} dispatch={dispatch} />
                </Col>
            </Row>
        </>
    )
}

export default CustomerForm;