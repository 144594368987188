import React from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {Product} from "../../../../services/product/ProductService";
import VariantsTab from "./tabs/VariantsTab";
import ProductForm from "./form/ProductForm";
import {ProductEquation} from "../../../../services/product/ProductEquationService";

interface Props {
    product: Product,
    equation?: ProductEquation
}

const ProductTabs = ({product, equation}: Props) => {

    return (
        <Tabs defaultActiveKey="info" id="product-tabs" className="mb-3">
            <Tab eventKey="info" title="Info" style={{ padding: '0 1em' }}>
                <ProductForm product={product} equation={equation} />
            </Tab>
            <Tab eventKey="variants" title="Variants" style={{ padding: '0 1em' }}>
                <VariantsTab product={product} />
            </Tab>
        </Tabs>
    )
}

export default ProductTabs;