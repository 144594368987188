import React from 'react';
import {Badge} from "react-bootstrap";
import {X} from "react-bootstrap-icons";

interface Props {
    name?: string
    size?: 's' | 'm' | 'l'
    colour: string
    deletable?: boolean
}

export const CategoryTag = ({name, colour, deletable=false, size='m'}: Props) => {

    let r = parseInt(colour.substring(1, 3), 16);
    let g = parseInt(colour.substring(3, 5), 16);
    let b = parseInt(colour.substring(5, 7), 16);

    let color = (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 150) ? 'black' : 'white';

    let padding = '0.75em';
    if (size === 's') {
        padding = '0.5em';
    } else if (size === 'l') {
        padding = '1em';
    }

    let containerStyle = {
        backgroundColor: colour,
        width: 'auto',
        marginRight: '0.5em',
        marginBottom: '0.5em',
        padding
    }

    return (
        <Badge bg={"none"} style={containerStyle}>
            <span style={{color}}>{name ? name : 'Preview'}</span>

            {deletable && <X color={color} />}
        </Badge>
    )
}