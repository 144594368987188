import { Button, Modal } from "react-bootstrap";

interface Props {
  show: boolean;
  close: () => void;
  cancelOrder: () => void;
  processOrder: () => void;
}
const CustomerOverCreditModal = ({
  show,
  close,
  cancelOrder,
  processOrder,
}: Props) => {
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          It Looks Like Customer Is Over Credit Limit Do You Still Want To
          Proceed With This Order?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelOrder}>
          Cancel
        </Button>
        <Button variant="primary" onClick={processOrder}>
          Create Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CustomerOverCreditModal;
