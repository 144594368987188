import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import RefreshableSearchablePageableTable
    from "../../../../../components/table/refreshableSearchablePageableTable/RefreshableSearchablePageableTable";
import {CustomerRow, CustomersTableService} from "../../../../../services/customer/CustomersTableService";
import {ADD_CUSTOMER, EDIT_CUSTOMER, VIEW_CUSTOMER} from "../../../PlatformStack";

interface Props {

}

const AllCustomersTable = ({}: Props) => {

    const navigate = useNavigate();

    const { getCustomersForTable, filter, columns } = CustomersTableService();

    const [loading, setLoading] = useState<boolean>(false);

    const [customers, setCustomers] = useState<CustomerRow[]>([]);
    const [cursor, setCursor] = useState<object>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [data, setData] = useState<CustomerRow[]>([]);

    useEffect(() => {
        setData(customers);
    }, [customers]);
    const onRowClick = (row: Object) => {
        let customerRow = row as CustomerRow
        navigate(VIEW_CUSTOMER.navigate([customerRow.id]))
    }
    const onRowEye = (rowId: string) => {
        navigate(EDIT_CUSTOMER.navigate([rowId]));
    }

    const toAddNewCustomer = () => {
        navigate(ADD_CUSTOMER.uri)
    }

    const loadTable = async (size: number) => {
        setCustomers([]);
        let customerTable = await getCustomersForTable(
            setLoading,
            size,
            onRowEye,
            cursor
        );
        setCustomers(customerTable.rows);
        setTotalCount(customerTable.totalCount);
        setPageCount(customerTable.pageCount);
        setCursor(customerTable.cursor);
    }

    const search = async (val: string) => {
        let custs = await filter(customers, val);
        setData(custs)
    }

    return (
        <RefreshableSearchablePageableTable
            totalCount={totalCount}
            pageCount={pageCount}
            data={data}
            columns={columns}
            loading={loading}
            loadTable={loadTable}
            onRowClick={onRowClick}
            filterFunction={(searchValue) => search(searchValue)}
            navigateToAddNewForm={toAddNewCustomer}
        />
    );
}

export default AllCustomersTable;