import React from "react";
import { useNavigate } from "react-router-dom";
import AppPage from "../../components/page/AppPage";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { QUOTES } from "../../PlatformStack";
import OrderForm from "../../order/parts/OrderForm";
import { OrderType } from "../../../../services/order/OrderFormService";

interface Props {

}

const AddQuote = ({}: Props) => {

    const navigate = useNavigate();

    return (
        <AppPage>
            <h3 className={"heading"}>Add Quote</h3>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(QUOTES.uri)}>Quotes</BreadcrumbItem>
                <BreadcrumbItem active>Add</BreadcrumbItem>
            </Breadcrumb>

            <OrderForm type={OrderType.QUOTE} />
        </AppPage>
    );
};

export default AddQuote;