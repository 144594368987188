import { toast } from "react-toastify";
import { logApiError } from "../api/ApiUtils";
import { Customer } from "../customer/CustomerService";
import { OrderItemSearchResult } from "../orderitem/OrderItemService";
import { PriceBand } from "../pricing/PriceBandService";
import { Price } from "../pricing/PriceCalculatorService";
import { BaseOrderLine, OrderService } from "./OrderService";

export enum OrderType {
    QUOTE = "quote",
    INVOICE = "invoice"
}

export enum OrderFormActionType {
    PRICE_BANDS_LOADED,
    CUSTOMER_SELECTED,
    CUSTOMER_REMOVE,
    CUSTOMER_ASSIGNED_BAND,
    ORDER_ITEM_SELECTED,
    MISC_ITEM_ADDED,
    VARIANT_DEFAULT_PRICE_LOADED,
    CUT_PRICE_LOADED,
    ADD_CUT,
    REMOVE_CUT,
    REMOVE_VARIANT,
    REMOVE_MISC_ITEM,
    UPDATE_MISC_ITEM,
    CLEAR,
    SHIPPING_ADDRESS_SELECTED,
    BILLING_ADDRESS_SELECTED,
    CONTACT_SELECTED,
    TAX_ID_SELECTED,
    CASH_ORDER_SELECTED,
}

export type OrderFormAction =
    | { value: PriceBand[]; type: OrderFormActionType.PRICE_BANDS_LOADED }
    | {
    value: { orderItem: OrderItemSearchResult };
    type: OrderFormActionType.ORDER_ITEM_SELECTED;
}
    | {
    value: { miscItemName: string; quantity: number; price: number };
    type: OrderFormActionType.MISC_ITEM_ADDED;
}
    | { value: Customer; type: OrderFormActionType.CUSTOMER_SELECTED }
    | { type: OrderFormActionType.CUSTOMER_REMOVE }
    | { value: string; type: OrderFormActionType.CUSTOMER_ASSIGNED_BAND }
    | {
    value: { price: Price; variantId: string };
    type: OrderFormActionType.VARIANT_DEFAULT_PRICE_LOADED;
}
    | {
    value: { variantId: string; cut: ItemCut; quantity: number };
    type: OrderFormActionType.ADD_CUT;
}
    | {
    value: { variantId: string; cut: ItemCut; price: Price };
    type: OrderFormActionType.CUT_PRICE_LOADED;
}
    | {
    value: { variantId: string; cut: ItemCut };
    type: OrderFormActionType.REMOVE_CUT;
}
    | { value: { variantId: string }; type: OrderFormActionType.REMOVE_VARIANT }
    | { value: { name: string }; type: OrderFormActionType.REMOVE_MISC_ITEM }
    | {
    value: { name: string; quantity: number; price: number };
    type: OrderFormActionType.UPDATE_MISC_ITEM;
}
    | { type: OrderFormActionType.CLEAR }
    | { value: string; type: OrderFormActionType.SHIPPING_ADDRESS_SELECTED }
    | { value: string; type: OrderFormActionType.BILLING_ADDRESS_SELECTED }
    | { value: string; type: OrderFormActionType.CONTACT_SELECTED }
    | { value: string; type: OrderFormActionType.TAX_ID_SELECTED }
    | { value: boolean; type: OrderFormActionType.CASH_ORDER_SELECTED };

export interface OrderFormState {
    priceBands?: PriceBand[];
    customer?: Customer;
    orderItems: OrderItem[];
    miscOrderItems?: MiscOrderItem[];
    customerAssignedBand?: string;
    canSend: boolean;
    billingAddressId?: string;
    shippingAddressId?: string;
    contactId?: string;
    taxId?: string;
    isCashOrder?: boolean;
}

export interface MiscOrderItem {
    name: string;
    quantity: number;
    price: number;
}

export interface OrderItem {
    productId: string;
    variantId: string;
    variantCode: string;
    title: string;
    fullLengthPrice?: Price;
    lines?: Line[];
}

export interface Line {
    cut: ItemCut;
    quantity: number;
    displayPrice?: string;
    unitPrice?: number;
    totalPrice?: number;
}

export interface ItemCut {
    isFullLength: boolean;
    length?: number;
    measurement?: CutMeasurement;
}

export enum CutMeasurement {
    MILLIMETRE = "mm",
    CENTIMETRE = "cm",
    METRE = "m",
    FEET = "ft",
}

export const OrderFormService = () => {
    const { createOrder } = OrderService();

    const reducer = (
        state: OrderFormState,
        action: OrderFormAction
    ): OrderFormState => {
        switch (action.type) {
            case OrderFormActionType.CLEAR:
                return {
                    ...state,
                    customer: undefined,
                    orderItems: [],
                    miscOrderItems: [],
                    customerAssignedBand: undefined,
                    canSend: false
                };
            case OrderFormActionType.PRICE_BANDS_LOADED:
                return {
                    ...state,
                    priceBands: action.value
                };
            case OrderFormActionType.CUSTOMER_SELECTED:
                return {
                    ...state,
                    customer: action.value,
                    canSend: action.value && state.orderItems.length > 0
                };
            case OrderFormActionType.CUSTOMER_REMOVE:
                return {
                    ...state,
                    customer: undefined,
                    canSend: false
                };
            case OrderFormActionType.CUSTOMER_ASSIGNED_BAND:
                return {
                    ...state,
                    customerAssignedBand: action.value
                };
            case OrderFormActionType.SHIPPING_ADDRESS_SELECTED:
                return {
                    ...state,
                    shippingAddressId: action.value
                };
            case OrderFormActionType.BILLING_ADDRESS_SELECTED:
                return {
                    ...state,
                    billingAddressId: action.value
                };
            case OrderFormActionType.CONTACT_SELECTED:
                return {
                    ...state,
                    contactId: action.value
                };
            case OrderFormActionType.TAX_ID_SELECTED:
                return {
                    ...state,
                    taxId: action.value
                };
            case OrderFormActionType.CASH_ORDER_SELECTED:
                return {
                    ...state,
                    isCashOrder: action.value
                };
            case OrderFormActionType.MISC_ITEM_ADDED:
                console.log("misc item added", action.value);
                return {
                    ...state,
                    miscOrderItems: [
                        ...(state.miscOrderItems ?? []),
                        {
                            name: action.value.miscItemName,
                            quantity: action.value.quantity,
                            price: action.value.price
                        }
                    ]
                };
            case OrderFormActionType.UPDATE_MISC_ITEM:
                return {
                    ...state,
                    miscOrderItems: state.miscOrderItems?.map((moi) => {
                        if (moi.name !== action.value.name) {
                            return moi;
                        }
                        moi.quantity = action.value.quantity;
                        moi.price = action.value.price;
                        return moi;
                    })
                };
            case OrderFormActionType.ORDER_ITEM_SELECTED:
                if (
                    state.orderItems.filter(
                        (item) => item.variantId === action.value.orderItem.variant.id
                    ).length > 0
                ) {
                    return state;
                }

                return {
                    ...state,
                    orderItems: [
                        ...state.orderItems,
                        {
                            productId: action.value.orderItem.variant.productId,
                            variantId: action.value.orderItem.variant.id,
                            variantCode: action.value.orderItem.variant.code,
                            title:
                                action.value.orderItem.product.title +
                                " - " +
                                action.value.orderItem.variant.title
                        }
                    ],
                    canSend: true
                };
            case OrderFormActionType.VARIANT_DEFAULT_PRICE_LOADED:
                let variantId = action.value.variantId;

                let orderItems = state.orderItems.map((oi) => {
                    if (oi.variantId !== variantId) {
                        return oi;
                    }
                    oi.fullLengthPrice = action.value.price;
                    return oi;
                });

                return {
                    ...state,
                    orderItems: orderItems
                };
            case OrderFormActionType.CUT_PRICE_LOADED:
                return {
                    ...state,
                    orderItems: state.orderItems.map((oi) => {
                        if (oi.variantId !== action.value.variantId) {
                            return oi;
                        }

                        oi.lines = oi.lines?.map((line) => {
                            if (isSameCut(line.cut, action.value.cut)) {
                                line.unitPrice = action.value.price.unitPrice;
                                line.displayPrice = action.value.price.displayAmount;
                                line.totalPrice = action.value.price.totalAmount;
                            }
                            return line;
                        });

                        return oi;
                    })
                };
            case OrderFormActionType.ADD_CUT:
                if (!action.value.cut.isFullLength) {
                    if (!action.value.cut.length || !action.value.cut.measurement) {
                        toast.error(
                            "Could not detect length, refill the form and try again."
                        );
                        return state;
                    }
                }

                return {
                    ...state,
                    orderItems: state.orderItems.map((oi) => {
                        if (oi.variantId !== action.value.variantId) {
                            return oi;
                        }

                        let found: boolean = false;
                        let lines = oi.lines?.map((l) => {
                            if (isSameCut(l.cut, action.value.cut)) {
                                l.quantity = action.value.quantity;
                                found = true;
                            }
                            return l;
                        });

                        if (!found) {
                            let line: Line = {
                                cut: action.value.cut,
                                quantity: action.value.quantity
                            };

                            if (lines) {
                                lines.push(line);
                                oi.lines = lines;
                            } else {
                                oi.lines = [line];
                            }
                        }

                        return oi;
                    })
                };
            case OrderFormActionType.REMOVE_CUT:
                return {
                    ...state,
                    orderItems: state.orderItems.map((oi) => {
                        if (oi.variantId !== action.value.variantId) {
                            return oi;
                        }

                        oi.lines = oi.lines?.filter(
                            (line) => !isSameCut(line.cut, action.value.cut)
                        );

                        return oi;
                    })
                };
            case OrderFormActionType.REMOVE_MISC_ITEM:
                return {
                    ...state,
                    miscOrderItems: state.miscOrderItems?.filter(
                        (moi) => moi.name !== action.value.name
                    )
                };
            case OrderFormActionType.REMOVE_VARIANT:
                return {
                    ...state,
                    orderItems: state.orderItems.filter(
                        (oi) => oi.variantId !== action.value.variantId
                    )
                };
        }
    };

    const isSameCut = (first: ItemCut, second: ItemCut) => {
        return (
            first &&
            second &&
            ((first.isFullLength && first.isFullLength === second.isFullLength) ||
                (first.length &&
                    second.length &&
                    first.measurement &&
                    second.measurement &&
                    first.length === second.length &&
                    first.measurement === second.measurement))
        );
    };

    const submitForm = async (
        type: OrderType,
        customerId: string,
        orderItems: OrderItem[],
        onSuccess: () => void,
        miscOrderItems?: MiscOrderItem[],
        defaultBillingAddressId?: string,
        defaultShippingAddressId?: string,
        contactId?: string,
        taxId?: string,
        isCashOrder?: boolean
    ) => {
        let lines: BaseOrderLine[] = [];

        orderItems.forEach((item) => {
            item.lines?.forEach((line) => {
                lines.push({
                    variantId: item.variantId,
                    quantity: line.quantity,
                    length: line.cut.length,
                    lengthMeasurement: line.cut.measurement,
                    fullLength: line.cut.isFullLength,
                    type: "VARIANT"
                });
            });
        });

        // also add misc items as lines
        miscOrderItems?.forEach((item) => {
            lines.push({
                title: item.name,
                quantity: item.quantity,
                unitPrice: item.price * 100,
                fullLength: true,
                type: "MISC"
            });
        });

        try {
            await createOrder(
                type.toUpperCase(),
                customerId,
                lines,
                isCashOrder ? isCashOrder : false,
                defaultBillingAddressId,
                defaultShippingAddressId,
                contactId,
                taxId
            );
            toast.success(
                type + " created successfully."
            );
            onSuccess();
        } catch (e) {
            logApiError("Error creating " + type, e);
        }
    };

    return {
        reducer,
        submitForm,
        isSameCut,
        initialState: {
            customer: undefined,
            orderItems: [],
            canSend: false
        }
    };
};
