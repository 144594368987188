import React, {useEffect, useState} from 'react';
import {Product} from "../../../../../services/product/ProductService";
import RefreshableSearchableTable
    from "../../../../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import {VariantRow, VariantsTableService} from "../../../../../services/product/VariantsTableService";
import {ProductVariantService, Variant} from "../../../../../services/product/ProductVariantService";
import {logApiError} from "../../../../../services/api/ApiUtils";
import VariantModal from "../modal/VariantModal";

interface Props {
    product: Product
}

const VariantsTable = ({ product }: Props) => {

    const { deleteVariant } = ProductVariantService();
    const { getVariants, filter, columns } = VariantsTableService();

    const [loading, setLoading] = useState<boolean>(false);

    const [rows, setRows] = useState<VariantRow[]>([]);
    const [data, setData] = useState<VariantRow[]>([]);

    const [variant, setVariant] = useState<Variant>();
    const [modalShow, setModalShow] = useState<boolean>(false);

    useEffect(() => {
        reload()
    }, [product]);

    useEffect(() => {
        setData(rows);
    }, [rows]);

    const reload = async () => {
        if (!product) {
            return;
        }
        const variantRows = await getVariants(setLoading, openModal, onBinClick, product);
        setRows(variantRows);
    }

    const onBinClick = async (variantId: string) => {
        setLoading(true);
        try {
            await deleteVariant(variantId);
            reload();
        } catch (e) {
            logApiError("Error attempting to delete variant.", e);
        } finally {
            setLoading(false);
        }
    }

    const toAddNewVariant = () => {
        openModal();
    }

    const openModal = (variant?: Variant) => {
        setVariant(variant);
        setModalShow(true);
    }

    const closeModal = (success?: boolean) => {
        if (success === false) {
            return;
        }
        setVariant(undefined);
        setModalShow(false);
        if (success) {
            reload();
        }
    }

    return (
        <>
            <RefreshableSearchableTable
                data={data}
                columns={columns}
                loading={loading}
                refresh={reload}
                filterFunction={(searchValue) => setData(filter(rows, searchValue))}
                navigateToAddNewForm={toAddNewVariant}
            />

            <VariantModal
                productId={product.id}
                variant={variant}
                show={modalShow}
                close={closeModal}
            />
        </>
    );
}

export default VariantsTable;