import React, {useState} from 'react';
import {Item} from "../../../../../services/purchases/ItemService";
import AllItemsTable from "../table/AllItemsTable";
import ItemModal from "../modal/ItemModal";

interface Props {

}

const PurchasesItemTab = ({}: Props) => {

    const [item, setItem] = useState<Item|undefined>()
    const [show, setShow] = useState<boolean>(false);

    const openModal = (item?: Item) => {
        setItem(item)
        setShow(true)
    }
    const closeModal = (shouldReload?: boolean) => {
        setShow(false)
    }

    return (
        <>
            <AllItemsTable addNewItem={openModal} />
            <ItemModal item={item} show={show} close={closeModal} />
        </>

    )
}

export default PurchasesItemTab;