import React, { useState } from "react";
import { Pencil, PencilFill, Trash, TrashFill } from "react-bootstrap-icons";
import {
  CustomerAddress,
  CustomerAddressService,
  PagedCustomerAddress,
} from "../../../../../services/customer/CustomerAddressService";
import { toast } from "react-toastify";
import AddCustomerAddressModal from "../modal/AddCustomerAddressModal";

interface CustomerAddressesProps {
  customerAddresses: PagedCustomerAddress;
  currentCustomerId: string;
  defaultBillingAddressId: string;
  defaultShippingAddressId: string;
}

const CustomerAddresses: React.FC<CustomerAddressesProps> = ({
  customerAddresses,
  currentCustomerId,
  defaultBillingAddressId,
  defaultShippingAddressId,
}) => {
  const [showAddCustomerContactModal, setAddCustomerContactModal] =
    useState(false);

  // Initialize selectedContact with initial values
  const [selectedAddress, setSelectedAddress] = useState<CustomerAddress>({
    lineOne: "",
    lineTwo: "",
    lineThree: "",
    lineFour: "",
    lineFive: "",
    postCode: "",
  });
  const [currentCustomerAddresses, setCurrentCustomerAddresses] =
    useState<PagedCustomerAddress>(customerAddresses);

  const openModal = (customerAddress: CustomerAddress) => {
    setSelectedAddress(customerAddress);
    setAddCustomerContactModal(true);
  };
  const {
    getAddresses,
    updateDefaultBillingAddress,
    updateDefaultShippingAddress,
    deleteAddress,
  } = CustomerAddressService();

  const updateAddressList = async () => {
    const updatedContactList = await getAddresses(currentCustomerId);
    setCurrentCustomerAddresses(updatedContactList);
  };

  const deleteCustomerAddress = async (addressId: string) => {
    await deleteAddress(currentCustomerId, addressId);
    updateAddressList();
    toast.success("Address deleted successfully");
  };

  const updateCurrentCustomerDefaultBillingAddress = async (
    addressId: string
  ) => {
    try {
      await updateDefaultBillingAddress(currentCustomerId, addressId);
      updateAddressList();
      toast.success("Default Billing Address updated successfully");
    } catch (e) {
      toast.error("Error updating Default Billing Address");
      return;
    }
  };
  const [selectedDefaultBillingAddress, setSelectedRadio] = useState(
    defaultBillingAddressId
  );
  const [selectedDefaultShippingAddress, setSelectedShippingRadio] = useState(
    defaultShippingAddressId
  );

  const handleDefaultBillingAddressRadioChange = async (addressId: string) => {
    setSelectedRadio(addressId);
    try {
      await updateCurrentCustomerDefaultBillingAddress(addressId);
      updateAddressList();
    } catch (e) {
      setSelectedRadio(defaultBillingAddressId); // Revert to the original selection in case of error
    }
  };

  const updateCurrentCustomerDefaultShippingAddress = async (
    addressId: string
  ) => {
    setSelectedShippingRadio(addressId);
    try {
      await updateDefaultShippingAddress(currentCustomerId, addressId);
      updateAddressList();
      toast.success("Default Shipping Address updated successfully");
    } catch (e) {
      toast.error("Error updating Default Shipping Address");
      return;
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-success"
          onClick={() => openModal(selectedAddress)}
        >
          + Add Contact
        </button>
      </div>
      {currentCustomerAddresses.resource.map((customerAddress, index) => (
        <ul className="list-group" key={index}>
          <li className="list-group-item mt-4 d-flex justify-content-between align-items-center">
            <div>
              {customerAddress.lineOne}
              {customerAddress.lineTwo && ", "}
              {customerAddress.lineTwo}
              {customerAddress.lineThree && ", "}
              {customerAddress.lineThree}
              {customerAddress.lineFour && ", "}
              {customerAddress.lineFour}
              {customerAddress.lineFive && ", "}
              {customerAddress.lineFive}
              {customerAddress.postCode && ", "}
              {customerAddress.postCode}
            </div>
            <div className="d-flex">
              <span className="mr-3" onClick={() => openModal(customerAddress)}>
                <Pencil style={{ marginRight: "0.2em" }} />
              </span>
              <span className="ms-3">
                <Trash
                  onClick={() => deleteCustomerAddress(customerAddress.id!)}
                />
              </span>
            </div>
          </li>
        </ul>
      ))}
      <h6 className="mt-4">Default Billing Address</h6>

      {currentCustomerAddresses.resource.map((customerAddress, index) => (
        <ul className="list-group" key={index}>
          <li className="list-group-item mt-3" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name="defaultBillingAddressRadios"
              id={`shippingAddressRadios${index}`}
              value={customerAddress.id}
              checked={customerAddress.id === selectedDefaultBillingAddress}
              onChange={() =>
                handleDefaultBillingAddressRadioChange(customerAddress.id!)
              }
              style={{ marginRight: "1em" }}
            />
            <label
              className="form-check-label"
              htmlFor={`exampleRadios${index}`}
            >
              {customerAddress.lineOne}
              {customerAddress.lineTwo && ", "}
              {customerAddress.lineTwo}
              {customerAddress.lineThree && ", "}
              {customerAddress.lineThree}
              {customerAddress.lineFour && ", "}
              {customerAddress.lineFour}
              {customerAddress.lineFive && ", "}
              {customerAddress.lineFive}
              {customerAddress.postCode && ", "}
              {customerAddress.postCode}
            </label>
          </li>
        </ul>
      ))}
      <h6 className="mt-4">Default Shipping Address</h6>
      {currentCustomerAddresses.resource.map((customerAddress, index) => (
        <ul className="list-group" key={index}>
          <li className="list-group-item mt-3" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name="defaultShippingAddressRadios"
              id={`shippingAddressRadios${index}`}
              value={customerAddress.id}
              checked={customerAddress.id === selectedDefaultShippingAddress}
              onChange={() =>
                updateCurrentCustomerDefaultShippingAddress(customerAddress.id!)
              }
              style={{ marginRight: "1em" }}
            />
            <label
              className="form-check-label"
              htmlFor={`shippingAddressRadios${index}`}
            >
              {customerAddress.lineOne}
              {customerAddress.lineTwo && ", "}
              {customerAddress.lineTwo}
              {customerAddress.lineThree && ", "}
              {customerAddress.lineThree}
              {customerAddress.lineFour && ", "}
              {customerAddress.lineFour}
              {customerAddress.lineFive && ", "}
              {customerAddress.lineFive}
              {customerAddress.postCode && ", "}
              {customerAddress.postCode}
            </label>
          </li>
        </ul>
      ))}
      <AddCustomerAddressModal
        show={showAddCustomerContactModal}
        close={() => setAddCustomerContactModal(false)}
        addresses={selectedAddress}
        customerId={currentCustomerId}
        onAddressChange={updateAddressList}
      />
    </div>
  );
};

export default CustomerAddresses;
