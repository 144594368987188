import React, {useEffect} from 'react';
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {InvykLogo} from "../../components/logo/InvykLogo";
import {useStore} from "../../services/state/useStore";
import {useNavigate} from "react-router-dom";
import {PRODUCTS} from "./PlatformStack";
import {LOGIN} from "../onboarding/OnBoardingStack";

interface Props {

}

const PlatformLoading = ({}: Props) => {

    const navigate = useNavigate();
    const { isLoaded, token } = useStore();

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        if (isLoaded && token) {
            navigate(PRODUCTS.uri);
            return;
        }

        navigate(LOGIN.uri);
    },[isLoaded, token]);

    return (
        <Container
            className={"contentContainer"}
            style={{
                height: "calc(100vh - 76px)",
                marginTop: "0px",
            }}
            fluid={true}
        >
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        minWidth: "50%",
                        width: "auto",
                        height: "auto",
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                    }}
                >
                    <Row>
                        <InvykLogo />
                    </Row>
                    <Col style={{display: 'flex', padding: '5em', justifyContent: 'center'}}>
                        <Spinner animation="border" />
                    </Col>
                </div>
            </div>
        </Container>
    )
}

export default PlatformLoading;