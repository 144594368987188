import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  ADD_PRODUCT,
  EDIT_MATERIAL,
  NEW_MATERIAL,
  PRODUCT,
} from "../../../PlatformStack"
import RefreshableSearchableTable from "../../../../../components/table/refreshableSearchableTable/RefreshableSearchableTable"
import {
  MaterialRow,
  MaterialsTableService,
} from "../../../../../services/materials/MaterialsTableService"

interface Props {}

const AllMaterialsTable = ({}: Props) => {
  const navigate = useNavigate()

  const { getMaterialsForTable, filter, columns } = MaterialsTableService()

  const [loading, setLoading] = useState<boolean>(false)

  const [materials, setMaterials] = useState<MaterialRow[]>([])
  const [data, setData] = useState<MaterialRow[]>([])

  useEffect(() => {
    loadTable()
  }, [])

  useEffect(() => {
    setData(materials)
  }, [materials])

  const onRowClick = (row: Object) => {
    let material = row as MaterialRow
    navigate(EDIT_MATERIAL.navigate([material.id]))
  }

  const toAddNewMaterial = () => {
    navigate(NEW_MATERIAL.uri)
  }

  const loadTable = async () => {
    setMaterials([])
    let materialTablePage = await getMaterialsForTable(setLoading)
    setMaterials(materialTablePage.rows)
  }

  return (
    <RefreshableSearchableTable
      data={data}
      columns={columns}
      loading={loading}
      refresh={loadTable}
      filterFunction={(searchValue) => setData(filter(materials, searchValue))}
      onRowClick={onRowClick}
      navigateToAddNewForm={toAddNewMaterial}
    />
  )
}

export default AllMaterialsTable
