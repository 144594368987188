import ApiClient from "../api/ApiClient";
import {PagedResource} from "../api/common/Types";
import {Customer} from "./CustomerService";

export interface CustomerAddress {
  id?: string;
  lineOne?: string;
  lineTwo?: string;
  lineThree?: string;
  lineFour?: string;
  lineFive?: string;
  postCode?: string;
}

export interface PagedCustomerAddress extends PagedResource {
  resource: CustomerAddress[];
}

export const CustomerAddressService = () => {
  const getAll = async (customerId: string): Promise<PagedCustomerAddress> => {
    return ApiClient.getInstance().post<PagedCustomerAddress>(
      `/api/1/customers/${customerId}/addresses-search`
    );
  };

  const get = async (
    customerId: string,
    id: string
  ): Promise<CustomerAddress> => {
    return ApiClient.getInstance().get<CustomerAddress>(
      `/api/1/customers/${customerId}/addresses/${id}`
    );
  };

  const create = async (
    customerId: string,
    address: CustomerAddress
  ): Promise<CustomerAddress> => {
    return ApiClient.getInstance().post<CustomerAddress>(
      `/api/1/customers/${customerId}/addresses`,
      {
        lineOne: address.lineOne,
        lineTwo: address.lineTwo,
        lineThree: address.lineThree,
        lineFour: address.lineFour,
        lineFive: address.lineFive,
        postCode: address.postCode,
      }
    );
  };

  const update = async (
    customerId: string,
    address: CustomerAddress
  ): Promise<CustomerAddress> => {
    return ApiClient.getInstance().put<CustomerAddress>(
      `/api/1/customers/${customerId}/addresses/${address.id}`,
      {
        lineOne: address.lineOne,
        lineTwo: address.lineTwo,
        lineThree: address.lineThree,
        lineFour: address.lineFour,
        lineFive: address.lineFive,
        postCode: address.postCode,
      }
    );
  };
  const updateDefaultBillingAddress = async (
    customerId: string,
    addressId: string
  ): Promise<Customer> => {
    return ApiClient.getInstance().put<Customer>(
      `/api/1/customers/update-default-billing-address`,
      {
        customerId: customerId,
        billingAddressId: addressId,
      }
    );
  };
  const updateDefaultShippingAddress = async (
    customerId: string,
    addressId: string
  ): Promise<Customer> => {
    return ApiClient.getInstance().put<Customer>(
      `/api/1/customers/update-default-shipping-address`,
      {
        customerId: customerId,
        shippingAddressId: addressId,
      }
    );
  };

  const deleteAddress = async (
    customerId: string,
    id: string
  ): Promise<void> => {
    return ApiClient.getInstance().delete<void>(
      `/api/1/customers/${customerId}/addresses/${id}`
    );
  };

  return {
    getAddresses: getAll,
    getAddress: get,
    createAddress: create,
    updateAddress: update,
    updateDefaultBillingAddress: updateDefaultBillingAddress,
    updateDefaultShippingAddress: updateDefaultShippingAddress,
    deleteAddress: deleteAddress,
  };
};
