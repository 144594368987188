import {Product, ProductService} from "./ProductService";
import {Tag} from "../tag/TagService";
import {toast} from "react-toastify";
import {logApiError} from "../api/ApiUtils";
import { ProductEquation, ProductEquationPriceCheck, ProductEquationService } from "./ProductEquationService";
import { Variant } from "./ProductVariantService";
import { TaxRuleService } from "../taxrules/TaxRuleService";

export enum ProductFormActionType {
    PRODUCT_LOADED = 'PRODUCT_LOADED',
    TAX_RULE_LOADED = 'TAX_RULE_LOADED',
    EQUATION_LOADED = 'EQUATION_LOADED',

    TITLE_UPDATE = 'TITLE_UPDATE',
    DESCRIPTION_UPDATE = 'DESCRIPTION_UPDATE',
    VAT_RULE_UPDATE = 'VAT_RULE_UPDATE',

    FULL_LENGTH_EQUATION_UPDATE = 'FULL_LENGTH_EQUATION_UPDATE',
    PER_FOOT_EQUATION_UPDATE = 'PER_FOOT_EQUATION_UPDATE',
}

export type ProductFormAction =
    { value: Product, type: ProductFormActionType.PRODUCT_LOADED } |
    {
        value: {
            hasVat: boolean,
            taxRuleId?: string
        }, type: ProductFormActionType.TAX_RULE_LOADED
    } |
    { value: ProductEquation, type: ProductFormActionType.EQUATION_LOADED } |
    { value: string, type: ProductFormActionType.TITLE_UPDATE } |
    { value: string, type: ProductFormActionType.DESCRIPTION_UPDATE } |
    { value: boolean, type: ProductFormActionType.VAT_RULE_UPDATE } |
    { value: string, type: ProductFormActionType.FULL_LENGTH_EQUATION_UPDATE } |
    { value: string, type: ProductFormActionType.PER_FOOT_EQUATION_UPDATE }

export interface ProductFormInfoState {
    id?: string
    title?: string
    description?: string
    hasVat?: boolean
    existingTaxRuleId?: string
}

export interface ProductEquationFormState {
    fullLength?: string
    perFoot?: string
    hasEdited: boolean
}

export interface ProductFormState {
    info: ProductFormInfoState
    equation: ProductEquationFormState
}

export const ProductFormService = () => {

    const { createProduct, updateProduct} = ProductService();
    const {updateProductEquation, deleteProductEquation} = ProductEquationService();
    const { createVatForProduct, deleteRule } = TaxRuleService();

    const create = async (title: string | undefined,
                          description: string | undefined,
                          tags: Tag[]): Promise<Product | undefined> => {
        if (!title) {
            // this should be handled by formik somehow
            toast.error('Can not create product, title is missing.')
            return;
        }
        try {
            let product = await createProduct(title, description);
            toast.success('Product created');
            return product
        } catch (e) {
            logApiError('Error creating product.', e);
        }
        return;
    }

    const update = async (productId: string,
                          title: string | undefined,
                          description: string | undefined,
                          tags: Tag[]) => {
        if (!title) {
            // this should be handled by formik somehow
            toast.error('Can not update product, title is missing.')
            return;
        }
        try {
            await updateProduct(productId, {
                title,
                description
            });
            toast.success('Product updated.');
        } catch (e) {
            logApiError('Error creating product.', e);
        }
    }

    const saveEquation = async (productId: string, equationState: ProductEquationFormState) => {
        if (!equationState.hasEdited) {
            return;
        }

        let fullLengthEquation = equationState.fullLength?.trim()
        let perFootEquation = equationState.perFoot?.trim()

        if ((!fullLengthEquation || fullLengthEquation === "") && (!perFootEquation || perFootEquation === "")) {
            try {
                await deleteProductEquation(productId)
            } catch (e) {
                logApiError('Error when deleting product equations', e)
            }
        } else {
            try {
                await updateProductEquation(productId, {
                    fullLength: fullLengthEquation ? fullLengthEquation : "",
                    perFoot: perFootEquation ? perFootEquation : "",
                })
            } catch (e) {
                logApiError('Error when updating product equations', e)
            }
        }
    }

    const save = (state: ProductFormState) => {
        if (state.info.id) {
            update(state.info.id,
                state.info.title,
                state.info.description,
                [],)
            saveEquation(state.info.id, state.equation)
            if (state.info.hasVat && !state.info.existingTaxRuleId) {
                createVatForProduct(state.info.id)
            } else if (!state.info.hasVat && state.info.existingTaxRuleId) {
                deleteRule(state.info.existingTaxRuleId)
            }
            return;
        }

        create(state.info.title,
            state.info.description,
            [],).then(r => {
            if (r && r.id) {
                saveEquation(r.id, state.equation)
                if (state.info.hasVat) {
                    createVatForProduct(r.id)
                }
            }
        })
    }

    const reducer = (state: ProductFormState, action: ProductFormAction): ProductFormState => {
        switch (action.type) {
            case ProductFormActionType.TITLE_UPDATE:
                return {
                    ...state,
                    info: {
                        ...state.info,
                        title: action.value
                    }
                }
            case ProductFormActionType.DESCRIPTION_UPDATE:
                return {
                    ...state,
                    info: {
                        ...state.info,
                        description: action.value
                    }
                }
            case ProductFormActionType.FULL_LENGTH_EQUATION_UPDATE:
                return {
                    ...state,
                    equation: {
                        ...state.equation,
                        fullLength: action.value,
                        hasEdited: true
                    }
                }
            case ProductFormActionType.PER_FOOT_EQUATION_UPDATE:
                return {
                    ...state,
                    equation: {
                        ...state.equation,
                        perFoot: action.value,
                        hasEdited: true
                    }
                }
            case ProductFormActionType.PRODUCT_LOADED:
                return {
                    ...state,
                    info: buildProductInfo(action.value),
                }
            case ProductFormActionType.EQUATION_LOADED:
                return {
                    ...state,
                    equation: {
                        fullLength: action.value.fullLength,
                        perFoot: action.value.perFoot,
                        hasEdited: false
                    },
                }
            case ProductFormActionType.TAX_RULE_LOADED:
                return {
                    ...state,
                    info: {
                        ...state.info,
                        hasVat: action.value.hasVat,
                        existingTaxRuleId: action.value.taxRuleId
                    }
                }
            case ProductFormActionType.VAT_RULE_UPDATE:
                return {
                    ...state,
                    info: {
                        ...state.info,
                        hasVat: action.value,
                    }
                }
        }
    };

    const buildProductInfo = (product: Product): ProductFormInfoState => {
        return {
            id: product.id,
            title: product.title,
            description: product.description
        }
    }

    return {
        saveProduct: save,
        reducer,
        initialState: {
            info: {},
            equation: {
                hasEdited: false
            }
        },
    }
}