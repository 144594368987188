import ApiClient from "../api/ApiClient";

export interface ItemBase {
    id?: string
    title: string
    code?: string
}

export interface Item extends ItemBase {
    id: string
}

export interface PagedItem {
    page: number
    size: number
    totalCount: number
    pageCount: number
    resource: Item[]
}

export const ItemService = () => {

    const getAll = (async (page: number, size: number): Promise<PagedItem> => {
        return ApiClient.getInstance().get<PagedItem>(`/api/1/items?page=${page}&size=${size}`);
    });

    const search = (async (search: string): Promise<PagedItem> => {
        return ApiClient.getInstance().get<PagedItem>(`/api/1/items?search=${search}`);
    });

    const create = (async (title: string, code: string): Promise<Item> => {
        return ApiClient.getInstance().post<Item>(`/api/1/items`, {
            title, code
        });
    });

    return {
        getItems: getAll,
        searchItems: search,
        createItem: create,
    }
}
