import React from 'react';
import AppPage from "../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import AllQuotesTable from "./parts/alltable/AllQuotesTable";

interface Props {

}

const Quotes = ({}: Props) => {

    return (
        <AppPage>
            <>
                <h3 className={"heading"}>Quotes</h3>

                <Breadcrumb>
                    <BreadcrumbItem active>Quotes</BreadcrumbItem>
                </Breadcrumb>
                <AllQuotesTable />
            </>
        </AppPage>
    )
}

export default Quotes;