import React from 'react';
import AppPage from "../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import PurchasesTabs from "./parts/PurchasesTabs";

interface Props {

}

const Purchases = ({}: Props) => {
    return (
        <AppPage>
            <>
                <h3 className={"heading"}>Purchases</h3>

                <Breadcrumb>
                    <BreadcrumbItem active>Purchases</BreadcrumbItem>
                </Breadcrumb>

                <PurchasesTabs />
            </>
        </AppPage>
    )
}

export default Purchases;