import { Trash } from "react-bootstrap-icons";

import {
    MiscOrderItem,
    OrderFormAction,
    OrderFormActionType
} from "../../../../services/order/OrderFormService";
import { Row, Form, Button, Table, InputGroup } from "react-bootstrap";
import { useState } from "react";

interface MiscItemsOrderFormProps {
    miscItem: MiscOrderItem;
    dispatch: (x: OrderFormAction) => void;
}

const MiscItemsOrderForm = ({
                                miscItem,
                                dispatch
                            }: MiscItemsOrderFormProps) => {
    const handleDeleteMiscItem = (miscItem: MiscOrderItem) => {
        dispatch({
            type: OrderFormActionType.REMOVE_MISC_ITEM,
            value: { name: miscItem.name }
        });
    };
    const handleUpdateMiscItem = (miscItem: MiscOrderItem) => {
        dispatch({
            type: OrderFormActionType.UPDATE_MISC_ITEM,
            value: { name: miscItem.name, quantity: quantity, price: price }
        });
    };
    const [price, setPrice] = useState<number>(1);
    const [quantity, setQuantity] = useState<number>(1);

    return (
        <>
            <div style={{ paddingTop: "0.5em" }}>
                {miscItem.name}

                <a
                    style={{
                        marginRight: "0.2em",
                        marginLeft: "0.2em",
                        color: "red",
                        cursor: "pointer"
                    }}
                >
                    <Trash
                        onClick={(e) => {
                            handleDeleteMiscItem(miscItem);
                        }}
                    />
                </a>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "0.5em",
                    borderBottom: "1px solid #adadad"
                }}
            >
                <div style={{ width: "90%" }}>
                    <Row style={{ backgroundColor: "#ededed", padding: "0.5em" }}>
                        <div style={{ display: "flex" }}>
                            <InputGroup>
                                <Form.Label style={{ whiteSpace: "nowrap" }}>
                                    Quantity:
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="form-input"
                                    type="number"
                                    name="Quantity"
                                    placeholder="Quantity"
                                    value={quantity}
                                    onChange={(e) => setQuantity(Number.parseInt(e.target.value))}
                                    style={{ marginLeft: "0.5em" }}
                                />
                            </InputGroup>
                            <InputGroup>
                                {" "}
                                <Form.Label style={{ whiteSpace: "nowrap", padding: 5 }}>
                                    Price:
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="form-input"
                                    type="number"
                                    name="Price"
                                    placeholder="Price"
                                    value={price}
                                    onChange={(e) => setPrice(Number.parseFloat(e.target.value))}
                                    style={{ marginLeft: "0.5em" }}
                                />
                            </InputGroup>

                            <Button
                                style={{ marginLeft: "0.5em", whiteSpace: "nowrap" }}
                                onClick={(e) => {
                                    handleUpdateMiscItem(miscItem);
                                }}
                            >
                                Add Item
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <Table style={{ marginTop: "1em" }}>
                            <thead>
                            <td style={{ width: "30%" }}>Name</td>
                            <td style={{ width: "30%" }}>Quantity</td>
                            <td style={{ width: "30%" }}>Price</td>
                            <td style={{ width: "10%" }}></td>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{miscItem.name}</td>
                                <td>{miscItem.quantity}</td>
                                <td>{miscItem.price}</td>
                                <td>
                                    <a
                                        style={{
                                            marginRight: "0.2em",
                                            marginLeft: "0.2em",
                                            color: "red",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <Trash
                                            onClick={(e) => {
                                                handleDeleteMiscItem(miscItem);
                                            }}
                                        />
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                </div>
            </div>
        </>
    );
};
export default MiscItemsOrderForm;
