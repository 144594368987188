export interface LocalStorageConfig {
    token?: string
    accountId?: string
}

const CONFIG = 'config';

export const LocalStorageService = () => {

    const getConfig: () => LocalStorageConfig|undefined = () => {
        const localStorageResp = localStorage.getItem(CONFIG);
        return localStorageResp == null ? undefined : JSON.parse(localStorageResp)
    }

    const updateConfig: (updatedConfig: LocalStorageConfig) => LocalStorageConfig|undefined = (updatedConfig: LocalStorageConfig) => {
        const existingConfig = getConfig();
        const newConfig = existingConfig ? {
            ...existingConfig,
            ...updatedConfig
        } : updatedConfig;

        localStorage.setItem(CONFIG, JSON.stringify(newConfig));
        return newConfig;
    }

    const deleteConfig: () => void = () => {
        localStorage.setItem(CONFIG, JSON.stringify({}));
    }

    return {
        deleteConfig,
        updateConfig,
        getConfig
    }
}
