import React from 'react';
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import AppPage from "../components/page/AppPage";
import AllCustomersTable from "./parts/alltable/AllCustomersTable";

interface Props {

}

const Customers = ({}: Props) => {
    return (
        <AppPage>
            <>
                <h3 className={"heading"}>Customers</h3>

                <Breadcrumb>
                    <BreadcrumbItem active>Customers</BreadcrumbItem>
                </Breadcrumb>

                <AllCustomersTable />
            </>
        </AppPage>
    )
}

export default Customers;