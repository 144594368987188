import {CategoryService, PagedCategory} from "./CategoryService";
import {Data} from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import {logApiError} from "../api/ApiUtils";

export interface CategoryRow extends Data {
    id: string
    name: string
    productCount: string
}

export interface CategoryTablePage {
    totalCount: number
    pageCount: number
    page: number
    rows: CategoryRow[]
}

export const AllCategoriesTableService = () => {

    const { getCategories } = CategoryService();

    const getCategoriesForTable =
        (async (setLoading: (value: boolean) => void, size: number, cursor?: object): Promise<CategoryTablePage> => {
        setLoading(true);
        try {
            const pagedCategory: PagedCategory = await getCategories(size, cursor);
            return {
                totalCount: pagedCategory.totalCount,
                pageCount: pagedCategory.pageCount,
                page: pagedCategory.page,
                rows: pagedCategory.resource.map((category) => {
                    return {
                        id: category.id ? category.id : '',
                        name: category.name,
                        productCount: "" + (category.products ? category.products.length : 0)
                    }
                })
            }
        } catch (e) {
            logApiError('Error loading categories.', e);
        } finally {
            setLoading(false);
        }
        return new Promise(() => []);
    });

    const filter: (categories: CategoryRow[], searchValue: string) => CategoryRow[] =
        ((categories: CategoryRow[], searchValue: string) => {
        if (!searchValue) {
            return categories;
        }
        return categories.filter((category) =>
            category.productCount == searchValue ||
            category.name.toLowerCase().includes(searchValue.toLowerCase()));
    });

    return {
        getCategoriesForTable,
        filter,
        columns: [
            {
                title: 'Name',
                key: 'name'
            },
            {
                title: 'No. of Products',
                key: 'productCount'
            },
        ]
    }
}
