import React from 'react';
import * as yup from "yup";
import {Alert, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {
    ProductFormAction,
    ProductFormActionType,
    ProductFormInfoState
} from "../../../../../services/product/ProductFormService";

interface Props {
    info: ProductFormInfoState
    dispatch: (x: ProductFormAction) => void
}

const ProductInfoForm = ({info, dispatch}: Props) => {

    const schema = yup.object().shape({
        title: yup.string().required("Name is required"),
        description: yup.string().nullable()
    });

    return (
        <Formik
            onSubmit={() => {}}
            validationSchema={schema}
            enableReinitialize={true}
            initialValues={{
                title: info.title,
                description: info.description
            }}
        >
            {({
                  handleChange,
                  handleBlur,
                  touched,
                  errors
              }) => (
                <Form className="productForm_card_form" noValidate>
                    <Form.Group as={Row} className="mb-3" controlId="categoryForm.title">
                        <Form.Label>
                            Title
                        </Form.Label>
                        <Form.Control
                            required
                            className="form-input"
                            type="text"
                            name="title"
                            placeholder="Name"
                            value={info.title}
                            onBlur={handleBlur}
                            onChange={(e) => {
                                dispatch({
                                    type: ProductFormActionType.TITLE_UPDATE,
                                    value: e.target.value
                                });
                                handleChange(e);

                            }}
                        />
                        {touched.title && errors.title && (
                            <Alert variant="danger">
                                {errors.title}
                            </Alert>
                        )}
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="categoryForm.description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            className="form-input"
                            placeholder="Description"
                            name="description"
                            value={info.description}
                            onBlur={handleBlur}
                            onChange={(e) => {
                                dispatch({
                                    type: ProductFormActionType.DESCRIPTION_UPDATE,
                                    value: e.target.value
                                });
                                handleChange(e);
                            }}
                            as="textarea"
                            type="text"
                            rows={6}
                        />
                        {touched.description && errors.description && (
                            <Alert variant="danger">
                                {errors.description}
                            </Alert>
                        )}
                    </Form.Group>
                </Form>
            )}
        </Formik>
    )
}

export default ProductInfoForm;