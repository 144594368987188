import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ProductRow, ProductsTableService} from "../../../../../services/product/ProductsTableService";
import {ADD_PRODUCT, PRODUCT} from "../../../PlatformStack";
import {Product} from "../../../../../services/product/ProductService";
import RefreshableSearchableTable
    from "../../../../../components/table/refreshableSearchableTable/RefreshableSearchableTable";

interface Props {
    products: Product[]
    reload: () => void
}

const ProductsTable = ({ products, reload }: Props) => {

    const navigate = useNavigate();

    const { productsToRows, filter, columns } = ProductsTableService();

    const [loading, setLoading] = useState<boolean>(false);

    const [rows, setRows] = useState<ProductRow[]>([]);
    const [data, setData] = useState<ProductRow[]>([]);

    useEffect(() => {
        const productRows = productsToRows(products);
        setRows(productRows);
    }, [products]);

    useEffect(() => {
        setData(rows);
    }, [rows]);

    const onRowClick = (row: Object) => {
        let product = row as ProductRow;
        navigate(PRODUCT.navigate([product.id]));
    }

    const toAddNewProduct = () => {
        navigate(ADD_PRODUCT.uri)
    }

    return (
        <RefreshableSearchableTable
            data={data}
            columns={columns}
            loading={loading}
            refresh={reload}
            filterFunction={(searchValue) => setData(filter(rows, searchValue))}
            onRowClick={onRowClick}
            navigateToAddNewForm={toAddNewProduct}
        />
    );
}

export default ProductsTable;