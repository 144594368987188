import React from 'react';
import AppPage from "../../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem, Container} from "react-bootstrap";
import {MATERIALS} from "../../PlatformStack";
import {useNavigate} from "react-router-dom";
import MaterialForm from "../parts/forms/MaterialForm";

interface Props {

}

const NewMaterial = ({}: Props) => {

    const navigate = useNavigate();

    return (
        <AppPage>
            <h3 className="heading">Add Material</h3>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(MATERIALS.uri)}>Materials</BreadcrumbItem>
                <BreadcrumbItem active>Add</BreadcrumbItem>
            </Breadcrumb>

            <Container>
                <MaterialForm />
            </Container>
        </AppPage>
    )
}

export default NewMaterial;