import React from 'react';
import AppPage from "../../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import {CUSTOMERS} from "../../PlatformStack";
import {useNavigate} from "react-router-dom";
import CustomerForm from "../parts/form/CustomerForm";

interface Props {

}

const AddCustomer = ({}: Props) => {

    const navigate = useNavigate();

    return (
        <AppPage>
            <h3 className="heading">Add Customer</h3>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(CUSTOMERS.uri)}>Customer</BreadcrumbItem>
                <BreadcrumbItem active>Add</BreadcrumbItem>
            </Breadcrumb>

            <CustomerForm />
        </AppPage>
    )
}

export default AddCustomer;