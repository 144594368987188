import React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { InvykLogo } from "../../../../components/logo/InvykLogo";
import * as yup from "yup";
import { Formik } from "formik";
import { CredentialsService } from "../../../../services/credientials/CredentialsService";
import { logApiError } from "../../../../services/api/ApiUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CONFIRM_EMAIL } from "../../OnBoardingStack";

export const RegisterForm = () => {

    const { createCredentials } = CredentialsService();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        email: yup.string().email().required("Email is required"),
        password: yup.string()
            .required("Password is required")
            .matches(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
                "Password should contain, at least: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number"),
        passwordConfirm: yup
            .string()
            .required("Password confirmation is required")
            .oneOf([yup.ref("password"), null], "Passwords must match")
    });

    const submitForm = async (values: any) => {
        createCredentials({
            email: values.email,
            password: values.password
        }).then(() => {
            toast.success("New account created.");
            navigate(CONFIRM_EMAIL.uri, {
                state: {
                    email: values.email,
                    password: values.password
                }
            });
        }).catch((err) => {
            logApiError("Error registering your account.", err);
        });
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={submitForm}
            initialValues={{
                email: "",
                password: "",
                passwordConfirm: ""
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <InvykLogo />

                    <Form.Group className="mb-3" controlId="registerForm.email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {touched.email && errors.email && (
                            <Alert variant="danger">
                                {errors.email}
                            </Alert>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="registerForm.password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {touched.password && errors.password && (
                            <Alert variant="danger">
                                {errors.password}
                            </Alert>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="registerForm.passwordConfirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            name="passwordConfirm"
                            placeholder="Password Confirmation"
                            onBlur={handleBlur}
                            value={values.passwordConfirm}
                            onChange={handleChange}
                        />
                        {touched.passwordConfirm && errors.passwordConfirm && (
                            <Alert variant="danger">
                                {errors.passwordConfirm}
                            </Alert>
                        )}
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Register
                    </Button>
                </Form>
            )}
        </Formik>
    );
};