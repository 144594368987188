import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import RefreshableSearchablePageableTable
    from "../../../../../components/table/refreshableSearchablePageableTable/RefreshableSearchablePageableTable";
import {ItemRow, ItemsTableService} from "../../../../../services/purchases/ItemTableService";

interface Props {
    addNewItem: () => void
}

const AllItemsTable = ({addNewItem}: Props) => {

    const navigate = useNavigate();

    const { getItemsForTable, filter, columns } = ItemsTableService();

    const [loading, setLoading] = useState<boolean>(false);

    const [items, setItems] = useState<ItemRow[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [data, setData] = useState<ItemRow[]>([]);

    useEffect(() => {
        setData(items);
    }, [items]);

    const onRowClick = (row: Object) => {
    }

    // update to use cursor
    const loadTableOld = async (page: number, size: number) => {
        setItems([]);
        let itemTablePage = await getItemsForTable(
            setLoading,
            page,
            size);
        setItems(itemTablePage.rows);
        setTotalCount(itemTablePage.totalCount);
        setPageCount(itemTablePage.pageCount);
    }

    const loadTable = async (size: number) => {
        return loadTableOld(1, size);
    }

    return (
        <RefreshableSearchablePageableTable
            totalCount={totalCount}
            pageCount={pageCount}
            data={data}
            columns={columns}
            loading={loading}
            loadTable={loadTable}
            filterFunction={(searchValue) => setData(filter(items, searchValue))}
            onRowClick={onRowClick}
            navigateToAddNewForm={addNewItem}
        />
    );
}

export default AllItemsTable;