import { useEffect, useState } from "react";
import { CustomerAddress } from "../../../../services/customer/CustomerAddressService";
import {
    CustomerContact,
    CustomerContactService,
    PagedCustomerContacts
} from "../../../../services/customer/CustomerContactService";
import {
    OrderFormAction,
    OrderFormActionType
} from "../../../../services/order/OrderFormService";
import AddCustomerContactModal from "../../customers/parts/modal/AddCustomerContactModal";

interface CustomerAddressSelectionProps {
    currentCustomerId: string;
    defaultContactId: string;
    dispatch: (x: OrderFormAction) => void;
}

const CustomerContactSelection = ({
                                      currentCustomerId,
                                      defaultContactId,
                                      dispatch
                                  }: CustomerAddressSelectionProps) => {

    const [hasContacts, setHasContacts] = useState<boolean>(false);
    const [currentCustomerContacts, setCurrentCustomerContacts] = useState<PagedCustomerContacts | null>(null);
    const { getContacts } = CustomerContactService();

    const fetchCustomerAddresses = async () => {
        const contacts = await getContacts(currentCustomerId);
        setCurrentCustomerContacts(contacts);
        setHasContacts(contacts?.resource?.length > 0);
    };

    useEffect(() => {
        dispatch({
            type: OrderFormActionType.CONTACT_SELECTED,
            value: defaultContactId
        });
        fetchCustomerAddresses();
    }, [currentCustomerId]);

    // Initialize selectedContact with initial values
    const [selectedContact, setSelectedContact] = useState<CustomerContact>({
        name: "",
        email: "",
        phoneNumber: ""
    });
    const [showAddCustomerContactModal, setAddCustomerContactModal] =
        useState(false);

    const openModal = (customerAddress: CustomerAddress) => {
        setSelectedContact(customerAddress);
        setAddCustomerContactModal(true);
    };

    const [selectedOrderContact, setSelectedRadio] = useState(defaultContactId);

    const updateContactList = async () => {
        const updatedContactList = await getContacts(currentCustomerId);
        setCurrentCustomerContacts(updatedContactList);
    };
    const handleOrderContactChange = async (contactId: string) => {
        dispatch({
            type: OrderFormActionType.CONTACT_SELECTED,
            value: contactId
        });
        setSelectedRadio(contactId);
    };

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h6 className="mt-4">Select Contact</h6>
                <button
                    className="btn btn-success"
                    onClick={() => openModal(selectedContact)}
                >
                    + Add New Contact
                </button>
            </div>
            {!hasContacts && <span>No contacts on record.</span>}

            {currentCustomerContacts &&
                currentCustomerContacts.resource.map((contact, index) => (
                    <ul className="list-group" key={index}>
                        <li className="list-group-item mt-3" key={index}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="defaultContactRadios"
                                id={`exampleRadios${index}`}
                                value={contact.id}
                                checked={contact.id === selectedOrderContact}
                                onChange={() => handleOrderContactChange(contact.id!)}
                                style={{ marginRight: "1em" }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`exampleRadios${index}`}
                            >
                                {contact.name}
                                {contact.email && ", "}
                                {contact.email}
                                {contact.phoneNumber && ", "}
                                {contact.phoneNumber}
                            </label>
                        </li>
                    </ul>
                ))}

            <AddCustomerContactModal
                show={showAddCustomerContactModal}
                close={() => setAddCustomerContactModal(false)}
                contacts={selectedContact}
                customerId={currentCustomerId}
                onContactChange={updateContactList}
            />
        </div>
    );
};
export default CustomerContactSelection;
