import {Data} from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import {logApiError} from "../api/ApiUtils";
import {PagedPayments, Payment, PaymentService} from "./PaymentService";
import { Trash } from "react-bootstrap-icons";

export interface PaymentRow extends Data {
    documentId?: string
    ref: string
    paymentSource: string
    paymentType?: string
    timestamp: string
    amount: number
    amountDisplayString: string
    customerId: string
    orderId?: string
}

export interface PaymentsTablePage {
    totalCount: number
    pageCount: number
    page: number
    rows: PaymentRow[]
}

export const PaymentsTableService = () => {

    const {getPayments, getPaymentsByCustomerId} = PaymentService();

    const getPaymentsForTable: (setLoading: (value: boolean) => void)
        => Promise<PaymentsTablePage> = (async (setLoading: (value: boolean) => void) => {
        setLoading(true);
        try {
            const pagedMaterial: PagedPayments = await getPayments();
            return {
                totalCount: pagedMaterial.totalCount,
                pageCount: pagedMaterial.pageCount,
                page: pagedMaterial.page,
                rows: pagedMaterial.resource.map((payment) => {
                    return {
                        id: payment.id,
                        documentId: payment.documentId,
                        ref: payment.ref,
                        paymentSource: payment.source,
                        includes: payment.includes,
                        timestamp: payment.timestamp,
                        amount: payment.amount,
                        amountDisplayString: payment.amountDisplayString,
                        customerId: payment.customerId,
                        orderId: payment.orderId,
                    }
                })
            }
        } catch (e) {
            logApiError('Error loading payments.', e);
        } finally {
            setLoading(false);
        }
        return new Promise(() => []);
    });
    const getCustomerPaymentsForTable
        = (async (setLoading: (value: boolean) => void, customerId: string, showAlertForm: (custId: string, payId: string) => void): Promise<PaymentRow[]> => {
        setLoading(true);
        try {
            if (customerId != null) {
                const pagedPayments: PagedPayments = await getPaymentsByCustomerId(customerId);
                return paymentsToRows(pagedPayments, showAlertForm);
            }

        } catch (e) {
            logApiError('Error loading payments.', e);
        } finally {
            setLoading(false);
        }
        return new Promise(() => []);
    });

    const paymentsToRows = (pagedPayments: PagedPayments, showAlertForm: (custId: string, payId: string) => void): PaymentRow[] => {
        return pagedPayments.resource.map((payment) => {
            return {
                id: payment.id,
                documentId: payment.documentId,
                ref: payment.ref,
                paymentSource: payment.source,
                paymentType: payment.includes[0].type,
                timestamp: payment.timestamp,
                amount: payment.amount,
                amountDisplayString: payment.amountDisplayString,
                customerId: payment.customerId,
                orderId: payment.orderId,
                actions: (
                    <>
                        <Trash onClick={() => showAlertForm(payment.customerId, payment.id)} />
                    </>
                ),

            }
        })
    }

    const filter = ((payments: PaymentRow[], searchValue: string): PaymentRow[] => {
        if (!searchValue) {
            return payments;
        }
        return payments.filter((payment) =>
            payment.ref.toLowerCase().includes(searchValue.toLowerCase()));
    });

    return {
        getPaymentsForTable,
        getCustomerPaymentsForTable,
        filter,
        materialsToRows: paymentsToRows,
        columns: [
            {
                title: 'Ref',
                key: 'ref'
            },
            {
                title: 'Source',
                key: 'paymentSource'
            },

            {
                title: 'Type',
                key: 'paymentType'
            },
            {
                title: 'Date',
                key: 'timestamp'
            },
            {
                title: 'Amount',
                key: 'amountDisplayString'
            },
            {
                title: "",
                key: "actions",
                style: {
                    width: "10%",
                    textAlign: "end" as "end",
                },
            },
        ]
    }
}
