import React from "react";
import AppPage from "../components/page/AppPage";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import AllInvoicesTable from "./parts/alltable/AllInvoicesTable";

interface Props {}

const Invoices = ({}: Props) => {
  return (
    <AppPage>
      <>
        <h3 className={"heading"}>Invoices</h3>

        <Breadcrumb>
          <BreadcrumbItem active>Invoices</BreadcrumbItem>
        </Breadcrumb>

        <AllInvoicesTable />
      </>
    </AppPage>
  );
};

export default Invoices;
