import { useState } from "react";
import {
    Button,
    Dropdown,
    DropdownButton,
    Form,
    InputGroup,
    Row,
    Table
} from "react-bootstrap";
import { Pencil, Trash } from "react-bootstrap-icons";
import {
    CutMeasurement,
    OrderFormAction,
    OrderFormActionType,
    OrderFormService,
    OrderItem
} from "../../../../services/order/OrderFormService";
import { PRODUCT } from "../../PlatformStack";

interface Props {
    orderItem: OrderItem;
    dispatch: (x: OrderFormAction) => void;
    onNewCutAdded: (
        variantId: string,
        quantity: number,
        isFullLength: boolean,
        cutLength?: number,
        lengthMeasurement?: CutMeasurement
    ) => void;
}

const OrderItemLine = ({ orderItem, dispatch, onNewCutAdded }: Props) => {
    const [isFullLength, setIsFullLength] = useState<boolean>(true);
    const [customLength, setCustomLength] = useState<number>();
    const [lengthMeasurement, setLengthMeasurement] = useState<CutMeasurement>(
        CutMeasurement.FEET
    );
    const [quantity, setQuantity] = useState<number>(1);

    const { isSameCut } = OrderFormService();

    const addCut = () => {
        let cut = {
            isFullLength: isFullLength,
            length: customLength,
            measurement: lengthMeasurement
        };
        let existingQuantity = 0;
        let lines = orderItem.lines?.filter((l) => isSameCut(l.cut, cut));
        if (lines && lines.length === 1) {
            existingQuantity = lines[0].quantity;
        }
        let finalQuantity = existingQuantity + quantity;

        dispatch({
            type: OrderFormActionType.ADD_CUT,
            value: {
                variantId: orderItem.variantId,
                quantity: finalQuantity,
                cut: cut
            }
        });

        onNewCutAdded(
            orderItem.variantId,
            finalQuantity,
            isFullLength,
            customLength,
            lengthMeasurement
        );

        setIsFullLength(true);
        setCustomLength(undefined);
        setLengthMeasurement(CutMeasurement.FEET);
        setQuantity(1);
    };

    return (
        <>
            <div style={{ paddingTop: "0.5em" }}>
                {orderItem.variantCode + " - " + orderItem.title}

                <a
                    style={{
                        marginRight: "0.2em",
                        marginLeft: "0.2em",
                        color: "red",
                        cursor: "pointer"
                    }}
                >
                    <Trash
                        onClick={() =>
                            dispatch({
                                type: OrderFormActionType.REMOVE_VARIANT,
                                value: {
                                    variantId: orderItem.variantId
                                }
                            })
                        }
                    />
                </a>
            </div>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "0.5em",
                    borderBottom: "1px solid #adadad"
                }}
            >
                <div style={{ width: "90%" }}>
                    <div style={{}}>
            <span>
              <b>Pricing</b>
            </span>
                        <a
                            href={PRODUCT.uri.replace(":id", orderItem.productId)}
                            target="_blank"
                            style={{ color: "blue", cursor: "pointer", marginLeft: "0.2em" }}
                        >
                            <Pencil />
                        </a>
                        {/** TODO:: DO I NEED TO REPLACE THIS? **/}
                        {/*<a style={{ marginRight: '0.2em', marginLeft: '0.2em', color: 'blue', cursor: 'pointer' }}>*/}
                        {/*    <ArrowClockwise*/}
                        {/*        onClick={() => getPriceRulesForVariant(orderItem.variantId)}*/}
                        {/*    />*/}
                        {/*</a>*/}
                        {/*<br/>*/}
                        {/*<span>Full Length: £{orderItem.fullLengthPrice?.band ? Math.round(orderItem.fullLengthPrice?.band) / 100 : '0.00'}</span> <br/>*/}
                        {/*<span>Per Foot Price: £{orderItem.cutPrice?.band ? Math.round(orderItem.cutPrice?.band) / 100 : '0.00'}</span>*/}
                    </div>

                    <Row style={{ backgroundColor: "#ededed", padding: "0.5em" }}>
                        <div style={{ display: "flex" }}>
                            <Form.Label
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingRight: "0.5em",
                                    margin: 0,
                                    whiteSpace: "nowrap",
                                    lineHeight: "2.5em"
                                }}
                            >
                                Full Length
                            </Form.Label>
                            <Form.Check
                                type="radio"
                                name={"length-" + orderItem.variantId}
                                checked={isFullLength}
                                onChange={(chge) => setIsFullLength(chge.target.checked)}
                                style={{ paddingTop: "0.5em", marginRight: "0.5em" }}
                            />

                            <Form.Label
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingRight: "0.5em",
                                    margin: 0,
                                    whiteSpace: "nowrap",
                                    lineHeight: "2.5em"
                                }}
                            >
                                Custom Length
                            </Form.Label>
                            <Form.Check
                                type="radio"
                                name={"length-" + orderItem.variantId}
                                checked={!isFullLength}
                                onChange={(chge) => setIsFullLength(!chge.target.checked)}
                                style={{ paddingTop: "0.5em", marginRight: "0.5em" }}
                            />

                            <InputGroup>
                                <Form.Control
                                    required
                                    className="form-input"
                                    type="number"
                                    name="Length"
                                    placeholder="Length"
                                    disabled={isFullLength}
                                    value={customLength || ""}
                                    onChange={(e) =>
                                        setCustomLength(Number.parseFloat(e.target.value))
                                    }
                                    style={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        paddingRight: "0.5em",
                                        margin: 0
                                    }}
                                />
                                <DropdownButton
                                    variant="outline-secondary"
                                    title={lengthMeasurement}
                                    id={"length-measurement"}
                                    align="end"
                                    disabled={isFullLength}
                                >
                                    <Dropdown.Item
                                        disabled
                                        onClick={() =>
                                            setLengthMeasurement(CutMeasurement.CENTIMETRE)
                                        }
                                    >
                                        Centimetre
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled
                                        onClick={() => setLengthMeasurement(CutMeasurement.METRE)}
                                    >
                                        Metre
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={() => setLengthMeasurement(CutMeasurement.FEET)}
                                    >
                                        Foot
                                    </Dropdown.Item>
                                </DropdownButton>
                            </InputGroup>
                            <Form.Control
                                required
                                className="form-input"
                                type="number"
                                name="Quantity"
                                placeholder="Quantity"
                                value={quantity}
                                onChange={(e) => setQuantity(Number.parseInt(e.target.value))}
                                style={{ marginLeft: "0.5em" }}
                            />
                            <Button
                                style={{ marginLeft: "0.5em", whiteSpace: "nowrap" }}
                                onClick={addCut}
                            >
                                Add Cut
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <Table style={{ marginTop: "1em" }}>
                            <thead>
                            <td style={{ width: "30%" }}>Length</td>
                            <td style={{ width: "30%" }}>Quantity</td>
                            <td style={{ width: "30%" }}>Price</td>
                            <td style={{ width: "10%" }}></td>
                            </thead>
                            <tbody>
                            {orderItem.lines?.map((line) => {
                                let cut = line.cut;
                                return (
                                    <tr
                                        key={
                                            orderItem.variantId +
                                            line.cut.length +
                                            line.cut.measurement
                                        }
                                    >
                                        <td>
                                            {cut.isFullLength
                                                ? "Full Length"
                                                : "" + cut.length + cut.measurement}
                                        </td>
                                        <td>{line.quantity}</td>
                                        <td>{line.displayPrice}</td>
                                        <td style={{ width: "15%" }}>
                                            <a
                                                style={{
                                                    marginRight: "0.2em",
                                                    marginLeft: "0.2em",
                                                    color: "red",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <Trash
                                                    onClick={() =>
                                                        dispatch({
                                                            type: OrderFormActionType.REMOVE_CUT,
                                                            value: {
                                                                variantId: orderItem.variantId,
                                                                cut: cut
                                                            }
                                                        })
                                                    }
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default OrderItemLine;
