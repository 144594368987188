import { useEffect, useState } from "react";
import {
    CustomerAddress,
    CustomerAddressService,
    PagedCustomerAddress
} from "../../../../services/customer/CustomerAddressService";
import AddCustomerAddressModal from "../../customers/parts/modal/AddCustomerAddressModal";
import {
    OrderFormAction,
    OrderFormActionType
} from "../../../../services/order/OrderFormService";

interface CustomerAddressSelectionProps {
    currentCustomerId: string;
    defaultShippingAddressId: string;
    defaultBillingAddressId: string;
    dispatch: (x: OrderFormAction) => void;
}

const CustomerAddressSelection = ({
                                      currentCustomerId,
                                      defaultBillingAddressId,
                                      defaultShippingAddressId,
                                      dispatch
                                  }: CustomerAddressSelectionProps) => {

    const [hasAddresses, setHasAddresses] = useState<boolean>(false);
    const [currentCustomerAddresses, setCurrentCustomerAddresses] = useState<PagedCustomerAddress | null>(null);
    const { getAddresses } = CustomerAddressService();

    const fetchCustomerAddresses = async () => {
        const addresses = await getAddresses(currentCustomerId);
        setCurrentCustomerAddresses(addresses);
        setHasAddresses(addresses != null && addresses.resource != null && addresses.resource.length > 0)
    };

    useEffect(() => {
        dispatch({
            type: OrderFormActionType.BILLING_ADDRESS_SELECTED,
            value: defaultBillingAddressId
        });
        dispatch({
            type: OrderFormActionType.SHIPPING_ADDRESS_SELECTED,
            value: defaultShippingAddressId
        });
        fetchCustomerAddresses();
    }, [currentCustomerId]);

    // Initialize selectedContact with initial values
    const [selectedAddress, setSelectedAddress] = useState<CustomerAddress>({
        lineOne: "",
        lineTwo: "",
        lineThree: "",
        lineFour: "",
        lineFive: "",
        postCode: ""
    });
    const [showAddCustomerContactModal, setAddCustomerContactModal] =
        useState(false);

    const openModal = (customerAddress: CustomerAddress) => {
        setSelectedAddress(customerAddress);
        setAddCustomerContactModal(true);
    };
    const [selectedOrderAddress, setSelectedRadio] = useState(
        defaultBillingAddressId
    );
    const [useDifferentBillingAddress, setUseDifferentBillingAddress] =
        useState(false);

    const [selectedDefaultBillingAddressId, setSelectedDefaultBillingAddressId] =
        useState(defaultBillingAddressId);

    const updateAddressList = async () => {
        const updatedContactList = await getAddresses(currentCustomerId);
        setCurrentCustomerAddresses(updatedContactList);
    };
    const handleOrderAddressChange = async (addressId: string) => {
        dispatch({
            type: OrderFormActionType.SHIPPING_ADDRESS_SELECTED,
            value: addressId
        });
        setSelectedRadio(addressId);
    };

    const handleOrderBillingAddressChange = async (addressId: string) => {
        dispatch({
            type: OrderFormActionType.BILLING_ADDRESS_SELECTED,
            value: addressId
        });
        setSelectedDefaultBillingAddressId(addressId);
    };

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h6 className="mt-4">Select Address</h6>
                <button
                    className="btn btn-success"
                    onClick={() => openModal(selectedAddress)}
                >
                    + Add New Address
                </button>
            </div>
            {!hasAddresses && <span>No addresses on record.</span>}

            {currentCustomerAddresses &&
                currentCustomerAddresses.resource.map((customerAddress, index) => (
                    <ul className="list-group" key={index}>
                        <li className="list-group-item mt-3" key={index}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="defaultBillingAddressRadios"
                                id={`exampleRadios${index}`}
                                value={customerAddress.id}
                                checked={customerAddress.id === selectedOrderAddress}
                                onChange={() => handleOrderAddressChange(customerAddress.id!)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`exampleRadios${index}`}
                            >
                                &nbsp;{customerAddress.lineOne}&nbsp;
                                {customerAddress.lineTwo && ", "}
                                {customerAddress.lineTwo}&nbsp;
                                {customerAddress.lineThree && ", "}
                                {customerAddress.lineThree}&nbsp;
                                {customerAddress.lineFour && ", "}
                                {customerAddress.lineFour}&nbsp;
                                {customerAddress.lineFive && ", "}
                                {customerAddress.lineFive}&nbsp;
                                {customerAddress.postCode && ", "}
                                {customerAddress.postCode}
                            </label>
                        </li>
                    </ul>
                ))}

            {hasAddresses && <div className="form-check mt-4">
              <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={() => {
                      setUseDifferentBillingAddress(!useDifferentBillingAddress);
                  }}
              />
              <label>Use Different Billing Address?</label>
            </div>}

            {useDifferentBillingAddress
                ? currentCustomerAddresses &&
                currentCustomerAddresses.resource.map((customerAddress, index) => (
                    <ul className="list-group" key={index}>
                        <li className="list-group-item mt-3" key={index}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="defaultShippingAddressRadios"
                                id={`shippingAddressRadios${index}`}
                                value={customerAddress.id}
                                checked={
                                    customerAddress.id === selectedDefaultBillingAddressId
                                }
                                onChange={() =>
                                    handleOrderBillingAddressChange(customerAddress.id!)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`shippingAddressRadios${index}`}
                            >
                                &nbsp;{customerAddress.lineOne}&nbsp;
                                {customerAddress.lineTwo && ", "}
                                {customerAddress.lineTwo}&nbsp;
                                {customerAddress.lineThree && ", "}
                                {customerAddress.lineThree}&nbsp;
                                {customerAddress.lineFour && ", "}
                                {customerAddress.lineFour}&nbsp;
                                {customerAddress.lineFive && ", "}
                                {customerAddress.lineFive}&nbsp;
                                {customerAddress.postCode && ", "}
                                {customerAddress.postCode}
                            </label>
                        </li>
                    </ul>
                ))
                : null}
            <AddCustomerAddressModal
                show={showAddCustomerContactModal}
                close={() => setAddCustomerContactModal(false)}
                addresses={selectedAddress}
                customerId={currentCustomerId}
                onAddressChange={updateAddressList}
            />
        </div>
    );
};
export default CustomerAddressSelection;
