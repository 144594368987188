import React from 'react';
import {Product} from "../../../../../services/product/ProductService";
import VariantsTable from "../table/VariantsTable";

interface Props {
    product: Product
}

const VariantsTab = ({product}: Props) => {

    return (
        <VariantsTable product={product} />
    )
}

export default VariantsTab;