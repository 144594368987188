import React, {useEffect, useState} from 'react';
import AppPage from '../../components/page/AppPage';
import {useNavigate, useParams} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem, Spinner} from "react-bootstrap";
import {CATEGORIES} from "../../PlatformStack";
import {Category, CategoryService} from "../../../../services/category/CategoryService";
import {logApiError} from "../../../../services/api/ApiUtils";
import CategoryHeader from "../parts/CategoryHeader";
import CategoryTabs from "../parts/CategoryTabs";

interface Props {

}

const SpecificCategory = ({}: Props) => {

    const { id } = useParams();
    const navigate = useNavigate();

    const { getCategory } = CategoryService();

    const [loading, setLoading] = useState<boolean>();
    const [category, setCategory] = useState<Category>();

    useEffect(() => {
        loadCategory();
    }, []);

    const loadCategory = async () => {
        if (loading || !id) {
            return;
        }

        setLoading(true);
        try {
            const resp = await getCategory(id);
            setCategory(resp);
        } catch (e) {
            logApiError('Error loading category.', e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <AppPage>
            <>

                {loading && <>
                    <Spinner animation={"border"} />
                    <p>Loading...</p>
                    </>}

                {!loading && category && id && <>
                    <Breadcrumb>
                        <BreadcrumbItem onClick={() => navigate(CATEGORIES.uri)}>Categories</BreadcrumbItem>
                        <BreadcrumbItem active>{category.name}</BreadcrumbItem>
                    </Breadcrumb>

                    <CategoryHeader id={id} category={category} />
                    <CategoryTabs category={category} reload={loadCategory} />
                </>}
            </>
        </AppPage>
    )
}

export default SpecificCategory;