import { toast } from "react-toastify";
import { logApiError } from "../api/ApiUtils";
import {
    CustomerAddress,
    CustomerAddressService
} from "./CustomerAddressService";

export enum CustomerAddressFormActionType {
    LINE_ONE_UPDATE = "LINE_ONE_UPDATE",
    LINE_TWO_UPDATE = "LINE_TWO_UPDATE",
    LINE_THREE_UPDATE = "LINE_THREE_UPDATE",
    LINE_FOUR_UPDATE = "LINE_FOUR_UPDATE",
    LINE_FIVE_UPDATE = "LINE_FIVE_UPDATE",
    POSTCODE_UPDATE = "POSTCODE_UPDATE",
    MODAL_LOADED = "MODAL_LOADED",
    MODAL_CLOSED = "MODAL_CLOSED",
}

export type CustomerAddressFormAction =
    | {
    type: CustomerAddressFormActionType.LINE_ONE_UPDATE;
    value: string;
}
    | {
    type: CustomerAddressFormActionType.LINE_TWO_UPDATE;
    value: string;
}
    | {
    type: CustomerAddressFormActionType.LINE_THREE_UPDATE;
    value: string;
}
    | {
    type: CustomerAddressFormActionType.LINE_FOUR_UPDATE;
    value: string;
}
    | {
    type: CustomerAddressFormActionType.LINE_FIVE_UPDATE;
    value: string;
}
    | {
    type: CustomerAddressFormActionType.POSTCODE_UPDATE;
    value: string;
}
    | {
    type: CustomerAddressFormActionType.MODAL_LOADED;
    value: CustomerAddress;
}
    | {
    type: CustomerAddressFormActionType.MODAL_CLOSED;
};

export interface CustomerAddressFormState {
    id?: string;
    lineOne?: string;
    lineTwo?: string;
    lineThree?: string;
    lineFour?: string;
    lineFive?: string;
    postCode?: string;
}

export const CustomerAddressFormService = () => {
    const { createAddress, updateAddress } = CustomerAddressService();
    const createCustomerAddress = async (
        customerId: string,
        customerAddress: CustomerAddress
    ) => {
        try {
            await createAddress(customerId, customerAddress);
            toast.success("Contact created successfully");
        } catch (e) {
            logApiError("Error creating Contact.", e);
        }
    };
    const updateCustomerAddress = async (
        customerId: string,
        customerAddress: CustomerAddress
    ) => {
        try {
            await updateAddress(customerId, customerAddress);
            toast.success("Contact updated successfully");
        } catch (e) {
            logApiError("Error updating Contact.", e);
        }
    };
    const reducer = (
        state: CustomerAddressFormState,
        action: CustomerAddressFormAction
    ): CustomerAddressFormState => {
        switch (action.type) {
            case CustomerAddressFormActionType.MODAL_LOADED:
                return {
                    ...state,
                    id: action.value.id,
                    lineOne: action.value.lineOne,
                    lineTwo: action.value.lineTwo,
                    lineThree: action.value.lineThree,
                    lineFour: action.value.lineFour,
                    lineFive: action.value.lineFive,
                    postCode: action.value.postCode
                };
            case CustomerAddressFormActionType.LINE_ONE_UPDATE:
                return {
                    ...state,
                    lineOne: action.value
                };
            case CustomerAddressFormActionType.LINE_TWO_UPDATE:
                return {
                    ...state,
                    lineTwo: action.value
                };
            case CustomerAddressFormActionType.LINE_THREE_UPDATE:
                return {
                    ...state,
                    lineThree: action.value
                };
            case CustomerAddressFormActionType.LINE_FOUR_UPDATE:
                return {
                    ...state,
                    lineFour: action.value
                };
            case CustomerAddressFormActionType.LINE_FIVE_UPDATE:
                return {
                    ...state,
                    lineFive: action.value
                };
            case CustomerAddressFormActionType.POSTCODE_UPDATE:
                return {
                    ...state,
                    postCode: action.value
                };
            case CustomerAddressFormActionType.MODAL_CLOSED:
                return {
                    id: "",
                    lineOne: "",
                    lineTwo: "",
                    lineThree: "",
                    lineFour: "",
                    lineFive: "",
                    postCode: ""
                };
            default:
                return state;
        }
    };

    return {
        createCustomerAddress,
        updateCustomerAddress,
        reducer,
        initialState: {
            id: "",
            lineOne: "",
            lineTwo: "",
            lineThree: "",
            lineFour: "",
            lineFive: "",
            postCode: ""
        } as CustomerAddressFormState
    };
};
