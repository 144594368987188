import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ADD_CATEGORY, CATEGORY} from "../../../PlatformStack";
import {AllCategoriesTableService, CategoryRow} from "../../../../../services/category/AllCategoriesTableService";
import RefreshableSearchablePageableTable
    from "../../../../../components/table/refreshableSearchablePageableTable/RefreshableSearchablePageableTable";

interface Props {

}

const AllCategoriesTable = ({}: Props) => {

    const navigate = useNavigate();

    const { getCategoriesForTable, filter, columns } = AllCategoriesTableService();

    const [loading, setLoading] = useState<boolean>(false);

    const [categories, setCategories] = useState<CategoryRow[]>([]);
    const [cursor, setCursor] = useState<object>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [data, setData] = useState<CategoryRow[]>([]);

    useEffect(() => {
        setData(categories);
    }, [categories]);

    const onRowClick = (row: Object) => {
        let category = row as CategoryRow;

        navigate(CATEGORY.navigate([category.id]));
    }

    const toAddNewCategory = () => {
        navigate(ADD_CATEGORY.uri)
    }

    const loadTable = async (size: number) => {
        setCategories([]);
        let categoryTablePage = await getCategoriesForTable(
            setLoading,
            size);
        setCategories(categoryTablePage.rows);
        setTotalCount(categoryTablePage.totalCount);
        setPageCount(categoryTablePage.pageCount);
    }


    return (
        <RefreshableSearchablePageableTable
            totalCount={totalCount}
            pageCount={pageCount}
            data={data}
            columns={columns}
            loading={loading}
            loadTable={loadTable}
            filterFunction={(searchValue) => setData(filter(categories, searchValue))}
            onRowClick={onRowClick}
            navigateToAddNewForm={toAddNewCategory}
        />
    );
}

export default AllCategoriesTable;