import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RefreshableSearchablePageableTable from "../../../../../components/table/refreshableSearchablePageableTable/RefreshableSearchablePageableTable";
import { InvoiceTableRow } from "../../../../../services/invoice/InvoiceService";
import {
  InvoiceRow,
  InvoiceTableService,
} from "../../../../../services/invoice/InvoiceTableService";
import { ADD_INVOICE, INVOICES, VIEW_INVOICE } from "../../../PlatformStack";
import SendInvoiceToContactModal from "../../modals/SendInvoiceToContactModal";

interface Props {}

const AllInvoicesTable = ({}: Props) => {
  const navigate = useNavigate();

  const { getInvoicesForTable, columns, filter } = InvoiceTableService();

  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceTableRow[]>([]);
  const [data, setData] = useState<InvoiceTableRow[]>([]);
  const [cursor, setCursor] = useState<object>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>();
  const [invoiceId, setInvoiceId] = useState<string>();

  useEffect(() => {
    setData(invoices);
  }, [invoices]);

  const onRowClick = (row: Object) => {
    let invoice = row as InvoiceRow;
    navigate(VIEW_INVOICE.navigate([invoice.id]));
  };
  const showContactForm = (custId: string, invoiceId: string) => {
    setShowContactModal(true);

    setCustomerId(custId);
    setInvoiceId(invoiceId);
  };

  const loadTable = async (size: number) => {
    setInvoices([]);
    let invoiceTable = await getInvoicesForTable(
      setLoading,
      size,
      cursor,
      showContactForm
    );
    setInvoices(invoiceTable.rows);
    setTotalCount(invoiceTable.totalCount);
    setPageCount(invoiceTable.pageCount);
    setCursor(invoiceTable.cursor);
  };

  const search = (val: string) => {
    setData(filter(invoices, val));
  };

  return (
    <>
      <RefreshableSearchablePageableTable
        totalCount={totalCount}
        pageCount={pageCount}
        data={data}
        columns={columns}
        loading={loading}
        loadTable={loadTable}
        onRowClick={onRowClick}
        filterFunction={(searchValue) => search(searchValue)}
        navigateToAddNewForm={() => navigate(ADD_INVOICE.uri)}
      />
      <SendInvoiceToContactModal
        show={showContactModal}
        close={() => setShowContactModal(false)}
        customerId={customerId}
        invoiceId={invoiceId}
      />
    </>
  );
};
export default AllInvoicesTable;
