import ApiClient from "../api/ApiClient";
import {PriceBand} from "./PriceBandService";

export interface Price {
    unitPrice: number
    totalAmount: number
    currency: string
    displayAmount: string
}

export interface PriceRequest {
    variantId: string
    quantity: number

    customerId?: string
    length?: number
    lengthMeasurement?: string
    fullLength?: boolean
}

export const PriceCalculatorService = () => {

    const get = (async (request: PriceRequest): Promise<Price> => {
        return ApiClient.getInstance().post<Price>(`/api/1/price-calculator`, request);
    });

    return {
        getPrice: get
    }
}