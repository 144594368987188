import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VIEW_CUSTOMER_PAYMENT } from "../../../PlatformStack";
import { logApiError } from "../../../../../services/api/ApiUtils";
import { Button, Form, Row } from "react-bootstrap";
import {
  PaymentFormActionType,
  PaymentFormService,
} from "../../../../../services/payments/PaymentFormService";
import * as yup from "yup";
import { Formik } from "formik";
import AllInvoicesTable from "../../../invoices/parts/alltable/AllInvoicesTable";
import { InvoiceTableRow } from "../../../../../services/invoice/InvoiceService";
import { Includes } from "../../../../../services/payments/PaymentService";
import CustomerInvoiceTable from "../../../customers/parts/invoicetable/CustomerInvoiceTable";

interface Props {
  customerId: string;
  customerName: string;
  customerBalance?: number;
}
const InvoicePaymentForm = ({
  customerId,
  customerName,
  customerBalance,
}: Props) => {
  const { savePayment, reducer, initialState } = PaymentFormService();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState<number>(0);
  const [state, dispatch] = useReducer(reducer, initialState);

  let selectedInvoiceRows: InvoiceTableRow[] = [];

  const updateParentState = (selectedInvoiceRow: InvoiceTableRow) => {
    if (selectedInvoiceRows.length === 0) {
      selectedInvoiceRows.push(selectedInvoiceRow);
    } else {
      const selectedIndex = selectedInvoiceRows.findIndex((invoice) => {
        const trimmedSelectedInvoiceId = selectedInvoiceRow.invoiceId.trim();
        const trimmedInvoiceId = invoice.invoiceId.trim();
        return trimmedInvoiceId === trimmedSelectedInvoiceId;
      });
      if (selectedIndex !== -1) {
        selectedInvoiceRows.splice(selectedIndex, 1);
      } else {
        selectedInvoiceRows.push(selectedInvoiceRow);
      }
    }
    updatePaymentAmount();
    updateIncludes();
    console.log("selectedInvoiceRows", selectedInvoiceRows);
  };
  const updateIncludes = () => {
    //get all selectedInvoiceRows using those item create Includes[] and update state
    const includes: Includes[] = selectedInvoiceRows.map((invoice) => {
      return {
        type: "INVOICE",
        typeId: invoice.invoiceId,
      };
    });
    dispatch({
      type: PaymentFormActionType.ROW_SELECTED,
      value: includes,
    });
  };
  const updatePaymentAmount = () => {
    let paymentAmount = calculatePaymentAmount(selectedInvoiceRows);
    setTotalPaymentAmount(paymentAmount);
    dispatch({
      type: PaymentFormActionType.AMOUNT_UPDATE,
      value: paymentAmount,
    });
  };
  const calculatePaymentAmount = (selectedInvoiceRows: InvoiceTableRow[]) => {
    console.log("selectedInvoiceRows", selectedInvoiceRows);
    let totalPaymentAmount = 0;
    selectedInvoiceRows.forEach((invoice) => {
      let localnumber = Number.parseFloat(invoice.totalPrice.substring(1));
      totalPaymentAmount += localnumber;
    });

    return totalPaymentAmount;
  };

  const schema = yup.object().shape({
    amount: yup.number().required("Price is required"),
  });

  useEffect(() => {
    if (customerId) {
      dispatch({
        type: PaymentFormActionType.ONE_OFF_SELECTED,
        value: customerId,
      });
    }
  }, [customerId]);

  const save = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await savePayment(
        state.customerId,
        state.paymentSource,
        state.note,
        state.includes,
        state.amount * 100 //convert to pence before sending to backend
      );

      navigate(VIEW_CUSTOMER_PAYMENT.navigate([customerId]));
    } catch (e) {
      logApiError("Error saving Payment.", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Formik
          onSubmit={() => {}}
          validationSchema={schema}
          enableReinitialize={true}
          initialValues={{
            amount: state.amount,
            Notes: state.note,
          }}
        >
          {({
            handleChange,
            handleBlur,
            touched,
            errors,
            setFieldValue, // To set the radio button value
          }) => (
            <Form className="materialForm_card_form" noValidate>
              <h4 className="mt-5">{customerName} Invoices</h4>
              <CustomerInvoiceTable
                customerId={customerId}
                updateInvoiceRow={updateParentState}
              />
              <Form.Group
                as={Row}
                controlId="paymentType"
                className="mt-3 mb-4"
              >
                <Form.Label>
                  <h6>Payment Is Being Made By</h6>
                </Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Cash"
                    name="paymentType"
                    id="cash"
                    value="CASH"
                    checked={state.paymentSource === "CASH"}
                    onChange={(e) => {
                      dispatch({
                        type: PaymentFormActionType.PAYMENT_METHOD_UPDATE,
                        value: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Cheque"
                    name="paymentType"
                    id="cheque"
                    value="CHEQUE"
                    checked={state.paymentSource === "CHEQUE"}
                    onChange={(e) => {
                      dispatch({
                        type: PaymentFormActionType.PAYMENT_METHOD_UPDATE,
                        value: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Bank Transfer"
                    name="paymentType"
                    id="bank-transfer"
                    value="BANK_TRANSFER"
                    checked={state.paymentSource === "BANK_TRANSFER"}
                    onChange={(e) => {
                      dispatch({
                        type: PaymentFormActionType.PAYMENT_METHOD_UPDATE,
                        value: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Card"
                    name="paymentType"
                    id="card"
                    value="CARD"
                    checked={state.paymentSource === "CARD"}
                    onChange={(e) => {
                      dispatch({
                        type: PaymentFormActionType.PAYMENT_METHOD_UPDATE,
                        value: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="materialForm.name"
              >
                <Form.Label>
                  <h6>Notes</h6>
                </Form.Label>
                <Form.Control
                  className="form-input"
                  as="textarea"
                  rows={3}
                  name="Notes"
                  placeholder="Notes"
                  value={state.note}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    dispatch({
                      type: PaymentFormActionType.NOTE_UPDATE,
                      value: e.target.value,
                    });
                    handleChange(e);
                  }}
                />
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <p style={{ fontSize: "1.25em", marginBottom: "0.5em", width: "auto" }}>
          Current Balance: £{customerBalance}
        </p>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <p style={{ fontSize: "1.25em", marginBottom: "0.5em", width: "auto" }}>
          This Payment: £{totalPaymentAmount}
        </p>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <p style={{ fontSize: "1.25em", marginBottom: "0.5em", width: "auto" }}>
          New Balance:{" "}
          {customerBalance ? `£${customerBalance - totalPaymentAmount}` : ""}
        </p>
      </Row>
      <hr />
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant={"outline-danger"}
          style={{ marginRight: "0.5em", width: "auto" }}
          onClick={() => navigate(VIEW_CUSTOMER_PAYMENT.navigate([customerId]))}
        >
          Cancel
        </Button>
        <Button style={{ width: "auto" }} onClick={save}>
          {"Create"}
        </Button>
      </Row>
    </>
  );
};

export default InvoicePaymentForm;
