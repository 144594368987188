import ApiClient from "../api/ApiClient";
import { Data } from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import { PagedResource } from "../api/common/Types";
import { OrderLine } from "../order/OrderService";
import { CustomerAddress } from "../customer/CustomerAddressService";

export interface InvoiceTable {
  rows: InvoiceTableRow[];
}

export interface InvoiceTableRow extends Data {
  orderId: string;
  invoiceId: string;
  customerId: string;
  ref: string;
  customerName: string;
  numberOfItems: number;
  totalPrice: string;
  invoiceDate: string;
}

export interface InvoiceDetail {
  customerName: string;
  shippingAddress: CustomerAddress;
  billingAddress: CustomerAddress;
  refNo: string;
  lines: OrderLine[];


  total: string;
  totalWithoutTax: string;
  totalTax: string;
}

export interface InvoiceTablePage extends PagedResource {
  resource: InvoiceTable;
}

export const InvoiceService = () => {
  const get = async (invoiceId: string): Promise<InvoiceDetail> => {
    return ApiClient.getInstance().get<InvoiceDetail>(
      `/api/1/invoices/${invoiceId}`
    );
  };

  const getTable = async (
    size: number,
    cursor?: object,
    customerId?: string
  ): Promise<InvoiceTablePage> => {
    return ApiClient.getInstance().post<InvoiceTablePage>(
      `/api/1/invoice-table`,
      {
        size,
        cursor,
        customerId,
      }
    );
  };

  return {
    getInvoice: get,
    getInvoiceTable: getTable,
  };
};
