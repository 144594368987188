import React, {ReactElement} from 'react';
import {Data} from '../../components/table/refreshableSearchableTable/RefreshableSearchableTable';
import {logApiError} from '../api/ApiUtils';
import {Customer, CustomerService, PagedCustomer} from "./CustomerService";
import {Pencil} from 'react-bootstrap-icons';

export interface CustomerRow extends Data {
    id: string;
    code: string;
    name: string;
    actions?: ReactElement;
}

export interface CustomerTablePage {
    totalCount: number;
    pageCount: number;
    page: number;
    cursor: object;
    rows: CustomerRow[];
}

export const CustomersTableService = () => {
    const {getCustomers, searchCustomers} = CustomerService();

    const getCustomersForTable = async (
        setLoading: (value: boolean) => void,
        size: number,
        editCustomerDetail: (customerId: string) => void,
        cursor?: object
    ): Promise<CustomerTablePage> => {
        setLoading(true);
        try {
            const pagedCustomer: PagedCustomer = await getCustomers(size, cursor);
            return {
                totalCount: pagedCustomer.totalCount,
                pageCount: pagedCustomer.pageCount,
                page: pagedCustomer.page,
                cursor: pagedCustomer.cursor,
                rows: customersToRows(editCustomerDetail, pagedCustomer.resource),
            };
        } catch (e) {
            logApiError('Error loading customers.', e);
        } finally {
            setLoading(false);
        }
        return new Promise(() => []);
    };

    const customersToRows = (
        editCustomerDetail: (customerId: string) => void,
        customers: Customer[]
    ): CustomerRow[] => {
        return customers.map((customer) => {
            return {
                id: customer.id,
                code: customer.code,
                name: customer.name,
                actions: (
                    <>
                        <Pencil
                            style={{marginRight: '0.4em'}}
                            onClick={(e) => {
                                e.stopPropagation()
                                editCustomerDetail(customer.id)
                            }}
                        />
                    </>
                ),
            };
        });
    };

    const filter = async (
        customers: CustomerRow[],
        searchValue: string
    ): Promise<CustomerRow[]> => {
        if (!searchValue) {
            return customers;
        }

        let res = await searchCustomers(searchValue);
        return res.resource;
    };

    return {
        getCustomersForTable,
        filter,
        customersToRows,
        columns: [
            {
                title: 'Code',
                key: 'code',
            },
            {
                title: 'Name',
                key: 'name',
            },
            {
                title: '',
                key: 'actions',
                style: {
                    width: '10%',
                    // no idea - https://github.com/typestyle/typestyle/issues/281
                    textAlign: 'end' as 'end',
                },
            },
        ],
    };
};
