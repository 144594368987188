import ApiClient from "../api/ApiClient";
import {PriceRule} from "../pricing/PriceRuleService";
import {PagedResource} from "../api/common/Types";

export interface VariantBase {
    id?: string
    title: string
    code: string
    description?: string
}

export interface Variant extends VariantBase {
    id: string
    productId: string
    pricing?: PriceRule[]
    attributes?: VariantAttribute[]
}

export interface PagedVariants extends PagedResource {
    resource: Variant[]
}

export interface VariantAttributeBase {
    id?: string
    variantId?: string
    measurement: string
    type: string
    value: string
}

export interface VariantAttribute {
    id: string
    variantId: string
    measurement: string
    type: string
    value: string
}

export const ProductVariantService = () => {

    const getAll = (async (): Promise<PagedVariants> => {
        return ApiClient.getInstance().get<PagedVariants>(`/api/1/variants`)
    })

    const getAllForProduct = (async (productId: string): Promise<PagedVariants> => {
        return ApiClient.getInstance().post<PagedVariants>(`/api/1/variants-search`, {
            size: 100,
            productId: productId,
        })
    })

    const search = (async (search: string): Promise<PagedVariants> => {
        return ApiClient.getInstance().post<PagedVariants>(`/api/1/variants-search`, {
            search
        });
    });

    const create = (async (productId: string, attributes: VariantAttributeBase[]): Promise<Variant> => {
        return ApiClient.getInstance().post<Variant>(`/api/1/variants`, {
            productId: productId,
            attributes
        });
    });

    const update = (async (productId: string, variantId: string, attributes: VariantAttributeBase[]): Promise<Variant> => {
        return ApiClient.getInstance().put<Variant>(`/api/1/variants/${variantId}`, {
            productId: productId,
            attributes
        });
    });

    const deleteVariant = (async (variantId: string): Promise<void> => {
        return ApiClient.getInstance().delete<void>(`/api/1/variants/${variantId}`);
    });

    const createAttribute = (async (variantId: string,
                                    attribute: VariantAttributeBase): Promise<VariantAttribute> => {
        return ApiClient.getInstance().put<VariantAttribute>(`/api/1/variants/${variantId}/attributes`, attribute);
    });

    const updateAttribute = (async (variantId: string,
                                    attributeId: string,
                                    attribute: VariantAttributeBase): Promise<VariantAttribute> => {
        return ApiClient.getInstance()
            .put<VariantAttribute>(`/api/1/variants/${variantId}/attributes/${attributeId}`, attribute);
    });

    const deleteAttribute = (async (variantId: string,
                                    attributeId: string): Promise<void> => {
        return ApiClient.getInstance().delete<void>(`/api/1/variants/${variantId}/attributes/${attributeId}`);
    });

    return {
        getVariants: getAll,
        getVariantsForProduct: getAllForProduct,
        searchVariants: search,
        createVariant: create,
        updateVariant: update,
        deleteVariant,
        createAttribute,
        updateAttribute,
        deleteAttribute,
    }
}
