import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VIEW_CUSTOMER_PAYMENT } from "../../../PlatformStack";
import { logApiError } from "../../../../../services/api/ApiUtils";
import { Alert, Button, Form, Row } from "react-bootstrap";
import {
  PaymentFormActionType,
  PaymentFormService,
} from "../../../../../services/payments/PaymentFormService";
import * as yup from "yup";
import { Formik } from "formik";

interface Props {
  customerId: string;
  customerBalance: number;
}

const OneOffPaymentForm = ({ customerId, customerBalance }: Props) => {
  const { savePayment, reducer, initialState } = PaymentFormService();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [payment, setPayment] = useState<number>(0);
  const [isChecked, setIsChecked] = useState(false);

  const schema = yup.object().shape({
    amount: yup.number().required("Price is required"),
  });

  useEffect(() => {
    if (customerId) {
      dispatch({
        type: PaymentFormActionType.ONE_OFF_SELECTED,
        value: customerId,
      });
    }
  }, [customerId]);

  const save = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await savePayment(
        state.customerId,
        state.paymentSource,
        state.note,
        state.includes,
        state.amount * 100 // convert to pence before sending to backend
      );
      navigate(VIEW_CUSTOMER_PAYMENT.navigate([customerId]));
    } catch (e) {
      logApiError("Error saving material.", e);
    } finally {
      setLoading(false);
    }
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setPayment(customerBalance);
      dispatch({
        type: PaymentFormActionType.AMOUNT_UPDATE,
        value: customerBalance,
      });
    } else {
      setPayment(0);
      dispatch({
        type: PaymentFormActionType.AMOUNT_UPDATE,
        value: 0,
      });
    }
  };

  return (
    <>
      <Row>
        <Formik
          onSubmit={() => {}}
          validationSchema={schema}
          enableReinitialize={true}
          initialValues={{
            amount: state.amount,
            Notes: state.note,
          }}
        >
          {({ handleChange, handleBlur, touched, errors }) => (
            <Form className="materialForm_card_form" noValidate>
              <Form.Group
                as={Row}
                className="mb-3 mt-8"
                controlId="materialForm.price"
              >
                <Form.Label>
                  <h6 className="mt-4">Amount</h6>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleCheckboxChange}
                      checked={isChecked}
                    />
                    Pay current balance of £{customerBalance}
                  </div>
                </Form.Label>
                <Form.Control
                  required={true}
                  className="form-input"
                  placeholder="Price"
                  name="price"
                  type="number"
                  value={state.amount}
                  onBlur={handleBlur}
                  disabled={isChecked}
                  onChange={(e) => {
                    dispatch({
                      type: PaymentFormActionType.AMOUNT_UPDATE,
                      value: Number.parseFloat(e.target.value),
                    });
                    setPayment(Number.parseFloat(e.target.value));
                    handleChange(e);
                  }}
                />
                {touched.amount && errors.amount && (
                  <Alert variant="danger">{errors.amount}</Alert>
                )}
              </Form.Group>

              <Form.Group as={Row} controlId="paymentType" className="mt-4">
                <Form.Label>
                  <h6>Payment is being made by</h6>
                </Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Cash"
                    name="paymentType"
                    id="cash"
                    value="CASH"
                    checked={state.paymentSource === "CASH"}
                    onChange={(e) => {
                      dispatch({
                        type: PaymentFormActionType.PAYMENT_METHOD_UPDATE,
                        value: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Cheque"
                    name="paymentType"
                    id="cheque"
                    value="CHEQUE"
                    checked={state.paymentSource === "CHEQUE"}
                    onChange={(e) => {
                      dispatch({
                        type: PaymentFormActionType.PAYMENT_METHOD_UPDATE,
                        value: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Bank Transfer"
                    name="paymentType"
                    id="bank-transfer"
                    value="BANK_TRANSFER"
                    checked={state.paymentSource === "BANK_TRANSFER"}
                    onChange={(e) => {
                      dispatch({
                        type: PaymentFormActionType.PAYMENT_METHOD_UPDATE,
                        value: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label="Card"
                    name="paymentType"
                    id="card"
                    value="CARD"
                    checked={state.paymentSource === "CARD"}
                    onChange={(e) => {
                      dispatch({
                        type: PaymentFormActionType.PAYMENT_METHOD_UPDATE,
                        value: e.target.value,
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3 mt-4"
                controlId="materialForm.name"
              >
                <Form.Label>
                  <h6>Notes</h6>
                </Form.Label>
                <Form.Control
                  className="form-input"
                  as="textarea"
                  rows={3}
                  name="Notes"
                  placeholder="Notes"
                  value={state.note}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    dispatch({
                      type: PaymentFormActionType.NOTE_UPDATE,
                      value: e.target.value,
                    });
                    handleChange(e);
                  }}
                />
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <p style={{ fontSize: "1.25em", marginBottom: "0.5em", width: "auto" }}>
          Current Balance: £{customerBalance}
        </p>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <p style={{ fontSize: "1.25em", marginBottom: "0.5em", width: "auto" }}>
          This Payment: £{payment}
        </p>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <p style={{ fontSize: "1.25em", marginBottom: "0.5em", width: "auto" }}>
          New Balance: £{customerBalance - payment}
        </p>
      </Row>
      <hr />
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant={"outline-danger"}
          style={{ marginRight: "0.5em", width: "auto" }}
          onClick={() => navigate(VIEW_CUSTOMER_PAYMENT.navigate([customerId]))}
        >
          Cancel
        </Button>
        <Button style={{ width: "auto" }} onClick={save}>
          {"Create"}
        </Button>
      </Row>
    </>
  );
};

export default OneOffPaymentForm;
