import {
  StatementService,
  StatementTable,
  StatementTablePage,
  StatementTableRow,
} from "../statements/StatementService";
import { logApiError } from "../api/ApiUtils";

export const CustomerStatementTableService = () => {
  const { getStatementTable } = StatementService();

  const getStatementsForTable = async (
    setLoading: (x: boolean) => void,
    size: number,
    customerId: string,
    cursor?: object
  ): Promise<StatementTablePage> => {
    setLoading(true);
    try {
      return await getStatementTable(size, cursor, customerId);
    } catch (e) {
      logApiError("Error loading statements.", e);
    } finally {
      setLoading(false);
    }
    return new Promise(() => []);
  };

  const filter = (
    statements: StatementTableRow[],
    searchValue: string
  ): StatementTableRow[] => {
    if (!searchValue) {
      return statements;
    }
    return statements.filter(
      (statement) =>
        statement.id.toLowerCase().includes(searchValue.toLowerCase()) ||
        statement.customerName
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        statement.status.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  return {
    getStatementsForTable,
    filter,
    columns: [
      {
        title: "Ref",
        key: "ref",
        style: {
          width: "20%",
        },
      },
      {
        title: "Customer Name",
        key: "customerName",
      },
      {
        title: "Statement Type",
        key: "statementType",
      },

      {
        title: "To Date",
        key: "toDate",
      },
      {
        title: "From Date",
        key: "fromDate",
      },
      {
        title: "Amount Due",
        key: "amountDue",
      },
      {
        title: "",
        key: "actions",
        style: {
          width: "10%",
          textAlign: "end" as "end",
        },
      },
    ],
    statementPaymentColumns: [
      {
        title: "Select",
        key: "actions",
        style: {
          width: "10%",
        },
      },
      {
        title: "Ref",
        key: "ref",
        style: {
          width: "20%",
        },
      },
      {
        title: "Customer Name",
        key: "customerName",
      },
      {
        title: "Statement Type",
        key: "statementType",
      },

      {
        title: "To Date",
        key: "toDate",
      },
      {
        title: "From Date",
        key: "fromDate",
      },
      {
        title: "Amount Due",
        key: "amountDue",
      },
    ],
    lineItemsColumns: [
      {
        title: "Line Item",
        key: "ref",
        style: {
          width: "50%",
        },
      },
      {
        title: "Date",
        key: "createdDate",
      },
      {
        title: "Price",
        key: "price",
      },
      {
        title: "Balance",
        key: "balance",
      },
    ],
    lineItemsResources: [
      {
        title: "Product",
        key: "title",
        style: {
          width: "50%",
        },
      },
      {
        title: "Length",
        key: "length",
      },
      {
        title: "Number of Items",
        key: "quantity",
      },
      {
        title: "Item Price",
        key: "unitPrice",
      },

      {
        title: "Total Price",
        key: "totalPrice",
      },
    ],
  };
};
