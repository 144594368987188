import React from "react";
import { Table } from "react-bootstrap";
import {
    CutMeasurement,
    MiscOrderItem,
    OrderFormAction,
    OrderItem
} from "../../../../services/order/OrderFormService";
import ProductSearch from "./ProductSearch";
import OrderItemLine from "./OrderItemLine";
import MiscItemsOrderForm from "./MiscItemsOrderForm";

interface Props {
    orderItems: OrderItem[];
    miscOrderItems?: MiscOrderItem[];
    dispatch: (x: OrderFormAction) => void;
    getDefaultPrice: (variantId: string) => void;
    getPrice: (
        variantId: string,
        quantity: number,
        isFullLength: boolean,
        cutLength?: number,
        lengthMeasurement?: CutMeasurement
    ) => void;
}

const Lines = ({
                   orderItems,
                   miscOrderItems,
                   dispatch,
                   getDefaultPrice,
                   getPrice
               }: Props) => {
    const calculateTotalPrice = (
        orderItems: OrderItem[],
        miscOrderItems?: MiscOrderItem[]
    ): number => {
        // Calculate total price from orderItems
        const orderItemsTotalPrice = orderItems
            .flatMap((item) => item.lines)
            .reduce((sum, line) => sum + (line?.totalPrice || 0), 0);

        // Calculate total price from miscOrderItems
        const miscItemsTotalPrice =
            miscOrderItems?.reduce((sum, item) => sum + item.price, 0) ?? 0;

        // Calculate grand total
        const grandTotalPrice = orderItemsTotalPrice / 100 + miscItemsTotalPrice;

        return grandTotalPrice;
    };

    return (
        <>
            <h3 style={{ marginTop: "2em" }}>Order</h3>
            <ProductSearch
                dispatch={dispatch}
                onItemSelected={(vId) => getDefaultPrice(vId)}
            />

            <h6>Products</h6>
            {orderItems.map((orderItem) => {
                return (
                    <OrderItemLine
                        dispatch={dispatch}
                        key={orderItem.variantId}
                        orderItem={orderItem}
                        onNewCutAdded={getPrice}
                    />
                );
            })}
            <br />
            <hr />
            <h6>Miscellenous Items</h6>
            {miscOrderItems?.map((miscItem) => {
                return <MiscItemsOrderForm miscItem={miscItem} dispatch={dispatch} />;
            })}

            <Table style={{ marginTop: "2em" }}>
                <thead>
                <td>
                    <span style={{ float: "right" }}>Total Price</span>
                </td>
                </thead>
                <tbody>
                <tr>
                    <td>
              <span style={{ float: "right" }}>
                £{calculateTotalPrice(orderItems, miscOrderItems)}
              </span>
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    );
};

export default Lines;
