import {Data} from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import {logApiError} from "../api/ApiUtils";
import {Material, MaterialService, PagedMaterial} from "./MaterialService";

export interface MaterialRow extends Data {
    id: string
    name: string
    price: string
}

export interface MaterialTablePage {
    totalCount: number
    pageCount: number
    page: number
    rows: MaterialRow[]
}

export const MaterialsTableService = () => {

    const { getMaterials } = MaterialService();

    const getMaterialsForTable: (setLoading: (value: boolean) => void)
        => Promise<MaterialTablePage> = (async (setLoading: (value: boolean) => void) => {
        setLoading(true);
        try {
            const pagedMaterial: PagedMaterial = await getMaterials();
            return {
                totalCount: pagedMaterial.totalCount,
                pageCount: pagedMaterial.pageCount,
                page: pagedMaterial.page,
                rows: pagedMaterial.resource.map((material) => {
                    return {
                        id: material.id,
                        name: material.name,
                        price: material.displayPrice ? '' + material.displayPrice : 'No price',
                    }
                })
            }
        } catch (e) {
            logApiError('Error loading materials.', e);
        } finally {
            setLoading(false);
        }
        return new Promise(() => []);
    });

    const materialsToRows: (materials: Material[]) => MaterialRow[] = (materials: Material[]) => {
        return materials.map((material) => {
            return {
                id: material.id,
                name: material.name,
                price: material.displayPrice,
            }
        })
    }

    const filter = ((materials: MaterialRow[], searchValue: string): MaterialRow[] => {
        if (!searchValue) {
            return materials;
        }
        return materials.filter((material) =>
            material.name.toLowerCase().includes(searchValue.toLowerCase()));
    });

    return {
        getMaterialsForTable,
        filter,
        materialsToRows,
        columns: [
            {
                title: 'Name',
                key: 'name'
            },
            {
                title: 'Price',
                key: 'price'
            },
        ]
    }
}
