import React, {useState} from 'react';
import {Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import {Customer, CustomerService} from "../../../../services/customer/CustomerService";
import {logApiError} from "../../../../services/api/ApiUtils";
import {OrderFormAction, OrderFormActionType} from "../../../../services/order/OrderFormService";

interface Props {
    dispatch: (x: OrderFormAction) => void
}

const CustomerSearch = ({ dispatch }: Props) => {

    const { searchCustomers } = CustomerService();

    const [loading, setLoading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<Customer[]>([]);

    const search = async (value: string) => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            let pagedCustomer = await searchCustomers(value);
            setSearchResults(pagedCustomer.resource);
        } catch (e) {
            logApiError('Error when searching customers', e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card>
            <Card.Body className="productForm_card_sectionContainer">Select Customer</Card.Body>
            <Card.Body className="productForm_card_sectionContainer"
                       style={{ paddingLeft: '5em', paddingRight: '5em' }}>
                <Form.Group as={Row} className="mb-3" controlId="orderForm.search">
                    <Form.Label>
                        Search
                    </Form.Label>
                    <Col xs={11}>
                        <Form.Control
                            required
                            className="form-input"
                            type="text"
                            name="title"
                            placeholder="Search..."
                            onChange={(e) => search(e.target.value)}
                        />
                    </Col>
                    <Col xs={1}>
                        {loading && <Spinner animation='border' />}
                    </Col>
                </Form.Group>

                <Table>
                    <tbody>
                    {searchResults && searchResults.map((customer) => {
                        return <tr style={{ cursor: 'pointer' }}
                                   onClick={() => dispatch({
                                       value: customer,
                                       type: OrderFormActionType.CUSTOMER_SELECTED
                                   })}>
                            <td style={{ width: '35%' }}>{customer.code}</td>
                            <td>{customer.name}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>

            </Card.Body>
        </Card>
    )
}

export default CustomerSearch;