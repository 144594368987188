import React from 'react';

import './AccountOption.scss';

interface Props {
    name: string
    role: string
}

const AccountOption = ({name, role}: Props) => {

    return (
        <div className="accountOption_container">
            <h3 className="accountOption_header">{name}</h3>
            <h6 className="accountOption_subheading">{role}</h6>
        </div>
    )
}

export default AccountOption;