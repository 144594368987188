import axios, {AxiosRequestHeaders, AxiosResponse} from 'axios';
import {LocalStorageService} from "../state/LocalStorageService";

declare module 'axios' {
    interface AxiosResponse<T = any> extends Promise<T> {}
}

let handleError = (error: any) => {};

export type ApiError = {
    errorCode: string
    message: string
}

export class ApiClient {

    constructor(errorCallback: (error: any) => void, baseURL?: string) {
        axios.defaults.baseURL = baseURL ? baseURL : 'http://localhost:8080';
        handleError = errorCallback;
    }

    public static getInstance() {
        const { getConfig } = LocalStorageService();
        const localStorageConfig = getConfig();
        const token = localStorageConfig?.token ? localStorageConfig.token : '';
        const accountId = localStorageConfig?.accountId ? localStorageConfig.accountId : '';

        const api = axios.create({
            withCredentials: true,
            headers: accountId ? {
                Authorization: token,
                'X-ACCOUNT-ID': accountId
            } : {
                Authorization: token
            }
        });
        api.interceptors.response.use(
            this._handleResponse,
            handleError
        )
        return api;
    }

    private static _handleResponse = ({ data }: AxiosResponse) => data;

}

export default ApiClient;