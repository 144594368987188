import {Page} from "../../components/NavigationStack";

export const PLATFORM_ROOT = "/platform";

export const LOADING: Page = {
    uri: PLATFORM_ROOT + "/loading",
    navigate: () => LOADING.uri,
};

// export const DASHBOARD: Page = {
//     uri: PLATFORM_ROOT + '/',
//     navigate: () => DASHBOARD.uri
// }

export const CATEGORIES: Page = {
    uri: PLATFORM_ROOT + '/categories',
    navigate: () => CATEGORIES.uri
}

export const CATEGORY: Page = {
    uri: PLATFORM_ROOT + '/category/:id',
    navigate: (urlParams: string[]) => CATEGORY.uri.replace(':id', urlParams[0])
}

export const ADD_CATEGORY: Page = {
    uri: PLATFORM_ROOT + '/category/new',
    navigate: () => ADD_CATEGORY.uri
}

export const EDIT_CATEGORY: Page = {
    uri: PLATFORM_ROOT + '/category/:id/edit',
    navigate: (urlParams: string[]) => EDIT_CATEGORY.uri.replace(':id', urlParams[0])
}

export const PRODUCTS: Page = {
    uri: PLATFORM_ROOT + '/products',
    navigate: () => PRODUCTS.uri
}

export const PRODUCT: Page = {
    uri: PLATFORM_ROOT + '/products/:id',
    navigate: (urlParams: string[]) => PRODUCT.uri.replace(':id', urlParams[0])
}

export const EDIT_PRODUCT: Page = {
    uri: PLATFORM_ROOT + '/products/:id/edit',
    navigate: (urlParams: string[]) => EDIT_PRODUCT.uri.replace(':id', urlParams[0])
}

export const ADD_PRODUCT: Page = {
    uri: PLATFORM_ROOT + '/products/new',
    navigate: () => ADD_PRODUCT.uri
}

export const PRICING: Page = {
    uri: PLATFORM_ROOT + '/pricing',
    navigate: () => PRICING.uri
}

export const CUSTOMERS: Page = {
    uri: PLATFORM_ROOT + '/customers',
    navigate: () => CUSTOMERS.uri
}

export const ADD_CUSTOMER: Page = {
    uri: PLATFORM_ROOT + '/customers/new',
    navigate: () => ADD_CUSTOMER.uri
}

export const EDIT_CUSTOMER: Page = {
    uri: PLATFORM_ROOT + '/customers/:id/edit',
    navigate: (urlParams: string[]) => EDIT_CUSTOMER.uri.replace(':id', urlParams[0])
}
export const VIEW_CUSTOMER: Page = {
    uri: PLATFORM_ROOT + '/customers/:id/view',
    navigate: (urlParams: string[]) => VIEW_CUSTOMER.uri.replace(':id', urlParams[0])
}
export const VIEW_CUSTOMER_STATEMENT: Page = {
    uri: PLATFORM_ROOT + '/customers/:id/view/statement',
    navigate: (urlParams: string[]) => VIEW_CUSTOMER_STATEMENT.uri.replace(':id', urlParams[0])
}
export const VIEW_CUSTOMER_PAYMENT: Page = {
    uri: PLATFORM_ROOT + '/customers/:id/view/payment',
    navigate: (urlParams: string[]) => VIEW_CUSTOMER_PAYMENT.uri.replace(':id', urlParams[0])
}
export const PURCHASES: Page = {
    uri: PLATFORM_ROOT + '/purchases',
    navigate: () => PURCHASES.uri
}

export const QUOTES: Page = {
    uri: PLATFORM_ROOT + '/quotes',
    navigate: () => QUOTES.uri
}

export const ADD_QUOTE: Page = {
    uri: PLATFORM_ROOT + '/quotes/new',
    navigate: () => ADD_QUOTE.uri
}

export const MATERIALS: Page = {
    uri: PLATFORM_ROOT + '/materials',
    navigate: () => MATERIALS.uri
}

export const NEW_MATERIAL: Page = {
    uri: PLATFORM_ROOT + '/materials/new',
    navigate: () => NEW_MATERIAL.uri
}
export const NEW_PAYMENT: Page = {
    uri: PLATFORM_ROOT + '/payments/new/:customerId/:customerName',
    navigate: (urlParams: string[]) =>
        NEW_PAYMENT.uri
            .replace(":customerId", urlParams[0])
            .replace(":customerName", urlParams[1]),
}

export const EDIT_MATERIAL: Page = {
    uri: PLATFORM_ROOT + "/materials/:id/edit",
    navigate: (urlParams: string[]) =>
        EDIT_MATERIAL.uri.replace(":id", urlParams[0]),
};

export const INVOICES: Page = {
    uri: PLATFORM_ROOT + "/invoices",
    navigate: () => INVOICES.uri,
};

export const ADD_INVOICE: Page = {
    uri: PLATFORM_ROOT + "/invoices/new",
    navigate: () => ADD_INVOICE.uri,
};

export const STATEMENTS: Page = {
    uri: PLATFORM_ROOT + "/statements",
    navigate: () => STATEMENTS.uri,
};
export const VIEW_INVOICE: Page = {
    uri: PLATFORM_ROOT + "/invoice/:id",
    navigate: (urlParams: string[]) =>
        VIEW_INVOICE.uri.replace(":id", urlParams[0]),
};
export const VIEW_QUOTE: Page = {
    uri: PLATFORM_ROOT + "/quote/:id",
    navigate: (urlParams: string[]) =>
        VIEW_QUOTE.uri.replace(":id", urlParams[0]),
};
export const VIEW_STATEMENT: Page = {
    uri: PLATFORM_ROOT + "/statement/:id",
    navigate: (urlParams: string[]) => {
        const idReplacement = urlParams[0] || "";
        return VIEW_STATEMENT.uri
            .replace(":id", idReplacement)
    },
};

export const VIEW_STATEMENT_CUSTOMER: Page = {
    uri: PLATFORM_ROOT + "/statement/:id/:customerId/:customerName",
    navigate: (urlParams: string[]) => {
        const idReplacement = urlParams[0] || "";
        const customerNameReplacement = urlParams[1] || "";
        const customerIdReplacement = urlParams[2] || "";
        console.log(customerIdReplacement)
        return VIEW_STATEMENT_CUSTOMER.uri
            .replace(":id", idReplacement)
            .replace(":customerName", customerNameReplacement)
            .replace(":customerId", customerIdReplacement);
    },
};
