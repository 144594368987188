import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {BrowserRouter} from 'react-router-dom';
import {GlobalStateProvider} from "./services/state/GlobalStateProvider";
import {App} from "./stacks/App";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <GlobalStateProvider>
            <App />
        </GlobalStateProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
