import {Dropdown, DropdownButton, Form, InputGroup} from "react-bootstrap";
import React from "react";

interface Props {
    label: string
    value?: string
    valueUpdate: (x: string) => void
    measurementValue?: string
    measurementUpdate: (x: string | undefined) => void
}

const VariantAttributeForm = ({label, value, valueUpdate, measurementValue, measurementUpdate}: Props) => {
    return <>
        <Form.Label>
            {label}
        </Form.Label>
        <InputGroup className="mb-3">
            <Form.Control
                required
                className="form-input"
                type="number"
                name={label}
                placeholder={label}
                value={value}
                onChange={(e) => valueUpdate(e.target.value)}
            />
            <DropdownButton
                variant="outline-secondary"
                title={measurementValue ? measurementValue.toLowerCase().replaceAll('_', ' ') : ""}
                id={`${label}-measurement`}
                align="end"
            >
                <Dropdown.Item onClick={() => measurementUpdate(undefined)}>None</Dropdown.Item>
                <Dropdown.Item onClick={() => measurementUpdate('MILLIMETRE')}>Millimetre</Dropdown.Item>
                <Dropdown.Item onClick={() => measurementUpdate('CENTIMETRE')}>Centimetre</Dropdown.Item>
                <Dropdown.Item onClick={() => measurementUpdate('METRE')}>Metre</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item onClick={() => measurementUpdate('FOOT')}>Foot</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item onClick={() => measurementUpdate('INCH')}>Inch</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item onClick={() => measurementUpdate('KILOGRAM_METRE')}>Kilogram per Metre</Dropdown.Item>
            </DropdownButton>
        </InputGroup>
    </>;

}

export default VariantAttributeForm;
