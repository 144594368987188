import React, {useEffect, useState} from 'react';

import RefreshableSearchableTable, {
    Props as ParentTableProps
} from "../refreshableSearchableTable/RefreshableSearchableTable";
import {Button, Col, Row} from "react-bootstrap";

import './RefreshableSearchablePageableTable.scss';

interface UserPaging {
    currentPage: number,
    size: number
}

interface Props extends ParentTableProps {
    totalCount: number
    pageCount: number
    loadTable: (size: number) => void
}

const RefreshableSearchablePageableTable = ({
                                                data,
                                                columns,
                                                loading,
                                                filterFunction,
                                                onRowClick,
                                                totalCount,
                                                pageCount,
                                                navigateToAddNewForm,
                                                loadTable
                                            }: Props) => {

    const [userPaging, setUserPaging] = useState<UserPaging>({
        currentPage: 0,
        size: 25
    });
    const [currentPageText, setCurrentPageText] = useState<string>(String(userPaging.currentPage + 1));
    const [currentPageInvalid, setCurrentPageInvalid] = useState<boolean>(false);

    useEffect(() => {
        reloadTable();
    }, []);

    useEffect(() => {
        setCurrentPageText(String(userPaging.currentPage + 1));
        reloadTable();
    }, [userPaging]);

    const updateSize = async (newSize: number) => {
        setUserPaging({
            size: newSize,
            currentPage: 0
        })
    }

    const updatePage = async (newPage: number) => {
        setUserPaging({
            size: userPaging.size,
            currentPage: newPage
        })
    }

    const reloadTable = () => {
        loadTable(userPaging.size);
    }

    return (
        <>
            <RefreshableSearchableTable
                data={data}
                columns={columns}
                loading={loading}
                filterFunction={filterFunction}
                refresh={reloadTable}
                onRowClick={onRowClick}
                navigateToAddNewForm={navigateToAddNewForm}
            />
            <Row className={"refreshableSearchablePageableTable_footerContainer"}>
                <Col>
                    <h3 className="refreshableSearchablePageableTable_footer_text">{totalCount} Items</h3>
                </Col>
                <Col className={"refreshableSearchablePageableTable_pageNumberContainer"}>
                    {/*<Button*/}
                    {/*    onClick={() => updatePage(userPaging.currentPage - 1)}*/}
                    {/*    disabled={userPaging.currentPage == 0}*/}
                    {/*    className="refreshableSearchablePageableTable_footer_btn"*/}
                    {/*>Previous</Button>*/}
                    {/*<input*/}
                    {/*    onChange={(e) => {*/}
                    {/*        setCurrentPageText(e.target.value);*/}
                    {/*        if (!isNaN(Number(e.target.value))) {*/}
                    {/*            const newPage = (+e.target.value) - 1;*/}
                    {/*            if (newPage < 0 || newPage >= pageCount || newPage == userPaging.currentPage) {*/}
                    {/*                setCurrentPageInvalid(true);*/}
                    {/*                return;*/}
                    {/*            }*/}
                    {/*            updatePage((+e.target.value) - 1);*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    value={currentPageText}*/}
                    {/*    aria-invalid={currentPageInvalid}*/}
                    {/*    className="refreshableSearchablePageableTable_footer_pageInput"*/}
                    {/*/>*/}
                    {/*<h4 className="refreshableSearchablePageableTable_footer_text">of {pageCount}</h4>*/}
                    {/*<Button*/}
                    {/*    onClick={() => updatePage(userPaging.currentPage + 1)}*/}
                    {/*    disabled={userPaging.currentPage + 1 == pageCount || pageCount == 0}*/}
                    {/*    className="refreshableSearchablePageableTable_footer_btn"*/}
                    {/*>Next</Button>*/}
                </Col>
                <Col className={"refreshableSearchablePageableTable_sizeContainer"}>
                    <Button
                        onClick={() => updatePage(userPaging.currentPage + 1)}
                        disabled={userPaging.currentPage + 1 == pageCount || pageCount == 0}
                        className="refreshableSearchablePageableTable_footer_btn"
                    >Next</Button>
                    <h4 className="refreshableSearchablePageableTable_footer_text">Show</h4>
                    <select
                        defaultValue={25}
                        onChange={(e) => updateSize(+e.target.value)}
                        className="refreshableSearchablePageableTable_footer_showDropDown"
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </Col>
            </Row>
        </>
    )
}

export default RefreshableSearchablePageableTable;