import {Page} from "../../components/NavigationStack";

export const LOGIN: Page = {
    uri: '/login',
    navigate: () => LOGIN.uri
};
export const REGISTER: Page = {
    uri: '/register',
    navigate: () => REGISTER.uri
};
export const CONFIRM_EMAIL: Page = {
    uri: '/confirm-email',
    navigate: () => CONFIRM_EMAIL.uri
};
export const ACCOUNT_LIST: Page = {
    uri: '/accounts',
    navigate: () => ACCOUNT_LIST.uri
};
export const CREATE_ACCOUNT: Page = {
    uri: '/create-account',
    navigate: () => CREATE_ACCOUNT.uri
};