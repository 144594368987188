import React, {ReactElement} from 'react';
import {Container} from "react-bootstrap";

import './CenteredCardPage.scss';

interface Props {
    children: ReactElement
}

export const CenteredCardPage = ({children}: Props) => {

    return (
        <Container className="centeredCardPage_container" fluid>
            <div className="centeredCardPage_card">
                {children}
            </div>
        </Container>
    );
};