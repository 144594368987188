import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, ModalBody, ModalFooter, Spinner} from "react-bootstrap";
import {Variant} from "../../../../../services/product/ProductVariantService";
import ModalHeader from "react-bootstrap/ModalHeader";
import VariantAttributeForm from "../form/VariantAttributeForm";
import {Attribute, VariantModalService} from "../../../../../services/product/VariantModalService";

interface Props {
    productId: string
    variant: Variant | undefined
    show: boolean
    close: (shouldReload?: boolean) => void
}

const VariantModal = ({productId, variant, show, close}: Props) => {

    const {initVariant, submit, attributes} = VariantModalService();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        initVariant(setLoading, productId, variant)
    }, [variant]);

    return (
        <Modal show={show} onHide={close}>
            <ModalHeader>
                {variant ? variant.title : 'New Variant'}
            </ModalHeader>
            {!loading && <ModalBody style={{paddingLeft: '5em', paddingRight: '5em'}}>
                    <h3>Attributes</h3>
                    <Form className="productForm_card_form" noValidate>
                        {attributes.map((attribute: Attribute) => {
                            return <VariantAttributeForm
                                label={attribute.name}
                                value={attribute.value}
                                valueUpdate={attribute.updateValue}
                                measurementValue={attribute.measurement}
                                measurementUpdate={attribute.updateMeasurement}
                            />
                        })}
                    </Form>
                </ModalBody>
            }
            <ModalFooter>
                {loading && <Spinner animation='border'/>}
                {!loading &&
                <>
                    <Button variant="outline-danger" onClick={() => close()}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setLoading(true);
                        submit()
                            .then((success) => {
                                close(success);
                            })
                            .finally(() => {
                                setLoading(false);
                            })
                    }}>
                        {variant ? 'Update' : 'Save'}
                    </Button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}

export default VariantModal;