import {Variant} from "../product/ProductVariantService";
import {Product} from "../product/ProductService";
import ApiClient from "../api/ApiClient";

export interface OrderItemSearchResult {
    variant: Variant
    product: Product
}

export const OrderItemService = () => {

    const search = (async (searchTerm: string): Promise<OrderItemSearchResult[]> => {
        return ApiClient.getInstance().post<OrderItemSearchResult[]>(`/api/1/order-items-search`, {
            searchTerm
        })
    });


    return {
        searchOrderItems: search
    }

}