import React, {useState} from 'react';
import {Formik} from "formik";
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import * as yup from "yup";
import {AccountService} from "../../../../services/account/AccountService";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {PRODUCTS} from "../../../platform/PlatformStack";
import {logApiError} from "../../../../services/api/ApiUtils";

interface Props {

}

const CreateAccountForm = ({}: Props) => {

    const { createAccount } = AccountService();
    const navigate  = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
    });

    const submitForm = async (values: any) => {
        if (loading) {
            return;
        }

        const createNewAccount = async (name: string) => {
            createAccount(name)
                .then(() => {
                    toast.success('New account created.');
                    navigate(PRODUCTS.uri);
                })
                .catch((err) => logApiError('Error creating account.', err))
                .finally(() => setLoading(false))
        }

        setLoading(true);
        createNewAccount(values.name);
    }


    return <>
        <h1 className="cardHeading">Create account</h1>

        <Formik
            validationSchema={schema}
            onSubmit={submitForm}
            initialValues={{
                name: "",
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
              }) => (
                <Form
                    style={{
                        marginTop: "10px",
                        padding: "2rem",
                    }}
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <Form.Group as={Row} className="mb-3" controlId="createAccountForm.name">
                        <Form.Label column xs="4">
                            Name:
                        </Form.Label>
                        <Col xs="8">
                            <Form.Control
                                required
                                className="form-input"
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={values.name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Col>
                        {touched.name && errors.name && (
                            <Alert variant="danger">
                                {errors.name}
                            </Alert>
                        )}
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={loading}>
                        Create
                    </Button>
                    {loading && <Spinner animation="border" />}
                </Form>
            )}
        </Formik>
    </>
}

export default CreateAccountForm;