import React from 'react';
import {CenteredCardPage} from "../../../components/layout/centeredCardPage/CenteredCardPage";
import {LoginForm} from "../parts/login/LoginForm";

export const Login = () => {


    return (
        <CenteredCardPage>
            <LoginForm />
        </CenteredCardPage>
    );
};