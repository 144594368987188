import React, {useEffect, useReducer, useState} from "react";
import {Button, Form, Modal, Row} from "react-bootstrap";
import {Formik} from "formik";
import {
    DeletePaymentState,
    PaymentFormActionType,
    PaymentFormService,
} from "../../../../../services/payments/PaymentFormService";
import {logApiError} from "../../../../../services/api/ApiUtils";
import {toast} from "react-toastify";

interface Props {
    show: boolean;
    deletePayData: DeletePaymentState;
    close: () => void;
    customerId?: string;
    loadTable: () => void;
}

const ConfirmDeleteModal = ({
                                show,
                                deletePayData,
                                close,
                                loadTable,
                            }: Props) => {
    const {deletePayment, deleteFormReducer} = PaymentFormService();
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [confirmEnabled, setConfirmEnabled] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const [state, dispatch] = useReducer(deleteFormReducer, {
        paymentId: deletePayData.paymentId,
        customerId: deletePayData.paymentId,
        reason: "",
    } as DeletePaymentState);

    useEffect(() => {
        dispatch({
            type: PaymentFormActionType.MODAL_LOADED,
            value: deletePayData,
        });
    }, [deletePayData]);

    const handleCheckboxChange = () => {
        setCheckboxChecked(!checkboxChecked);
    };

    const handleReasonChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = e.target.value;
        setNote(value);
        setConfirmEnabled(checkboxChecked && value.trim() !== ""); // Enable Confirm button if checkbox is checked and reason is not empty
    };

    const handleConfirmDelete = async () => {
        if (confirmEnabled) {
            if (loading) {
                return;
            }
            setLoading(true);
            try {
                if (!state.paymentId) {
                    toast.error("Error deleting payment - don't know the payment.");
                    return;
                }

                await deletePayment(state.paymentId, state.reason);
                close();
                toast.success("Payment deleted successfully");
            } catch (e) {
                logApiError("Error deleting payment.", e);
            } finally {
                setLoading(false);
            }
        }
        setConfirmEnabled(false);
        setCheckboxChecked(false);
        setNote("");
        loadTable();
    };

    return (
        <Modal show={show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={() => {
                    }}
                    enableReinitialize={true}
                    initialValues={{
                        customerId: state.customerId,
                        paymentId: state.paymentId,
                    }}
                >
                    {({handleChange, handleBlur, errors}) => (
                        <Form>
                            <Form.Check
                                type="checkbox"
                                label="I understand that I am deleting an existing payment on the customer account"
                                checked={checkboxChecked}
                                onChange={handleCheckboxChange}
                                style={{marginBottom: "10px"}} // Add margin to the checkbox
                            />
                            {checkboxChecked && (
                                <Form.Group as={Row} controlId="reason">
                                    <Form.Control
                                        required={true}
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter reason for deletion"
                                        value={note}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            dispatch({
                                                type: PaymentFormActionType.REASON_UPDATE,
                                                value: e.target.value,
                                            });
                                            handleReasonChange(e);
                                            handleChange(e);
                                        }}
                                    />
                                </Form.Group>
                            )}
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Cancel
                </Button>
                <Button
                    variant="danger"
                    onClick={handleConfirmDelete}
                    disabled={!checkboxChecked || !confirmEnabled}
                >
                    Confirm Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeleteModal;
