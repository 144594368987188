import ApiClient from "../api/ApiClient";
import {PagedResource} from "../api/common/Types";

export interface TaxRuleBase {
    id?: string
    taxId: string
    parentType: string
    parentId: string
}

export interface TaxRule extends TaxRuleBase {
    id: string
}

export interface PagedTaxRule extends PagedResource {
    resource: TaxRule[]
}

export const TaxRuleService = () => {

    const searchForProduct = (async (productId: string): Promise<PagedTaxRule> => {
        return ApiClient.getInstance().post<PagedTaxRule>(`/api/1/tax-rules-search`, {
            productId
        });
    });

    const createVatForProduct = (async (productId: string): Promise<TaxRule> => {
        return ApiClient.getInstance().post<TaxRule>(`/api/1/tax-rules`, {
            parentId: productId
        });
    });

    const deleteR = (async (ruleId: string): Promise<void> => {
        return ApiClient.getInstance().delete<void>(`/api/1/tax-rules/${ruleId}`)
    })

    return {
        searchTaxRuleForProduct: searchForProduct,
        createVatForProduct,
        deleteRule: deleteR
    }
}
