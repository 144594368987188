import ApiClient from "../api/ApiClient";
import { PagedResource } from "../api/common/Types";

export interface CustomerContact {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export interface PagedCustomerContacts extends PagedResource {
  resource: CustomerContact[];
}

export const CustomerContactService = () => {
  const getAll = async (customerId: string): Promise<PagedCustomerContacts> => {
    return ApiClient.getInstance().post<PagedCustomerContacts>(
      `/api/1/customers/${customerId}/contacts-search`
    );
  };

  const get = async (
    customerId: string,
    id: string
  ): Promise<CustomerContact> => {
    return ApiClient.getInstance().get<CustomerContact>(
      `/api/1/customers/${customerId}/contacts/${id}`
    );
  };

  const create = async (
    customerId: string,
    contact: CustomerContact
  ): Promise<CustomerContact> => {
    return ApiClient.getInstance().post<CustomerContact>(
      `/api/1/customers/${customerId}/contacts`,
      {
        name: contact.name,
        phoneNumber: contact.phoneNumber,
        email: contact.email,
      }
    );
  };

  const update = async (
    customerId: string,
    contact: CustomerContact
  ): Promise<CustomerContact> => {
    return ApiClient.getInstance().put<CustomerContact>(
      `/api/1/customers/${customerId}/contacts/${contact.id}`,
      {
        name: contact.name,
        phoneNumber: contact.phoneNumber,
        email: contact.email,
      }
    );
  };
  const updateDefaultContact = async (
    customerId: string,
    contactId: string
  ): Promise<CustomerContact> => {
    return ApiClient.getInstance().put<CustomerContact>(
      `/api/1/customers/update-default-contact`,
      {
        customerId: customerId,
        contactId: contactId,
      }
    );
  };

  const deleteContact = async (
    customerId: string,
    id: string
  ): Promise<void> => {
    return ApiClient.getInstance().delete<void>(
      `/api/1/customers/${customerId}/contacts/${id}`
    );
  };

  return {
    getContacts: getAll,
    getContact: get,
    createContact: create,
    updateContact: update,
    deleteContact: deleteContact,
    updateDefaultContact: updateDefaultContact,
  };
};
