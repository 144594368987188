import { Customer, CustomerService } from "./CustomerService";
import {
  PriceBandAssignment,
  PriceBandAssignmentService,
} from "../pricing/PriceBandAssignmentService";
import { PriceBand } from "../pricing/PriceBandService";
import { extractCapitalLetters } from "../util/Utils";
import {
  CustomerContact,
  CustomerContactService,
} from "./CustomerContactService";
import {
  CustomerAddress,
  CustomerAddressService,
} from "./CustomerAddressService";
import { toast } from "react-toastify";

export enum CustomerFormActionType {
  NAME_UPDATE = "NAME_UPDATE",
  CREDIT_LIMIT_UPDATE = "CREDIT_LIMIT_UPDATE",
  CODE_UPDATE = "CODE_UPDATE",
  CODE_OVERRIDE_CHECK = "CODE_OVERRIDE_CHECK",
  CUSTOMER_LOADED = "CUSTOMER_LOADED",
  CUSTOMER_ADDRESSES_LOADED = "CUSTOMER_ADDRESSES_LOADED",
  CUSTOMER_CONTACTS_LOADED = "CUSTOMER_CONTACTS_LOADED",
  BANDS_LOADED = "BANDS_LOADED",
  ASSIGNMENTS_LOADED = "ASSIGNMENTS_LOADED",
  ASSIGN_BAND = "ASSIGN_BAND",
  NEW_ADDRESS = "NEW_ADDRESS",
  REMOVE_ADDRESS = "REMOVE_ADDRESS",
  ADDRESS_UPDATE = "ADDRESS_UPDATE",
  NEW_CONTACT = "NEW_CONTACT",
  REMOVE_CONTACT = "REMOVE_CONTACT",
  CONTACT_UPDATE = "CONTACT_UPDATE",
}

export type CustomerFormAction =
  | { value: Customer; type: CustomerFormActionType.CUSTOMER_LOADED }
  | {
      value: CustomerAddress[];
      type: CustomerFormActionType.CUSTOMER_ADDRESSES_LOADED;
    }
  | {
      value: CustomerContact[];
      type: CustomerFormActionType.CUSTOMER_CONTACTS_LOADED;
    }
  | { value: PriceBand[]; type: CustomerFormActionType.BANDS_LOADED }
  | {
      value: PriceBandAssignment;
      type: CustomerFormActionType.ASSIGNMENTS_LOADED;
    }
  | { value: string; type: CustomerFormActionType.ASSIGN_BAND }
  | { value: string; type: CustomerFormActionType.NAME_UPDATE }
  | { value: number; type: CustomerFormActionType.CREDIT_LIMIT_UPDATE }
  | { value: string; type: CustomerFormActionType.CODE_UPDATE }
  | { value: boolean; type: CustomerFormActionType.CODE_OVERRIDE_CHECK }
  | { type: CustomerFormActionType.NEW_ADDRESS }
  | { value: string; type: CustomerFormActionType.REMOVE_ADDRESS }
  | {
      value: string;
      id: string;
      field: 1 | 2 | 3 | 4 | 5 | 6;
      type: CustomerFormActionType.ADDRESS_UPDATE;
    }
  | { type: CustomerFormActionType.NEW_CONTACT }
  | { value: string; type: CustomerFormActionType.REMOVE_CONTACT }
  | {
      value: string;
      id: string;
      field: "name" | "email" | "phone";
      type: CustomerFormActionType.CONTACT_UPDATE;
    };

export interface CustomerFormInfoState {
  id?: string;
  name?: string;
  code?: string;
  creditLimit?: number;
  creditLimitDisplayString?: string;
  overrideCode: boolean;
}

export interface CustomerAddressFormState {
  id: string;
  lineOne?: string;
  lineTwo?: string;
  lineThree?: string;
  lineFour?: string;
  lineFive?: string;
  postCode?: string;
}

export interface CustomerContactFormState {
  id: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export interface CustomerFormPricingState {
  priceBands?: PriceBand[];
  assignedPriceBand?: string;
}

export interface CustomerFormState {
  info: CustomerFormInfoState;
  pricing: CustomerFormPricingState;
  addresses: CustomerAddressFormState[];
  contacts: CustomerContactFormState[];
  removedAddresses: string[];
  removedContacts: string[];
}

export const MissingCodeError = new Error("The customer code must be provided to save the customer.");

export const CustomerFormService = () => {
  const { createCustomer, updateCustomer } = CustomerService();
  const { createContact, updateContact, deleteContact } =
    CustomerContactService();
  const { createAddress, updateAddress, deleteAddress } =
    CustomerAddressService();
  const { createPriceBandAssignment } = PriceBandAssignmentService();

  const loadAddresses = (value: CustomerAddress[]) => {
    let addresses: CustomerAddressFormState[] = [];
    value.forEach((a: CustomerAddress, index: number) => {
      addresses.push({
        id: a.id ? a.id : "" + index,
        lineOne: a.lineOne,
        lineTwo: a.lineTwo,
        lineThree: a.lineThree,
        lineFour: a.lineFour,
        lineFive: a.lineFive,
        postCode: a.postCode,
      });
    });
    if (addresses.length === 0) {
      addresses.push({
        id: "0",
      });
    }
    return addresses;
  };

  const loadContacts = (value: CustomerContact[]) => {
    let contacts: CustomerContactFormState[] = [];
    value.forEach((c: CustomerContact, index: number) => {
      contacts.push({
        id: c.id ? c.id : "" + index,
        name: c.name,
        email: c.email,
        phoneNumber: c.phoneNumber,
      });
    });
    if (contacts.length === 0) {
      contacts.push({
        id: "0",
      });
    }
    return contacts;
  };

  const reducer = (
    state: CustomerFormState,
    action: CustomerFormAction
  ): CustomerFormState => {
    switch (action.type) {
      case CustomerFormActionType.NAME_UPDATE:
        return {
          ...state,
          info: {
            ...state.info,
            name: action.value,
            code: state.info.overrideCode
              ? state.info.code
              : extractCapitalLetters(action.value),
          },
        };
      case CustomerFormActionType.CREDIT_LIMIT_UPDATE:
        return {
          ...state,
          info: {
            ...state.info,
            creditLimit: action.value,
          },
        };
      case CustomerFormActionType.CODE_UPDATE:
        return {
          ...state,
          info: {
            ...state.info,
            code: action.value,
          },
        };
      case CustomerFormActionType.CODE_OVERRIDE_CHECK:
        return {
          ...state,
          info: {
            ...state.info,
            code: action.value
              ? state.info.code
              : extractCapitalLetters(state.info.name),
            overrideCode: action.value,
          },
        };
      case CustomerFormActionType.CUSTOMER_LOADED:
        return {
          ...state,
          info: {
            id: action.value.id,
            name: action.value.name,
            code: action.value.code,
            creditLimit: action.value.creditLimit ? action.value.creditLimit / 100 : undefined,
            overrideCode:
              extractCapitalLetters(action.value.name) !== action.value.code,
          },
        };
      case CustomerFormActionType.CUSTOMER_ADDRESSES_LOADED:
        return {
          ...state,
          addresses: loadAddresses(action.value),
        };
      case CustomerFormActionType.CUSTOMER_CONTACTS_LOADED:
        return {
          ...state,
          contacts: loadContacts(action.value),
        };
      case CustomerFormActionType.BANDS_LOADED:
        return {
          ...state,
          pricing: {
            priceBands: action.value,
          },
        };
      case CustomerFormActionType.ASSIGNMENTS_LOADED:
        return {
          ...state,
          pricing: {
            priceBands: state.pricing.priceBands,
            assignedPriceBand: action.value.band.id,
          },
        };
      case CustomerFormActionType.ASSIGN_BAND:
        return {
          ...state,
          pricing: {
            priceBands: state.pricing.priceBands,
            assignedPriceBand: action.value,
          },
        };
      case CustomerFormActionType.NEW_ADDRESS:
        return {
          ...state,
          addresses: [
            ...state.addresses,
            {
              id:
                "" +
                (Math.max(
                  ...state.addresses
                    .map((a) => a.id)
                    .map((id) => {
                      let n = Number(id);
                      return isNaN(n) ? 0 : n;
                    })
                ) +
                  1),
            },
          ],
        };
      case CustomerFormActionType.REMOVE_ADDRESS:
        return {
          ...state,
          addresses: state.addresses.filter((a) => a.id != action.value),
          removedAddresses: [...state.removedAddresses, action.value],
        };
      case CustomerFormActionType.ADDRESS_UPDATE:
        return {
          ...state,
          addresses: state.addresses.map((a) => {
            if (a.id != action.id) {
              return a;
            }

            switch (action.field) {
              case 1:
                a.lineOne = action.value;
                return a;
              case 2:
                a.lineTwo = action.value;
                return a;
              case 3:
                a.lineThree = action.value;
                return a;
              case 4:
                a.lineFour = action.value;
                return a;
              case 5:
                a.lineFive = action.value;
                return a;
              case 6:
                a.postCode = action.value;
                return a;
            }
          }),
        };
      case CustomerFormActionType.NEW_CONTACT:
        return {
          ...state,
          contacts: [
            ...state.contacts,
            {
              id:
                "" +
                (Math.max(
                  ...state.contacts
                    .map((a) => a.id)
                    .map((id) => {
                      let n = Number(id);
                      return isNaN(n) ? 0 : n;
                    })
                ) +
                  1),
            },
          ],
        };
      case CustomerFormActionType.REMOVE_CONTACT:
        return {
          ...state,
          contacts: state.contacts.filter((a) => a.id != action.value),
          removedContacts: [...state.removedContacts, action.value],
        };
      case CustomerFormActionType.CONTACT_UPDATE:
        return {
          ...state,
          contacts: state.contacts.map((a) => {
            if (a.id != action.id) {
              return a;
            }

            switch (action.field) {
              case "name":
                a.name = action.value;
                return a;
              case "email":
                a.email = action.value;
                return a;
              case "phone":
                a.phoneNumber = action.value;
                return a;
            }
          }),
        };
    }
  };

  function addressesBody(state: CustomerFormState) {
    let addresses: CustomerAddress[] = state.addresses.flatMap((a) => {
      if (!a.lineOne) {
        return [];
      }

      return [
        {
          id: Number.isNaN(Number(a.id)) ? a.id : undefined,
          lineOne: a.lineOne,
          lineTwo: a.lineTwo,
          lineThree: a.lineThree,
          lineFour: a.lineFour,
          lineFive: a.lineFive,
          postCode: a.postCode,
        },
      ];
    });
    return addresses;
  }

  function contactsBody(state: CustomerFormState) {
    let contacts: CustomerContact[] = state.contacts.flatMap((c) => {
      return [
        {
          id: Number.isNaN(Number(c.id)) ? c.id : undefined,
          name: c.name,
          email: c.email,
          phoneNumber: c.phoneNumber,
        },
      ];
    });
    return contacts;
  }

  const save = async (state: CustomerFormState): Promise<void> => {
    if (state.info.code === undefined || state.info.code === "") {
      throw MissingCodeError;
    }

    if (state.info.code && state.info.name) {
      let addresses = addressesBody(state);
      let contacts = contactsBody(state);

      const customer: Customer = state.info.id
        ? await updateCustomer(
            state.info.id,
            state.info.code,
            state.info.name,
            state.info.creditLimit
          )
        : await createCustomer(
            state.info.code,
            state.info.name,
            state.info.creditLimit
          );
      if (addresses) {
        addresses.forEach((addr) => {
          addr.id
            ? updateAddress(customer.id, addr)
            : createAddress(customer.id, addr);
        });
      }
      state.removedAddresses.forEach((addrId) => {
        if (!Number.isInteger(addrId)) {
          deleteAddress(customer.id, addrId);
        }
      });
      if (contacts) {
        contacts.forEach((cont) => {
          cont.id
            ? updateContact(customer.id, cont)
            : createContact(customer.id, cont);
        });
      }
      state.removedContacts.forEach((contId) => {
        if (!Number.isInteger(contId)) {
          deleteContact(customer.id, contId);
        }
      });
      if (state.pricing) {
        if (state.pricing.assignedPriceBand) {
          await createPriceBandAssignment(
            state.pricing.assignedPriceBand,
            "CUSTOMER",
            customer.id
          );
        }
      }
      return;
    }

    throw Error("Could not save customer.");
  };

  return {
    reducer,
    initialState: {
      info: {
        overrideCode: false,
      },
      pricing: {},
      addresses: [
        {
          id: "0",
        },
      ],
      contacts: [
        {
          id: "0",
        },
      ],
      removedAddresses: [],
      removedContacts: [],
    },
    save,
  };
};
