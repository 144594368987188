import ApiClient from "../api/ApiClient";

export interface PriceBandBase {
    id?: string
    name: string
    index: number
    isDefault: boolean
    costPriceModifier?: number
}

export interface PriceBand extends PriceBandBase {
    id: string
}

export interface AssignablePriceBand {
    band: PriceBand
    isAssigned: boolean
}

export interface PagedPriceBand {
    page: number
    size: number
    totalCount: number
    pageCount: number
    resource: PriceBand[]
}

export const PriceBandService = () => {

    const getAll: () => Promise<PagedPriceBand> =
        (async () => {
        return ApiClient.getInstance().get<PagedPriceBand>(`/api/1/price-bands`);
    });

    const getAllWithPaging: (page?: number, size?: number) => Promise<PagedPriceBand> =
        (async (page, size) => {
        return ApiClient.getInstance().get<PagedPriceBand>(`/api/1/price-bands?page=${page}&size=${size}`);
    });

    const create: (bands: PriceBandBase[]) => Promise<PriceBandBase[]> =
        (async (bands: PriceBandBase[]) => {
        return ApiClient.getInstance().post<PriceBandBase[]>(`/api/1/price-bands`, bands);
    });

    const update: (bands: PriceBandBase[]) => Promise<PriceBandBase[]> =
        (async (bands: PriceBandBase[]) => {
        return ApiClient.getInstance().put<PriceBandBase[]>(`/api/1/price-bands`, bands);
    });

    return {
        getPriceBands: getAll,
        getPriceBandsPaged: getAllWithPaging,
        createPriceBands: create,
        updatePriceBands: update
    }
}