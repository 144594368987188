import React from 'react';
import AppPage from "../../components/page/AppPage";
import CategoryForm from "../parts/forms/CategoryForm";
import {Breadcrumb, BreadcrumbItem, Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {CATEGORIES} from "../../PlatformStack";

interface Props {

}

const AddCategory = ({}: Props) => {

    const navigate = useNavigate();

    return (
        <AppPage>
            <Container>
                <h3 className="heading">Add Category</h3>

                <Breadcrumb>
                    <BreadcrumbItem onClick={() => navigate(CATEGORIES.uri)}>Categories</BreadcrumbItem>
                    <BreadcrumbItem active>Add</BreadcrumbItem>
                </Breadcrumb>

                <CategoryForm />
            </Container>
        </AppPage>
    )
}

export default AddCategory;