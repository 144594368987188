import { Formik } from "formik";
import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { toast } from "react-toastify";
import * as yup from "yup";
import { logApiError } from "../../../../services/api/ApiUtils";
import { CommunicationService } from "../../../../services/communication/CommunicationService";
import {
  CustomerContact,
  CustomerContactService,
} from "../../../../services/customer/CustomerContactService";

interface Props {
  show: boolean;
  quoteId?: string;
  customerId?: string;
  close: () => void;
}

const SendQuoteToContactModal = ({
  show,
  quoteId,
  customerId,
  close,
}: Props) => {
  const { getContacts } = CustomerContactService();
  const { createCommunication } = CommunicationService();

  const [loading, setLoading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [contacts, setContacts] = useState<CustomerContact[]>([]);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [selectedEmail, setSelectedEmail] = useState<string>("");

  const schema = yup.object().shape({
    name: yup.string(),
    email: yup.string(),
    phoneNumber: yup.string(),
  });

  useEffect(() => {
    loadContacts();
  }, [customerId]);

  const loadContacts = async () => {
    if (!customerId) {
      return;
    }

    setLoading(true);
    try {
      let resp = await getContacts(customerId);
      setContacts(resp.resource.filter((c) => c.email));
    } finally {
      setLoading(false);
      setSending(false);
    }
  };

  const sendEmail = async (email?: string, contactId?: string) => {
    if (!contactId || !quoteId || !email || sending) {
      return;
    }

    try {
      setSending(true);
      let resources = new Map<String, String>();
      resources.set("QUOTE", quoteId);
      await createCommunication({
        type: "EMAIL",
        communication: "NEW_QUOTE",
        contactId: contactId,
        resources: resources,
      });
      toast.success("Sent email to: " + email);
      close();
    } catch (e) {
      logApiError("Error sending email.", e);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <ModalHeader>Select Customer Contact</ModalHeader>
      <ModalBody style={{ paddingLeft: "5em", paddingRight: "5em" }}>
        <p>Select a customer to send this quote as an email to.</p>

        {loading && <Spinner animation="border" />}
        {!loading &&
          contacts &&
          contacts.map((c) => {
            return (
              <Card style={{ margin: "1em" }}>
                <div style={{ padding: "0.5em" }}>
                  <h6>{c.name}</h6>
                  <p>{c.email}</p>
                </div>

                <Button
                  disabled={sending}
                  onClick={() => {
                    sendEmail(c.email, c.id);
                    if (c.email) setSelectedEmail(c.email);
                  }}
                >
                  {sending && selectedEmail === c.email && (
                    <Spinner animation="border" />
                  )}
                  {(!sending || (sending && selectedEmail != c.email)) &&
                    "Select"}
                </Button>
              </Card>
            );
          })}
        {!loading && contacts && contacts.length === 0 && (
          <p>
            <i>No existing contacts available.</i>
          </p>
        )}

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Add New Contact</Accordion.Header>
            <Accordion.Body>
              <Formik
                onSubmit={() => {}}
                validationSchema={schema}
                initialValues={{
                  name: name,
                  email: email,
                  phoneNumber: phone,
                }}
              >
                <div>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="customerContactForm.name"
                  >
                    <Form.Control
                      className="form-input"
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="customerContactForm.email"
                  >
                    <Form.Control
                      className="form-input"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="customerContactForm.phone"
                  >
                    <Form.Control
                      className="form-input"
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Button>Add Contact and Select</Button>
                </div>
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </ModalBody>
    </Modal>
  );
};

export default SendQuoteToContactModal;
