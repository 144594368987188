import ApiClient from "../api/ApiClient";


export interface CommunicationResource {
    type: string
    communication: string
    contactId: string
    resources: Map<String, String>
}

export const CommunicationService = () => {

    const create = (async (resource: CommunicationResource): Promise<void> => {
        return ApiClient.getInstance().post<void>('/api/1/communication', {
            communication: resource.communication,
            contactId: resource.contactId,
            type: resource.type,
            resources: Object.fromEntries(resource.resources)
        })
    });

    return {
        createCommunication: create
    }

}