import React, { useEffect, useState } from "react";
import AppPage from "../../components/page/AppPage";
import { Breadcrumb, BreadcrumbItem, Spinner, Table } from "react-bootstrap";
import InvoiceOrderItemsTable from "../parts/itemstable/InvoiceOrderItemsTable";
import { useNavigate, useParams } from "react-router-dom";
import { logApiError } from "../../../../services/api/ApiUtils";
import { INVOICES } from "../../PlatformStack";
import {
    InvoiceDetail,
    InvoiceService
} from "../../../../services/invoice/InvoiceService";

interface Props {
}

const SpecificInvoice = ({}: Props) => {
    const { id } = useParams();

    const navigate = useNavigate();

    const { getInvoice } = InvoiceService();

    const [loading, setLoading] = useState<boolean>();
    const [invoice, setInvoice] = useState<InvoiceDetail>();

    useEffect(() => {
        if (loading || !id) {
            return;
        }

        const loadInvoice = async (invoiceId: string) => {
            setLoading(true);
            try {
                const resp = await getInvoice(invoiceId);
                setInvoice(resp);
            } catch (e) {
                logApiError("Error loading Invoice.", e);
            } finally {
                setLoading(false);
            }
        };

        loadInvoice(id);
    }, []);

    return (
        <AppPage>
            <>
                <h3 className={"heading"}>Invoices</h3>

                {loading && (
                    <>
                        <Spinner animation={"border"} />
                        <p>Loading...</p>
                    </>
                )}
                {invoice && !loading && (
                    <>
                        <Breadcrumb>
                            <BreadcrumbItem onClick={() => navigate(INVOICES.uri)}>
                                Invoices
                            </BreadcrumbItem>
                            <BreadcrumbItem active>{invoice.refNo}</BreadcrumbItem>
                        </Breadcrumb>
                        <h4 className={"customer"}>{invoice.customerName}</h4>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '3em' }}>
                                <ul className="customer-address">
                                    {invoice.shippingAddress && (
                                        <>
                                            <b>Shipping Address</b>
                                            {invoice.shippingAddress.lineOne && (
                                                <li>{invoice.shippingAddress.lineOne}</li>
                                            )}
                                            {invoice.shippingAddress.lineTwo && (
                                                <li>{invoice.shippingAddress.lineTwo}</li>
                                            )}
                                            {invoice.shippingAddress.lineThree && (
                                                <li>{invoice.shippingAddress.lineThree}</li>
                                            )}
                                            {invoice.shippingAddress.lineFour && (
                                                <li>{invoice.shippingAddress.lineFour}</li>
                                            )}
                                            {invoice.shippingAddress.lineFive && (
                                                <li>{invoice.shippingAddress.lineFive}</li>
                                            )}
                                            {invoice.shippingAddress.postCode && (
                                                <li>{invoice.shippingAddress.postCode}</li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>
                            <div>
                                <ul className="customer-address">
                                    {invoice.billingAddress && (
                                        <>
                                            <b>Billing Address</b>
                                            {invoice.billingAddress.lineOne && (
                                                <li>{invoice.billingAddress.lineOne}</li>
                                            )}
                                            {invoice.billingAddress.lineTwo && (
                                                <li>{invoice.billingAddress.lineTwo}</li>
                                            )}
                                            {invoice.billingAddress.lineThree && (
                                                <li>{invoice.billingAddress.lineThree}</li>
                                            )}
                                            {invoice.billingAddress.lineFour && (
                                                <li>{invoice.billingAddress.lineFour}</li>
                                            )}
                                            {invoice.billingAddress.lineFive && (
                                                <li>{invoice.billingAddress.lineFive}</li>
                                            )}
                                            {invoice.billingAddress.postCode && (
                                                <li>{invoice.billingAddress.postCode}</li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <InvoiceOrderItemsTable lines={invoice.lines} />

                        <br />
                        <div className="row justify-content-end">
                            {invoice && (
                                <div className="col-md-3 ml-auto">
                                    <p className="fw-bold">
                                        Total Without Tax: {invoice.totalWithoutTax}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="row justify-content-end">
                            {invoice && (
                                <div className="col-md-3 ml-auto">
                                    <p className="fw-bold">Tax Amount: {invoice.totalTax}</p>
                                </div>
                            )}
                        </div>

                        <hr />
                        <div className="row justify-content-end">
                            {invoice && (
                                <div className="col-md-3 ml-auto">
                                    <p className="fw-bold">Total Amount: {invoice.total}</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </>
        </AppPage>
    );
};

export default SpecificInvoice;
