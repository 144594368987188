import { Envelope } from "react-bootstrap-icons";
import { Data } from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import { logApiError } from "../api/ApiUtils";
import {
  InvoiceService,
  InvoiceTable,
  InvoiceTableRow,
} from "./InvoiceService";

export interface InvoiceRow extends Data {
  orderId: string;
  invoiceId: string;
  customerId: string;
  ref: string;
  customerName: string;
  numberOfItems: number;
  totalPrice: string;
  invoiceDate: string;
}

export interface InvoiceTablePage {
  totalCount: number;
  pageCount: number;
  page: number;
  cursor: object;
  rows: InvoiceRow[];
}

export const InvoiceTableService = () => {
  const { getInvoiceTable } = InvoiceService();

  const getInvoicesForTable = async (
    setLoading: (value: boolean) => void,
    size: number,
    cursor?: object,
    showContactForm?: (custId: string, invoiceId: string) => void,
    onCheckboxClick?: (invoice: InvoiceTableRow) => void,
    customerId?: string
  ): Promise<InvoiceTablePage> => {
    setLoading(true);
    try {
      let pagedInvoice = await getInvoiceTable(size, cursor);
      return {
        totalCount: pagedInvoice.totalCount,
        pageCount: pagedInvoice.pageCount,
        page: pagedInvoice.page,
        cursor: pagedInvoice.cursor,
        rows: invoicesToRows(
          pagedInvoice.resource,
          onCheckboxClick,
          showContactForm,
          customerId
        ),
      };
    } catch (e) {
      logApiError("Error loading Invoices.", e);
    } finally {
      setLoading(false);
    }
    return new Promise(() => []);
  };

  const invoicesToRows = (
    invoiceTable: InvoiceTable,
    onCheckboxClick?: (invoice: InvoiceTableRow) => void,
    showContactForm?: (custId: string, quoteId: string) => void,
    customerId?: string
  ): InvoiceRow[] => {
    return invoiceTable.rows.map((invoice) => {
      if (customerId) {
        return {
          actions: (
            <>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onCheckboxClick) {
                      onCheckboxClick(invoice);
                    }
                  }}
                />
              </div>
            </>
          ),
          id: invoice.invoiceId,
          orderId: invoice.orderId,
          invoiceId: invoice.invoiceId,
          customerId: invoice.customerId,
          customerName: invoice.customerName,
          ref: invoice.ref,
          numberOfItems: invoice.numberOfItems,
          totalPrice: invoice.totalPrice,
          invoiceDate: invoice.invoiceDate,
        };
      } else {
        return {
          actions: (
            <>
              <Envelope
                style={{ marginRight: "0.2em" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (showContactForm) {
                    showContactForm(invoice.customerId, invoice.invoiceId);
                  }
                }}
              />
            </>
          ),
          id: invoice.invoiceId,
          orderId: invoice.orderId,
          invoiceId: invoice.invoiceId,
          customerId: invoice.customerId,
          customerName: invoice.customerName,
          ref: invoice.ref,
          numberOfItems: invoice.numberOfItems,
          totalPrice: invoice.totalPrice,
          invoiceDate: invoice.invoiceDate,
        };
      }
    });
  };

  const filter = (
    invoices: InvoiceTableRow[],
    searchValue: string
  ): InvoiceTableRow[] => {
    if (!searchValue) {
      return invoices;
    }

    return invoices.filter((invoiceRow) => {
      return (
        invoiceRow.ref.toLowerCase().includes(searchValue.toLowerCase()) ||
        invoiceRow.customerName
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });
  };

  return {
    getInvoicesForTable,
    filter,
    invoicesToRows,
    columns: [
      {
        title: "Ref",
        key: "ref",
        style: {
          width: "20%",
        },
      },
      {
        title: "Created Date",
        key: "invoiceDate",
      },
      {
        title: "Customer Name",
        key: "customerName",
      },
      {
        title: "Number of Items",
        key: "numberOfItems",
      },

      {
        title: "Total Price",
        key: "totalPrice",
      },
      {
        title: "",
        key: "actions",
        style: {
          width: "10%",
          // no idea - https://github.com/typestyle/typestyle/issues/281
          textAlign: "end" as "end",
        },
      },
    ],
    CustomerInvoiceSelectColumns: [
      {
        title: "Select",
        key: "actions",
        style: {
          width: "10%",
        },
      },
      {
        title: "Ref",
        key: "ref",
        style: {
          width: "20%",
        },
      },
      {
        title: "Created Date",
        key: "invoiceDate",
      },
      {
        title: "Number of Items",
        key: "numberOfItems",
      },

      {
        title: "Total Price",
        key: "totalPrice",
      },
    ],
    orderLineItemsColumns: [
      {
        title: "Product",
        key: "title",
        style: {
          width: "50%",
        },
      },
      {
        title: "Length",
        key: "lengthDisplayString",
      },
      {
        title: "Number of Items",
        key: "quantity",
      },
      {
        title: "Item Price",
        key: "unitPriceDisplayString",
      },

      {
        title: "Total Price",
        key: "totalPriceDisplayString",
      },
    ],
  };
};
