import React from 'react';
import {CategoryBase} from "../../../../services/category/CategoryService";
import {Button, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {EDIT_CATEGORY} from "../../PlatformStack";

interface Props {
    id: string
    category: CategoryBase
}

const CategoryHeader = ({id, category}: Props) => {

    const navigate = useNavigate();

    return (
        <Row style={{ marginTop: '3em', marginBottom: '3em' }}>
            <h3>{category.name}</h3>

            <p>{category.description === '' || category.description === undefined ? 'No description.' : category.description}</p>

            <Button style={{ width: 'auto', marginLeft: '0.8em' }} onClick={() => navigate(EDIT_CATEGORY.navigate([id]))}>
                Edit
            </Button>

            <Button style={{ width: 'auto', marginLeft: '0.8em' }} variant={'danger'}>
                Delete
            </Button>
        </Row>
    )
}

export default CategoryHeader;