import React, {useEffect, useState} from 'react';
import AppPage from "../../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem, Spinner} from "react-bootstrap";
import {CUSTOMERS} from "../../PlatformStack";
import {useNavigate, useParams} from "react-router-dom";
import CustomerForm from "../parts/form/CustomerForm";
import {Customer, CustomerService} from "../../../../services/customer/CustomerService";
import {logApiError} from "../../../../services/api/ApiUtils";

interface Props {

}

const EditCustomer = ({}: Props) => {

    const { getCustomer } = CustomerService();

    const { id } = useParams();
    const navigate = useNavigate();

    const [customer, setCustomer] = useState<Customer>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!id) {
            return;
        }

        const loadCustomer = async (id: string) => {
            let customer: Customer = await getCustomer(id);
            setCustomer(customer);
        }

        setLoading(true);
        loadCustomer(id)
            .catch((e) => logApiError('Error loading customer.', e))
            .finally(() => setLoading(false))
    }, [id]);

    return (
        <AppPage>
            <>
                {loading && <Spinner animation='border'/>}
                {!loading && customer && <>
                    <h3 className="heading">{customer.name}</h3>

                    <Breadcrumb>
                        <BreadcrumbItem onClick={() => navigate(CUSTOMERS.uri)}>Customers</BreadcrumbItem>
                        <BreadcrumbItem active>{customer.name}</BreadcrumbItem>
                    </Breadcrumb>

                    <CustomerForm customer={customer} />
                </>}
            </>
        </AppPage>
    )
}

export default EditCustomer;