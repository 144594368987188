import React from 'react';
import {useNavigate} from "react-router-dom";
import AppPage from "../../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem, Container} from "react-bootstrap";
import {PRODUCTS} from "../../PlatformStack";
import ProductForm from "../parts/form/ProductForm";

interface Props {

}

const AddProduct = ({}: Props) => {

    const navigate = useNavigate();

    return (
        <AppPage>
            <h3 className="heading">Add Product</h3>

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate(PRODUCTS.uri)}>Product</BreadcrumbItem>
                <BreadcrumbItem active>Add</BreadcrumbItem>
            </Breadcrumb>

            <Container>
                <ProductForm />
            </Container>
        </AppPage>
    )
}

export default AddProduct;