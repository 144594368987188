import React, { CSSProperties } from "react";
import { Button, Col, Row, Spinner, Stack, Table } from "react-bootstrap";
import { ArrowClockwise, PlusCircleFill } from "react-bootstrap-icons";

import "./RefreshableTable.scss";

export interface Props {
  data: Data[];
  columns: Column[];
  loading: boolean;
  onRowClick?: (row: object) => void;
  navigateToAddNewForm?: () => void;
  refresh?: () => void;
}

export interface Column {
  title: string;
  key: string;
  style?: CSSProperties;
}

export interface Data {
  [index: string]: string | any;
  id: string;
}

const RefreshableTable = ({
  data,
  columns,
  loading,
  onRowClick,
  refresh,
  navigateToAddNewForm,
}: Props) => {
  return (
    <>
      <Row>
        <Col xs={11} lg={11} />
        <Col xs={1} lg={1}>
          <Stack
            direction="horizontal"
            gap={3}
            className={"refreshableSearchableTable_actions"}
          >
            {navigateToAddNewForm &&
                <Button onClick={navigateToAddNewForm} variant={"success"}>
                  <PlusCircleFill />
                </Button>
            }
            {refresh && (
              <Button onClick={refresh} disabled={loading}>
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {!loading && <ArrowClockwise />}
              </Button>
            )}
          </Stack>
        </Col>
      </Row>
      <Table striped hover>
        <thead>
          <tr>
            {columns.map((column) => {
              return <th key={column.key}>{column.title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td>Loading...</td>
              <td />
            </tr>
          )}
          {!loading &&
            data.length > 0 &&
            data.map((row) => {
              return (
                <tr
                  onClick={() => onRowClick && onRowClick(row)}
                  className={"refreshableSearchableTable_categoryRow"}
                >
                  {columns.map((column) => {
                    return (
                      <td key={`${column.key}-${row.id}`} style={column.style}>
                        {row[column.key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          {!loading && (!data || data.length == 0) && (
            <tr>
              <td>
                <i>No data to display</i>
              </td>
              {columns.map((col) => {
                return <td />;
              })}
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default RefreshableTable;
