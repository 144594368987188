import { Formik } from "formik";
import { useEffect, useReducer, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { logApiError } from "../../../../../services/api/ApiUtils";
import {
  CustomerAddressFormActionType,
  CustomerAddressFormService,
} from "../../../../../services/customer/CustomerAddressFormService";
import { CustomerAddress } from "../../../../../services/customer/CustomerAddressService";

interface AddCustomerAddressModalProps {
  show: boolean;
  close: () => void;
  addresses: CustomerAddress;
  customerId: string;
  onAddressChange: () => void;
}

const AddCustomerAddressModal = ({
  show,
  close,
  addresses,
  customerId,
  onAddressChange,
}: AddCustomerAddressModalProps) => {
  const {
    createCustomerAddress,
    updateCustomerAddress,
    reducer,
    initialState,
  } = CustomerAddressFormService();

  const closeModal = () => {
    dispatch({
      type: CustomerAddressFormActionType.MODAL_CLOSED,
    });
    close();
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    dispatch({
      type: CustomerAddressFormActionType.MODAL_LOADED,
      value: addresses,
    });
  }, [addresses]);

  const handleCustomerAddressChange = async (isCreate: boolean) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      if (isCreate) {
        await createCustomerAddress(customerId, {
          lineOne: state.lineOne,
          lineTwo: state.lineTwo,
          lineThree: state.lineThree,
          lineFour: state.lineFour,
          lineFive: state.lineFive,
          postCode: state.postCode,
        });
      } else {
        await updateCustomerAddress(customerId, {
          id: state.id,
          lineOne: state.lineOne,
          lineTwo: state.lineTwo,
          lineThree: state.lineThree,
          lineFour: state.lineFour,
          lineFive: state.lineFive,
          postCode: state.postCode,
        });
      }
      closeModal();
      onAddressChange();
    } catch (e) {
      logApiError("Error creating customer contact", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={closeModal} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Add New Customer Contact Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          onSubmit={() => {}}
          initialValues={{
            lineOne: state.lineOne,
            lineTwo: state.lineTwo,
            lineThree: state.lineThree,
            lineFour: state.lineFour,
            lineFive: state.lineFive,
            postCode: state.postCode,
          }}
        >
          {({ handleChange, handleBlur, errors }) => (
            <Form className="customerForm_card_form" noValidate>
              <div>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerAddressForm.lineOne"
                >
                  <Form.Control
                    required
                    className="form-input"
                    type="text"
                    name="lineOne"
                    placeholder="Line One"
                    value={state.lineOne}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerAddressFormActionType.LINE_ONE_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerAddressForm.lineTwo"
                >
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="lineTwo"
                    placeholder="Line Two"
                    value={state.lineTwo}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerAddressFormActionType.LINE_TWO_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerAddressForm.lineThree"
                >
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="lineThree"
                    placeholder="Line Three"
                    value={state.lineThree}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerAddressFormActionType.LINE_THREE_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerAddressForm.lineFour"
                >
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="lineFour"
                    placeholder="Line Four"
                    value={state.lineFour}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerAddressFormActionType.LINE_FOUR_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerAddressForm.lineFive"
                >
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="lineFive"
                    placeholder="Line Five"
                    value={state.lineFive}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerAddressFormActionType.LINE_FIVE_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="customerAddressForm.postCode"
                >
                  <Form.Control
                    className="form-input"
                    type="text"
                    name="postCode"
                    placeholder="Postcode"
                    value={state.postCode}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      dispatch({
                        type: CustomerAddressFormActionType.POSTCODE_UPDATE,
                        value: e.target.value,
                      });
                      handleChange(e);
                    }}
                  />
                </Form.Group>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() =>
            state.id
              ? handleCustomerAddressChange(false)
              : handleCustomerAddressChange(true)
          }
        >
          {state.id ? "Update" : "Create New"}
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerAddressModal;
