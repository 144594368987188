import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Material,
  MaterialService,
} from "../../../../services/materials/MaterialService";
import { logApiError } from "../../../../services/api/ApiUtils";
import AppPage from "../../components/page/AppPage";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Spinner,
} from "react-bootstrap";
import { MATERIALS } from "../../PlatformStack";
import MaterialForm from "../../materials/parts/forms/MaterialForm";

interface Props {}

const EditMaterial = ({}: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { getMaterial } = MaterialService();

  const [loading, setLoading] = useState<boolean>();
  const [material, setMaterial] = useState<Material>();

  useEffect(() => {
    if (loading || !id) {
      return;
    }

    const loadMaterial = async (materialId: string) => {
      setLoading(true);
      try {
        const resp = await getMaterial(materialId);
        setMaterial(resp);
      } catch (e) {
        logApiError("Error loading material.", e);
      } finally {
        setLoading(false);
      }
    };

    loadMaterial(id);
  }, []);

  return (
    <AppPage>
      <Container>
        {loading && (
          <>
            <Spinner animation={"border"} />
            <p>Loading...</p>
          </>
        )}
        {!loading && id && material && (
          <>
            <h3 className="heading">{material.name}</h3>

            <Breadcrumb>
              <BreadcrumbItem onClick={() => navigate(MATERIALS.uri)}>
                Materials
              </BreadcrumbItem>
              <BreadcrumbItem active>{material.name}</BreadcrumbItem>
            </Breadcrumb>

            <MaterialForm material={material} />
          </>
        )}
      </Container>
    </AppPage>
  );
};

export default EditMaterial;
