import React from 'react';
import AppPage from "../components/page/AppPage";
import AllCategoriesTable from "./parts/alltable/AllCategoriesTable";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";

interface Props {

}

const Categories = ({}: Props) => {
    return (
        <AppPage>
            <>
                <h3 className={"heading"}>Categories</h3>

                <Breadcrumb>
                    <BreadcrumbItem active>Categories</BreadcrumbItem>
                </Breadcrumb>
                <AllCategoriesTable />
            </>
        </AppPage>
    )
}

export default Categories;