import React, {useState} from "react";
import RefreshableTable from "../../../../components/table/refreshableTable/RefreshableTable";
import {QuoteTableService} from "../../../../services/quote/QuoteTableService";
import {OrderedItems} from "../../../../services/quote/QuoteService";

import './invoiceOrderItemsTable.scss'
import { OrderLine } from "../../../../services/order/OrderService";

interface Props {
  orderLineItems: OrderLine[];
}

const QuoteOrderItemsTable = ({ orderLineItems }: Props) => {

  const { orderLineItemsColumns } = QuoteTableService();

  const [loading] = useState<boolean>(false);

  return (
    <RefreshableTable
      data={orderLineItems.map((line, idx) => {
        return {
          ...line,
          id: idx+"" //make string
        }
      })}
      columns={orderLineItemsColumns}
      loading={loading}
    />
  );
};

export default QuoteOrderItemsTable;
