import React, {useEffect, useState} from 'react';
import {Account, AccountService} from "../../../../services/account/AccountService";
import {useStore} from "../../../../services/state/useStore";
import AccountOption from "./component/AccountOption";

import {Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {CREATE_ACCOUNT} from "../../OnBoardingStack";

import './AccountList.scss';
import {logApiError} from "../../../../services/api/ApiUtils";
import {PRODUCTS} from "../../../platform/PlatformStack";


interface Props {

}

const AccountList = ({}: Props) => {

    const { token, updateAccountId } = useStore();
    const { getAllAccounts } = AccountService();

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<Account[]>();

    useEffect(() => {
        if (!token) {
            return;
        }

        const loadTenants = async () => {
            const accounts: void|Account[] = await getAllAccounts()
                .catch((err) => logApiError('Error loading your accounts.', err));
            if (!accounts) {
                return;
            }
            {/** Temporary until ECS provisioning is implemented */}
            // if (accounts.length === 0) {
            //     goToCreateTenant()
            //     return;
            // }
            if (accounts.length === 1) {
                goToTenantApp(accounts[0].id)
            }
            setAccounts(accounts);
        }

        setLoading(true);
        loadTenants()
            .finally(() => setLoading(false));
    }, [token]);

    const goToCreateTenant = () => {
        navigate(CREATE_ACCOUNT.uri);
    }

    const goToTenantApp = (id: string) => {
        updateAccountId(id);
        navigate(PRODUCTS.uri);
    }

    return (
        <>
            <h3 className="cardHeading">Your Accounts</h3>
            {!loading && accounts?.map(t => <AccountOption name={t.name} role={t.users[0].role} />)}
            {/** Temporary until ECS provisioning is implemented */}
            {!loading && !accounts && <i>Please email <code>jamie@kee.digital</code> to continue setting up your account.</i>}
            {!loading && accounts?.length === 0 && <i>Please email <code>jamie@kee.digital</code> to continue setting up your account.</i>}
            {loading && <Spinner animation={"border"} />}
            {/** Temporary until ECS provisioning is implemented */}
            {/*<Row>*/}
            {/*    <Button*/}
            {/*        className="tenantList_addNewAccountBtn"*/}
            {/*        onClick={() => goToCreateTenant()}*/}
            {/*    >Add New Account</Button>*/}
            {/*</Row>*/}
        </>
    )
}

export default AccountList;