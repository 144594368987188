import React, {useState} from 'react';
import * as yup from "yup";
import {Button, Card, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {
    CustomerContactFormState,
    CustomerFormAction,
    CustomerFormActionType
} from "../../../../../services/customer/CustomerFormService";
import {CaretDownFill, CaretUpFill, Trash} from "react-bootstrap-icons";

interface Props {
    contacts: CustomerContactFormState[]
    dispatch: (x: CustomerFormAction) => void
}

const CustomerContactsForm = ({contacts, dispatch}: Props) => {

    const [closedIds, setClosedIds] = useState<string[]>([]);

    const schema = yup.object().shape({
        name: yup.string(),
        email: yup.string(),
        phoneNumber: yup.string()
    });

    const contactUpdate = (id: string, field: 'name'|'email'|'phone', value: string) => {
        dispatch({
            type: CustomerFormActionType.CONTACT_UPDATE,
            value: value,
            id: id,
            field: field
        });
    }

    return (
        <Card className="justify-content-md-center productForm_card">
            <Card.Body className="productForm_card_sectionContainer">
                Contacts
            </Card.Body>
            {contacts.map((a) => {
                return <Card.Body>
                    <Formik
                        onSubmit={() => {}}
                        validationSchema={schema}
                        initialValues={{
                            name: a.name,
                            email: a.email,
                            phoneNumber: a.phoneNumber,
                        }}
                    >
                        {({
                              handleChange,
                              handleBlur,
                              values = {
                                  name: a.name,
                                  email: a.email,
                                  phoneNumber: a.phoneNumber,
                              },
                              touched,
                              errors
                          }) => (
                            <Form className="customerForm_card_form" noValidate>
                                <Form.Label style={{ width: '100%' }}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                        <div>
                                            {closedIds.includes(a.id) ?
                                                <CaretDownFill
                                                    onClick={() => setClosedIds(closedIds.filter(c => c != a.id))} /> :
                                                <CaretUpFill onClick={() => {
                                                    let ids = [...closedIds]
                                                    ids.push(a.id)
                                                    setClosedIds(ids)
                                                }}/>}
                                            {!!a.name && <span style={{ marginLeft: '0.5em' }}>{a.name} </span>}
                                            {!a.name && <span style={{ marginLeft: '0.5em' }}>Contact </span>}
                                        </div>
                                        <Trash className={"customerForm_deleteIcon"} onClick={() => {
                                            dispatch({
                                                type: CustomerFormActionType.REMOVE_CONTACT,
                                                value: a.id
                                            })
                                        }}/>
                                    </div>
                                </Form.Label>
                                {!closedIds.includes(a.id) && <div>
                                    <Form.Group as={Row} className="mb-3" controlId="customerContactForm.name">
                                        <Form.Control
                                            className="form-input"
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={a.name}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                contactUpdate(a.id, 'name', e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerContactForm.email">
                                        <Form.Control
                                            className="form-input"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={a.email}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                contactUpdate(a.id, 'email', e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="customerContactForm.phone">
                                        <Form.Control
                                            className="form-input"
                                            type="text"
                                            name="phoneNumber"
                                            placeholder="Phone Number"
                                            value={a.phoneNumber}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                contactUpdate(a.id, 'phone', e.target.value);
                                                handleChange(e);
                                            }}
                                        />
                                    </Form.Group>
                                </div>}
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            })}
            <Button style={{width: 'fit-content', margin: 16}} onClick={() => dispatch({
                type: CustomerFormActionType.NEW_CONTACT
            })}>Add Contact</Button>
        </Card>
    )
}

export default CustomerContactsForm;