import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import PriceBandForm from "./PriceBandForm";

import './PriceBands.scss';
import {PriceBandBase, PriceBandService} from "../../../../services/pricing/PriceBandService";
import {logApiError} from "../../../../services/api/ApiUtils";
import {toast} from "react-toastify";

export type PriceBandFormInstance = {
    id: string
    index: number
    name?: string
    isDefault?: boolean
}

interface Props {

}

const PriceBands = ({}: Props) => {

    const { getPriceBands, updatePriceBands } = PriceBandService();

    const [bands, setBands] = useState<PriceBandFormInstance[]>([
        {
            id: 'id-0',
            index: 0,
            isDefault: true
        }
    ]);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadPriceBands();
    }, []);

    const loadPriceBands = async () => {
        setLoading(true);
        try {
            const priceBands = await getPriceBands();
            if (priceBands) {
                if (priceBands.totalCount === 0) {
                    return;
                }
                setBands(priceBands.resource);
            }
        } finally {
            setLoading(false);
        }
    }

    const addNewBand = () => {
        setBands([
            ...bands,
            {
                id: `id-${bands.length}`,
                index: bands.length
            }
        ])
    }

    const updateBandName = (id: string, value: string) => {
        updateBands(id, (band) => {
            band.name = value;
            return band;
        })
    }

    const updateDefault = (id: string) => {
        setBands(bands.map((band) => {
            band.isDefault = band.id === id
            return band;
        }));
    }

    const updateBands = (id: string, updateBand: (band: PriceBandFormInstance) => PriceBandFormInstance) => {
        setBands(bands.map((band) => {
            if (band.id !== id) {
                return band;
            }

            return updateBand(band);
        }));
    }

    const saveBands = async () => {
        const toSave: PriceBandBase[] = bands.map((band) => {
            let priceBandBase: PriceBandBase = {
                name: band.name ? band.name : 'No name',
                index: band.index,
                isDefault: band.isDefault ? band.isDefault : false
            }
            if (!band.id.includes('id-')) {
                priceBandBase.id = band.id
            }

            return priceBandBase;
        });

        try {
            setLoading(true);
            await updatePriceBands(toSave);
            toast.success('Pricing saved.')
            loadPriceBands();
        } catch (e) {
            logApiError('Unable to update price bands.', e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <h3 className="subheading" style={{ marginTop: '1.5em' }}>Price Bands</h3>
            {loading && <Spinner animation="border" />}
            {!loading && <>
                <Row>
                    <Col xs={12}>
                        <Button
                            disabled={loading}
                            style={{ float: 'right' }}
                            onClick={() => saveBands()}
                        >Update</Button>
                    </Col>
                </Row>
                <Row>
                    <Card style={{ paddingBottom: '1em', paddingTop: '1em', marginTop: '1em' }}>
                        <Form className="priceBands_form" noValidate>
                            {bands.map((band) =>
                                <PriceBandForm
                                    key={ band.id }
                                    updateDefault={ updateDefault }
                                    updateBandName={ updateBandName }
                                    priceBand={ band } />
                            )}
                        </Form>

                        <span
                            onClick={addNewBand}
                            style={{ color: 'blue', cursor: 'pointer' }}>
                        + Add new Band
                    </span>
                    </Card>
                </Row>
            </>}

        </>
    )
}

export default PriceBands;