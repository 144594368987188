import { Data } from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import { logApiError } from "../api/ApiUtils";
import {
  InvoiceService,
  InvoiceTable,
  InvoiceTableRow,
} from "../invoice/InvoiceService";

export interface InvoiceRow extends Data {
  orderId: string;
  invoiceId: string;
  customerId: string;
  ref: string;
  customerName: string;
  numberOfItems: number;
  totalPrice: string;
  invoiceDate: string;
}

export interface InvoiceTablePage {
  totalCount: number;
  pageCount: number;
  page: number;
  cursor: object;
  rows: InvoiceRow[];
}

export const CustomerInvoiceTableService = () => {
  const { getInvoiceTable } = InvoiceService();

  const getInvoicesForTable = async (
    setLoading: (value: boolean) => void,
    size: number,
    customerId: string,
    onCheckboxClick: (invoice: InvoiceTableRow) => void,
    showContactForm: (custId: string, quoteId: string) => void,
    cursor?: object
  ): Promise<InvoiceTablePage> => {
    setLoading(true);
    try {
      let pagedInvoice = await getInvoiceTable(size, cursor, customerId);
      return {
        totalCount: pagedInvoice.totalCount,
        pageCount: pagedInvoice.pageCount,
        page: pagedInvoice.page,
        cursor: pagedInvoice.cursor,
        rows: invoicesToRows(pagedInvoice.resource, onCheckboxClick),
      };
    } catch (e) {
      logApiError("Error loading Invoices.", e);
    } finally {
      setLoading(false);
    }
    return new Promise(() => []);
  };

  const invoicesToRows = (
    invoiceTable: InvoiceTable,
    onCheckboxClick: (invoice: InvoiceTableRow) => void
  ): InvoiceRow[] => {
    return invoiceTable.rows.map((invoice) => {
      return {
        actions: (
          <>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                onClick={(e) => {
                  e.stopPropagation();
                  onCheckboxClick(invoice);
                }}
              />
            </div>
          </>
        ),
        id: invoice.invoiceId,
        orderId: invoice.orderId,
        invoiceId: invoice.invoiceId,
        customerId: invoice.customerId,
        customerName: invoice.customerName,
        ref: invoice.ref,
        numberOfItems: invoice.numberOfItems,
        totalPrice: invoice.totalPrice,
        invoiceDate: invoice.invoiceDate,
      };
    });
  };

  const filter = (
    invoices: InvoiceTableRow[],
    searchValue: string
  ): InvoiceTableRow[] => {
    if (!searchValue) {
      return invoices;
    }

    return invoices.filter((invoiceRow) => {
      return (
        invoiceRow.ref.toLowerCase().includes(searchValue.toLowerCase()) ||
        invoiceRow.customerName
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });
  };

  return {
    getInvoicesForTable,
    filter,
    invoicesToRows,
    columns: [
      {
        title: "Select",
        key: "actions",
        style: {
          width: "10%",
        },
      },
      {
        title: "Ref",
        key: "ref",
        style: {
          width: "20%",
        },
      },
      {
        title: "Created Date",
        key: "invoiceDate",
      },
      {
        title: "Number of Items",
        key: "numberOfItems",
      },

      {
        title: "Total Price",
        key: "totalPrice",
      },
    ],
  };
};
