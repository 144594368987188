import {Includes, PaymentService} from "./PaymentService";
import {toast} from "react-toastify";
import {logApiError} from "../api/ApiUtils";

export enum PaymentFormActionType {
    PAYMENT_METHOD_UPDATE = "PAYMENT_METHOD_UPDATE",
    NOTE_UPDATE = "NOTE_UPDATE",
    AMOUNT_UPDATE = "AMOUNT_UPDATE",
    MODAL_LOADED = "MODAL_LOADED",
    REASON_UPDATE = "REASON_UPDATE",
    ONE_OFF_SELECTED = "ONE_OFF_SELECTED",
    INVOICE_SELECTED = "ONE_OFF_SELECTED",
    ROW_SELECTED = "ROW_SELECTED"
}

export type PaymentFormAction =
    | { value: string; type: PaymentFormActionType.REASON_UPDATE }
    | { value: DeletePaymentState; type: PaymentFormActionType.MODAL_LOADED }
    | { value: string; type: PaymentFormActionType.PAYMENT_METHOD_UPDATE; }
    | { value: string; type: PaymentFormActionType.ONE_OFF_SELECTED }
    | { value: string; type: PaymentFormActionType.NOTE_UPDATE }
    | { value: number; type: PaymentFormActionType.AMOUNT_UPDATE }
    | { value: string; type: PaymentFormActionType.INVOICE_SELECTED }
    | { value: Includes[], type: PaymentFormActionType.ROW_SELECTED };

export interface DeletePaymentState {
    paymentId?: string;
    customerId?: string;
    reason: string;
}

export interface PaymentFormState {
    customerId: string;
    paymentSource: string;
    note: string;
    includes: Includes[];
    amount: number;
}

export const PaymentFormService = () => {
    const {createPayment, deletePayment} = PaymentService();

    const create = async (
        customerId: string,
        paymentSource: string,
        note: string,
        includes: Includes[],
        amount: number
    ) => {
        if (!paymentSource) {
            // this should be handled by formik somehow
            toast.error("Can not create payment, paymentSource is missing.");
            return;
        }
        if (!amount) {
            // this should be handled by formik somehow
            toast.error("Can not create payment, please select any of the invoices you want to pay for.");
            return;
        }
        try {
            await createPayment(
                customerId,
                paymentSource,
                note,
                includes,
                amount
            );
            toast.success("Payment created");
        } catch (e) {
            logApiError("Error creating payment.", e);
        }
    };
    const reducer = (
        state: PaymentFormState,
        action: PaymentFormAction
    ): PaymentFormState => {
        switch (action.type) {
            case PaymentFormActionType.PAYMENT_METHOD_UPDATE:
                return {
                    ...state,
                    paymentSource: action.value,
                };
            case PaymentFormActionType.NOTE_UPDATE:
                return {
                    ...state,
                    note: action.value,
                };
            case PaymentFormActionType.AMOUNT_UPDATE:
                return {
                    ...state,
                    amount: action.value,
                };
            case PaymentFormActionType.ONE_OFF_SELECTED:
                return {
                    ...state,
                    includes: [{"type": "ONE_OFF"}],
                    customerId: action.value
                };
            case PaymentFormActionType.INVOICE_SELECTED:
                return {
                    ...state,
                    customerId: action.value
                };
            case PaymentFormActionType.ROW_SELECTED:
                return {
                    ...state,
                    includes: action.value
                };
            default:
                return state;
        }
    };

    const deleteFormReducer = (
        state: DeletePaymentState,
        action: PaymentFormAction
    ): DeletePaymentState => {
        switch (action.type) {
            case PaymentFormActionType.REASON_UPDATE:
                console.log(state);
                return {
                    ...state,
                    reason: action.value,
                };
            case PaymentFormActionType.MODAL_LOADED:
                return {
                    ...state,
                    paymentId: action.value.paymentId,
                    customerId: action.value.customerId,
                };
            default:
                return state;
        }
    };

    return {
        savePayment: create,
        deletePayment,
        reducer,
        deleteFormReducer,
        initialState: {
            customerId: "",
            paymentSource: "",
            note: "",
            includes: [],
            amount: 0,
        } as PaymentFormState,
    };
};
