import {toast} from "react-toastify";
import {CustomerContact, CustomerContactService,} from "./CustomerContactService";
import {logApiError} from "../api/ApiUtils";

export enum CustomerContactFormActionType {
    NAME_UPDATE = "NAME_UPDATE",
    EMAIL_UPDATE = "EMAIL_UPDATE",
    PHONE_NUMBER_UPDATE = "PHONE_NUMBER_UPDATE",
    MODAL_LOADED = "MODAL_LOADED",
    MODAL_CLOSED = "MODAL_CLOSED",
}

export type CustomerContactFormAction =
    | {
    type: CustomerContactFormActionType.NAME_UPDATE;
    value: string;
}
    | {
    type: CustomerContactFormActionType.EMAIL_UPDATE;
    value: string;
}
    | {
    type: CustomerContactFormActionType.PHONE_NUMBER_UPDATE;
    value: string;
}
    | {
    type: CustomerContactFormActionType.MODAL_LOADED;
    value: CustomerContact;
}
    | {
    type: CustomerContactFormActionType.MODAL_CLOSED;
};

export interface CustomerContactFormState {
    id?: string;
    name?: string;
    email?: string;
    phoneNumber?: string;
}

export const CustomerContactFormService = () => {
    const {createContact, updateContact} = CustomerContactService();
    const createCustomerContact = async (
        customerId: string,
        customerContact: CustomerContact
    ) => {
        try {
            await createContact(customerId, customerContact);
            toast.success("Contact created successfully");
        } catch (e) {
            logApiError("Error creating Contact.", e);
        }
    };
    const updateCustomerContact = async (
        customerId: string,
        customerContact: CustomerContact
    ) => {
        try {
            await updateContact(customerId, customerContact);
            toast.success("Contact updated successfully");
        } catch (e) {
            logApiError("Error updating Contact.", e);
        }
    };
    const reducer = (
        state: CustomerContactFormState,
        action: CustomerContactFormAction
    ): CustomerContactFormState => {
        switch (action.type) {
            case CustomerContactFormActionType.MODAL_LOADED:
                return {
                    ...state,
                    id: action.value.id,
                    name: action.value.name,
                    email: action.value.email,
                    phoneNumber: action.value.phoneNumber,
                };
            case CustomerContactFormActionType.NAME_UPDATE:
                return {
                    ...state,
                    name: action.value,
                };
            case CustomerContactFormActionType.EMAIL_UPDATE:
                return {
                    ...state,
                    email: action.value,
                };
            case CustomerContactFormActionType.PHONE_NUMBER_UPDATE:
                return {
                    ...state,
                    phoneNumber: action.value,
                };
            case CustomerContactFormActionType.MODAL_CLOSED:
                return {
                    id: "",
                    name: "",
                    email: "",
                    phoneNumber: "",
                };
            default:
                return state;
        }
    };

    return {
        createCustomerContact,
        updateCustomerContact,
        reducer,
        initialState: {
            id: "",
            name: "",
            email: "",
            phoneNumber: "",
        } as CustomerContactFormState,
    };
};
