import {Data} from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import {logApiError} from "../api/ApiUtils";
import {PagedProduct, Product, ProductService} from "./ProductService";
import {ReactElement} from "react";
import {CategoryTag} from "../../components/tags/CategoryTag";

export interface ProductRow extends Data {
    id: string
    title: string
    description: string
    tags: ReactElement[]
}

export interface ProductTablePage {
    totalCount: number
    pageCount: number
    page: number
    rows: ProductRow[]
}

export const ProductsTableService = () => {

    const { getProducts } = ProductService();

    const getProductsForTable = async (setLoading: (value: boolean) => void): Promise<ProductTablePage> => {
        setLoading(true);
        try {
            const pagedProduct: PagedProduct = await getProducts();
            return {
                totalCount: pagedProduct.totalCount,
                pageCount: pagedProduct.pageCount,
                page: pagedProduct.page,
                rows: pagedProduct.resource.map((product) => {
                    return {
                        id: product.id,
                        title: product.title,
                        description: product.description ? '' + product.description : 'No description',
                        tags: product.tags? product.tags.map((tag) => {
                            return <CategoryTag colour={tag.colour} name={tag.name} />
                        }) : []
                    }
                })
            }
        } catch (e) {
            logApiError('Error loading products.', e);
        } finally {
            setLoading(false);
        }
        return new Promise(() => []);
    };

    const productsToRows: (products: Product[]) => ProductRow[] = (products: Product[]) => {
        return products.map((product) => {
            return {
                id: product.id,
                title: product.title,
                description: '' + product.description,
                tags: product.tags ? product.tags.map((tag) => {
                    return <CategoryTag colour={tag.colour} name={tag.name} />
                }) : []
            }
        })
    }

    const filter = (products: ProductRow[], searchValue: string): ProductRow[] => {
        if (!searchValue) {
            return products;
        }
        return products.filter((product) =>
            product.title.toLowerCase().includes(searchValue.toLowerCase()));
    };

    return {
        getProductsForTable,
        filter,
        productsToRows,
        columns: [
            {
                title: 'Title',
                key: 'title'
            },
            {
                title: 'Description',
                key: 'description'
            },
        ]
    }
}
