import React, {useState} from 'react';
import {Formik} from "formik";
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import * as yup from "yup";
import {CategoryTag} from "../../../../../components/tags/CategoryTag";
import {ColourPicker} from "../../../../../components/input/ColourPicker";
import {CategoryBase, CategoryService} from "../../../../../services/category/CategoryService";
import {logApiError} from "../../../../../services/api/ApiUtils";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {CATEGORIES, CATEGORY} from "../../../PlatformStack";

interface Props {
    category?: CategoryBase
}

const CategoryForm = ({category}: Props) => {

    const {createCategory, updateCategory} = CategoryService();
    const navigate = useNavigate();

    const [colour, setColour] = useState<string>(category?.colour ? category.colour : '#ff00ff');
    const [loading, setLoading] = useState<boolean>(false);

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        description: yup.string()
    });

    const submitForm = async (values: any) => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const payload = {
                name: values.name,
                description: values.description,
                colour: colour
            }
            const savedCategory = await ((category && category?.id) ? updateCategory(category.id, payload) : createCategory(payload));
            toast.success('Category created.');
            savedCategory.id ?
                navigate(CATEGORY.navigate([savedCategory.id])) :
                navigate(CATEGORIES.uri);
        } catch (e) {
            logApiError('Error creating category.', e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Row className="justify-content-md-center">

            <Col xs={9}>

                <Formik
                    validationSchema={schema}
                    onSubmit={submitForm}
                    initialValues={{
                        name: category?.name ? category.name : "",
                        description: category?.description ? category.description : ""
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                      }) => (
                        <Form
                            style={{
                                marginTop: "10px",
                                padding: "2rem",
                            }}
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <Form.Group as={Row} className="mb-3" controlId="categoryForm.name">
                                <Form.Label>
                                    Name
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="form-input"
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={values.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.name && errors.name && (
                                    <Alert variant="danger">
                                        {errors.name}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="categoryForm.description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    className="form-input"
                                    placeholder="Description"
                                    name="description"
                                    value={values.description}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    as="textarea"
                                    type="text"
                                    rows={4}
                                />
                                {touched.description && errors.description && (
                                    <Alert variant="danger">
                                        {errors.description}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="categoryForm.description">
                                <Form.Label>Colour</Form.Label>
                                <ColourPicker
                                    updateHex={(hex: string) => setColour(hex)}
                                    initialColour={colour}
                                />
                                <div style={{marginTop: '0.75em'}}>
                                    <span>Category Tag Preview:  </span>
                                    <CategoryTag name={values.name ? values.name : 'Category name'} colour={colour}/>
                                </div>
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={loading}>
                                {category ? 'Save' : 'Create'}
                            </Button>
                            {loading && <Spinner animation="border" style={{marginLeft: '0.75em'}}/>}
                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    )
}

export default CategoryForm;