import { PagedResource } from "../api/common/Types";
import ApiClient from "../api/ApiClient";
import { Data } from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import { ReactElement } from "react";
import { CustomerAddress } from "../customer/CustomerAddressService";

export interface StatementTable {
  rows: StatementTableRow[];
}

export interface StatementTableRow extends Data {
  statementId: string;
  customerId: string;
  customerName: string;
  ref: string;
  statementType: string;
  fromDate: string;
  toDate: string;
  amountDue: string;
  actions: ReactElement;
}

export interface StatementDetails {
  id: string;
  ref: string;
  fromDate: string;
  toDate: string;
  carryOverBalance: string;
  lineItems: LineItems[];
  invoiceTotal: string;
  paymentTotal: string;
  newBalance: string;
  statementType: string;
  shippingAddress: CustomerAddress;
  billingAddress: CustomerAddress;
  customerName: string;
}

export interface LineItems {
  statementType: string;
  ref: string;
  createdDate: string;
  price: string;
  balance: string;
  lineItemsResources: LineItemsResource[];
}

export interface LineItemsResource {
  title: string;
  unitPrice: string;
  quantity: number;
  length: String;
  totalPrice: string;
}

export interface StatementTablePage extends PagedResource {
  resource: StatementTable;
}

export const StatementService = () => {
  const get = async (id: string): Promise<StatementDetails> => {
    return ApiClient.getInstance().get<StatementDetails>(
      `/api/1/statements/${id}`
    );
  };

  const getTable = async (
    size: number,
    cursor?: object,
    customerId?: string
  ): Promise<StatementTablePage> => {
    return ApiClient.getInstance().post<StatementTablePage>(
      `/api/1/statement-table`,
      {
        size,
        cursor,
        customerId,
      }
    );
  };

  return {
    getStatement: get,
    getStatementTable: getTable,
  };
};
