import React from 'react';
import * as yup from "yup";
import {Alert, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {
    ProductEquationFormState,
    ProductFormAction,
    ProductFormActionType
} from "../../../../../services/product/ProductFormService";

interface Props {
    equationState: ProductEquationFormState
    dispatch: (x: ProductFormAction) => void
    onEquationUpdate: (type: string, equation: string) => void
}

const ProductEquationForm = ({equationState, dispatch, onEquationUpdate}: Props) => {

    const schema = yup.object().shape({
        fullLength: yup.string(),
        perFoot: yup.string()
    });

    return (
        <Formik
            onSubmit={() => {}}
            validationSchema={schema}
            enableReinitialize={true}
            initialValues={{
                fullLength: equationState.fullLength,
                perFoot: equationState.perFoot,
            }}
        >
            {({
                  handleChange,
                  handleBlur,
                  touched,
                  errors
              }) => (
                <Form className="productForm_card_form" noValidate>
                    <Form.Group as={Row} className="mb-3" controlId="productEquationForm.equation">
                        <Form.Label>
                            Full Length Equation
                        </Form.Label>
                        <Form.Control
                            required
                            className="form-input"
                            type="text"
                            name="title"
                            placeholder="Equation"
                            value={equationState.fullLength}
                            onBlur={handleBlur}
                            onChange={(e) => {
                                dispatch({
                                    type: ProductFormActionType.FULL_LENGTH_EQUATION_UPDATE,
                                    value: e.target.value
                                });
                                handleChange(e);
                                onEquationUpdate('fullLength', e.target.value)
                            }}
                        />
                        {touched.fullLength && errors.fullLength && (
                            <Alert variant="danger">
                                {errors.fullLength}
                            </Alert>
                        )}
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="productEquationForm.equation">
                        <Form.Label>
                            Per Foot Equation
                        </Form.Label>
                        <Form.Control
                            required
                            className="form-input"
                            type="text"
                            name="title"
                            placeholder="Equation"
                            value={equationState.perFoot}
                            onBlur={handleBlur}
                            onChange={(e) => {
                                dispatch({
                                    type: ProductFormActionType.PER_FOOT_EQUATION_UPDATE,
                                    value: e.target.value
                                });
                                handleChange(e);
                                onEquationUpdate('perFoot', e.target.value)
                            }}
                        />
                        {touched.perFoot && errors.perFoot && (
                            <Alert variant="danger">
                                {errors.perFoot}
                            </Alert>
                        )}
                    </Form.Group>
                </Form>
            )}
        </Formik>
    )
}

export default ProductEquationForm;