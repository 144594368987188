import React from 'react';
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import AppPage from "../components/page/AppPage";
import AllMaterialsTable from "./parts/allltable/AllMaterialsTable";

interface Props {

}

const Materials = ({}: Props) => {
    return (
        <AppPage>
            <>
                <h3 className={"heading"}>Materials</h3>

                <Breadcrumb>
                    <BreadcrumbItem active>Materials</BreadcrumbItem>
                </Breadcrumb>
                <AllMaterialsTable />
            </>
        </AppPage>
    )
}

export default Materials;