import {useState} from "react";
import {toast} from "react-toastify";
import {logApiError} from "../api/ApiUtils";
import {Item, ItemService} from "./ItemService";

export const ItemModalService = () => {

    const {
        createItem
    } = ItemService();

    const [item, setItem] = useState<Item>();

    const [title, setTitle] = useState<string>();
    const [code, setCode] = useState<string>();

    const initItem = (item?: Item) => {
        setItem(item);

        setTitle(item?.title)
        setCode(item?.code)
    }

    const submit = async (): Promise<boolean> => {
        if (item) {
            return update();
        }
        return create();
    }

    const create = async (): Promise<boolean> => {
        if (!title || !code) {
            toast.error('Must provide both title and code.')
            return false;
        }
        try {
            await createItem(title, code);
        } catch (e) {
            logApiError("Error creating new item.", e);
            return false;
        }
        return true;
    }

    const update = async (): Promise<boolean> => {
        if (!title || !code) {
            toast.error('Must provide both title and code.')
            return false;
        }
        try {
            // await updateItem(title, code);
        } catch (e) {
            logApiError("Error updating item.", e);
            return false;
        }
        return true;
    }

    return {
        initItem,
        submit,
    }
}