import {Material, MaterialService} from "./MaterialService";
import {toast} from "react-toastify";
import {logApiError} from "../api/ApiUtils";

export enum MaterialFormActionType {
    MATERIAL_LOADED = 'MATERIAL_LOADED',

    NAME_UPDATE = 'NAME_UPDATE',
    PRICE_UPDATE = 'PRICE_UPDATE',
}

export type MaterialFormAction =
    { value: Material, type: MaterialFormActionType.MATERIAL_LOADED } |
    { value: string, type: MaterialFormActionType.NAME_UPDATE } |
    { value: number, type: MaterialFormActionType.PRICE_UPDATE }

export interface MaterialFormState {
    id?: string
    name?: string
    price?: number
}

export const MaterialFormService = () => {

    const {createMaterial, updateMaterialPrice} = MaterialService();

    const create = async (name: string | undefined,
                          price: number | undefined) => {
        if (!name) {
            // this should be handled by formik somehow
            toast.error('Can not create material, name is missing.')
            return;
        }
        if (!price) {
            // this should be handled by formik somehow
            toast.error('Can not create material, price is missing.')
            return;
        }
        try {
            await createMaterial(name, price);
            toast.success('Material created');
        } catch (e) {
            logApiError('Error creating material.', e);
        }
    }

    const update = async (materialId: string,
                          price: number | undefined) => {
        if (!price) {
            // this should be handled by formik somehow
            toast.error('Can not update material, price is missing.')
            return;
        }
        try {
            await updateMaterialPrice(materialId, price);
            toast.success('Material updated.');
        } catch (e) {
            logApiError('Error creating material.', e);
        }
    }

    const save = (state: MaterialFormState) => {
        return state.id ?
            update(state.id, state.price) :
            create(state.name, state.price)
    }

    const reducer = (state: MaterialFormState, action: MaterialFormAction): MaterialFormState => {
        switch (action.type) {
            case MaterialFormActionType.NAME_UPDATE:
                return {
                    ...state,
                    name: action.value
                }
            case MaterialFormActionType.PRICE_UPDATE:
                return {
                    ...state,
                    price: action.value
                }
            case MaterialFormActionType.MATERIAL_LOADED:
                return {
                    ...state,
                    id: action.value.id,
                    name: action.value.name,
                    price: action.value.price ? action.value.price / 100 : action.value.price
                }
        }
    };

    return {
        saveMaterial: save,
        reducer,
        initialState: {

        },
    }
}