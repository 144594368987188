import React, {useState} from "react";
import {ColorResult, HuePicker} from 'react-color';

interface Props {
    updateHex: (hex: string) => void
    initialColour: string
}

export const ColourPicker = ({updateHex, initialColour}: Props) => {

    const [colour, setColour] = useState<string>(initialColour);

    const handleChange = (color: ColorResult) => {
        setColour(color.hex);
    };

    const handleChangeComplete = (color: ColorResult) => {
        updateHex(color.hex);
    }

    return (
        <HuePicker
            width="100%"
            color={colour}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
        />
    );
}