import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {ProductRow, ProductsTableService} from "../../../../../services/product/ProductsTableService";
import {ADD_PRODUCT, PRODUCT} from "../../../PlatformStack";
import RefreshableSearchableTable
    from "../../../../../components/table/refreshableSearchableTable/RefreshableSearchableTable";

interface Props {
}

const AllProductsTable = ({}: Props) => {

    const navigate = useNavigate();

    const { getProductsForTable, filter, columns } = ProductsTableService();

    const [loading, setLoading] = useState<boolean>(false);

    const [products, setProducts] = useState<ProductRow[]>([]);
    const [data, setData] = useState<ProductRow[]>([]);

    useEffect(() => {
        loadTable();
    }, []);

    useEffect(() => {
        setData(products);
    }, [products]);

    const onRowClick = (row: Object) => {
        let product = row as ProductRow;
        navigate(PRODUCT.navigate([product.id]));
    }

    const toAddNewProduct = () => {
        navigate(ADD_PRODUCT.uri)
    }

    const loadTable = async () => {
        setProducts([]);
        let productTablePage = await getProductsForTable(setLoading);
        setProducts(productTablePage.rows);
    }

    return (
        <RefreshableSearchableTable
            data={data}
            columns={columns}
            loading={loading}
            refresh={loadTable}
            filterFunction={(searchValue) => setData(filter(products, searchValue))}
            onRowClick={onRowClick}
            navigateToAddNewForm={toAddNewProduct}
        />
    );
}

export default AllProductsTable;