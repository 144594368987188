import React from "react";
import AccountList from "../parts/listaccounts/AccountList";
import {CenteredCardPage} from "../../../components/layout/centeredCardPage/CenteredCardPage";

export const ListAccounts = () => {

    return <CenteredCardPage>
        <AccountList />
    </CenteredCardPage>

}