import React, {useEffect, useState} from 'react';
import {QuoteRow, QuoteTableService} from "../../../../../services/quote/QuoteTableService";
import RefreshableTable from "../../../../../components/table/refreshableTable/RefreshableTable";
import SendQuoteToContactModal from "../../modals/SendQuoteToContactModal";
import { ADD_QUOTE, VIEW_QUOTE } from "../../../PlatformStack";
import {useNavigate} from "react-router-dom";

interface Props {
}

const AllQuotesTable = ({}: Props) => {

    const { getQuotesForTable, activeColumns, settledColumns } = QuoteTableService();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false);
    const [showContactModal, setShowContactModal] = useState<boolean>(false);
    const [customerId, setCustomerId] = useState<string>();
    const [quoteId, setQuoteId] = useState<string>();

    const [activeQuotes, setActiveQuotes] = useState<QuoteRow[]>([]);
    const [activeData, setActiveData] = useState<QuoteRow[]>([]);

    const [settledQuotes, setSettledQuotes] = useState<QuoteRow[]>([]);
    const [settledData, setSettledData] = useState<QuoteRow[]>([]);

    useEffect(() => {
        loadTable();
    }, []);

    const onRowClick = (row: Object) => {
        let quote = row as QuoteRow
        navigate(VIEW_QUOTE.navigate([quote.id]))
    }

    const toNewQuote = () => {
        navigate(ADD_QUOTE.uri)
    }

    const displayCustomerContactModal = (custId: string, quoteId: string) => {
        setShowContactModal(true)

        setCustomerId(custId)
        setQuoteId(quoteId)
    }

    const loadTable = async () => {
        setActiveQuotes([]);
        setSettledQuotes([]);
        let productTablePage = await getQuotesForTable(setLoading, displayCustomerContactModal);
        setActiveQuotes(productTablePage.activeRows);
        setActiveData(productTablePage.activeRows);
        setSettledQuotes(productTablePage.settledRows);
        setSettledData(productTablePage.settledRows);
    }

    return (
        <>
            <h3 className="subheading" style={{ marginTop: '1.5em' }}>Active Quotes</h3>
            <RefreshableTable
                data={activeData}
                columns={activeColumns}
                loading={loading}
                refresh={loadTable}
                onRowClick={onRowClick}
                navigateToAddNewForm={toNewQuote}
            />

            <h3 className="subheading" style={{ marginTop: '1.5em' }}>Settled Quotes</h3>
            <RefreshableTable
                data={settledData}
                columns={settledColumns}
                loading={loading}
                onRowClick={onRowClick}
            />

            <SendQuoteToContactModal
                show={showContactModal}
                close={() => setShowContactModal(false)}
                customerId={customerId}
                quoteId={quoteId}
            />
        </>

    );
}

export default AllQuotesTable;