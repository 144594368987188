import React from 'react';
import AppPage from "../components/page/AppPage";
import PriceBands from "./parts/PriceBands";
import {Container} from "react-bootstrap";

interface Props {

}

const Pricing = ({}: Props) => {
    return (
        <AppPage>
            <Container>
                <h3 className={"heading"}>Pricing</h3>
                <PriceBands />
            </Container>
        </AppPage>
    )
}

export default Pricing;