import React, {useState} from "react";
import RefreshableTable from "../../../../../components/table/refreshableTable/RefreshableTable";
import {InvoiceTableService} from "../../../../../services/invoice/InvoiceTableService";

import './invoiceOrderItemsTable.scss'
import { OrderLine } from "../../../../../services/order/OrderService";

interface Props {
  lines: OrderLine[];
}

const InvoiceOrderItemsTable = ({ lines }: Props) => {

  const { orderLineItemsColumns } = InvoiceTableService();

  const [loading] = useState<boolean>(false);
  return (
    <RefreshableTable
      data={lines.map(l => {
        return {
          ...l,
          id: `${l.title}-${l.lengthDisplayString}`
        }
      })}
      columns={orderLineItemsColumns}
      loading={loading}
    />
  );
};

export default InvoiceOrderItemsTable;
