import { Data } from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import { logApiError } from "../api/ApiUtils";
import { QuoteService, QuoteTable, QuoteTableRow } from "./QuoteService";
import { ReactElement } from "react";
import { Envelope } from "react-bootstrap-icons";

export interface QuoteRow extends Data {
    quoteId: string;
    orderId: string;
    customerName: string;
    ref: string;
    status: string;
    expires: string;
    totalPrice: string;
    actions: ReactElement;
}

export interface QuoteTablePage {
    totalCount: number;
    pageCount: number;
    page: number;
    activeRows: QuoteRow[];
    settledRows: QuoteRow[];
}

export const QuoteTableService = () => {

    const { getQuoteTable } = QuoteService();

    const getQuotesForTable = async (
        setLoading: (value: boolean) => void,
        showContactForm: (custId: string, quoteId: string) => void
    ): Promise<QuoteTablePage> => {
        setLoading(true);
        try {
            const quoteTable: QuoteTable = await getQuoteTable();
            return {
                totalCount: 0,
                pageCount: 0,
                page: 0,
                activeRows: quoteTable?.active
                    ? quotesToRows(quoteTable.active, showContactForm)
                    : [],
                settledRows: quoteTable?.settled
                    ? quotesToRows(quoteTable.settled, showContactForm)
                    : []
            };
        } catch (e) {
            if (e instanceof Error) {
                logApiError("Error loading quotes.", e);
            }
        } finally {
            setLoading(false);
        }
        return new Promise(() => []);
    };

    const quotesToRows = (
        quotes: QuoteTableRow[],
        showContactForm: (custId: string, quoteId: string) => void
    ): QuoteRow[] => {
        return quotes.map((quote) => {
            return {
                id: quote.quoteId,
                quoteId: quote.quoteId,
                orderId: quote.orderId,
                customerName: quote.customerName,
                ref: quote.ref,
                status: quote.status,
                expires: quote.expires,
                totalPrice: quote.totalPrice,
                actions: (
                    <>
                        <Envelope
                            style={{ marginRight: "0.2em" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                showContactForm(quote.customerId, quote.quoteId);
                            }}
                        />
                    </>
                )
            };
        });
    };

    const filter = (quotes: QuoteRow[], searchValue: string): QuoteRow[] => {
        if (!searchValue) {
            return quotes;
        }
        return quotes.filter(
            (quoteRow) =>
                quoteRow.id.toLowerCase().includes(searchValue.toLowerCase()) ||
                quoteRow.customerName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                quoteRow.status.toLowerCase().includes(searchValue.toLowerCase())
        );
    };

    return {
        getQuotesForTable,
        filter,
        quotesToRows,
        activeColumns: [
            {
                title: "Ref",
                key: "ref",
                style: {
                    width: "10%"
                }
            },
            {
                title: "Customer Name",
                key: "customerName"
            },
            {
                title: "Status",
                key: "status"
            },
            {
                title: "Expires",
                key: "expires"
            },
            {
                title: "Total Price",
                key: "totalPrice"
            },
            {
                title: "",
                key: "actions",
                style: {
                    width: "10%",
                    // no idea - https://github.com/typestyle/typestyle/issues/281
                    textAlign: "end" as "end"
                }
            }
        ],
        settledColumns: [
            {
                title: "Ref",
                key: "ref",
                style: {
                    width: "10%"
                }
            },
            {
                title: "Customer Name",
                key: "customerName"
            },
            {
                title: "Status",
                key: "status"
            },
            {
                title: "Expired On",
                key: "expires"
            },
            {
                title: "Total Price",
                key: "totalPrice"
            }
        ],
        orderLineItemsColumns: [
            {
                title: "Product",
                key: "title",
                style: {
                    width: "50%"
                }
            },
            {
                title: "Length",
                key: "lengthDisplayString"
            },
            {
                title: "Number of Items",
                key: "quantity"
            },
            {
                title: "Item Price",
                key: "unitPriceDisplayString"
            },

            {
                title: "Total Price",
                key: "totalPriceDisplayString"
            }
        ]
    };
};
