import React from 'react';
import {Category} from "../../../../../services/category/CategoryService";
import ProductsTable from "../../../products/parts/table/ProductsTable";

interface Props {
    category: Category
    reload: () => void
}

const ProductTab = ({category, reload}: Props) => {

    return (
        <ProductsTable
            reload={reload}
            products={category.products.map((product) => {
                return {
                    ...product,
                    category: category
                }
            })}
        />
    )
}

export default ProductTab;