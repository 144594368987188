import React, {useEffect, useState} from "react";
import {CustomerService} from "../../../../services/customer/CustomerService";
import {logApiError} from "../../../../services/api/ApiUtils";

import OneOffPaymentForm from "./forms/OneOffPaymentForm";
import InvoicePaymentForm from "./forms/InvoicePaymentForm";

interface Props {
    customerId: string;
    customerName: string;
}

const AddPaymentTabs = ({customerId, customerName}: Props) => {
    const [customerBalance, setCustomerBalance] = useState<number>();
    const {getCustomer} = CustomerService();

    const loadCustomer = async (customerId?: string) => {
        try {
            if (customerId != null) {
                const resp = await getCustomer(customerId);
                if (resp.customerBalance != null)
                    setCustomerBalance(Number.parseFloat(resp.customerBalance.substring(1)));
            }
        } catch (e) {
            logApiError("Error loading Customer.", e);
        }
    };

    useEffect(() => {
        loadCustomer(customerId);
    }, []);

    const [selectedTab, setSelectedTab] = useState("one-off");

    const handleTabClick = (eventKey: string) => {
        setSelectedTab(eventKey);
    };

    return (
        <div className="mt-8">
            <h6 className="mb-2">Select Payment Type</h6>
            <div className="btn-group" role="group" aria-label="Payment Tabs">
                <button
                    type="button"
                    className={`btn ${selectedTab === "one-off" ? "btn-primary" : "btn-outline-secondary"}`}
                    onClick={() => handleTabClick("one-off")}
                >
                    One Off
                </button>
                <button
                    type="button"
                    className={`btn ${selectedTab === "invoices" ? "btn-primary" : "btn-outline-secondary"}`}
                    onClick={() => handleTabClick("invoices")}
                >
                    Invoices
                </button>

            </div>

            {selectedTab === "one-off" && customerBalance != null && (
                <OneOffPaymentForm customerId={customerId} customerBalance={customerBalance}/>
            )}

            {selectedTab === "invoices" && customerId && customerName && (
                <InvoicePaymentForm customerId={customerId} customerName={customerName}
                                    customerBalance={customerBalance}/>
            )}
        </div>
    );
};

export default AddPaymentTabs;
