import React from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { INVOICES } from "../../PlatformStack";
import { useNavigate } from "react-router-dom";
import OrderForm from "../../order/parts/OrderForm";
import AppPage from "../../components/page/AppPage";
import { OrderType } from "../../../../services/order/OrderFormService";

interface Props {

}

const AddInvoice = ({}: Props) => {

  const navigate = useNavigate();

  return (
    <AppPage>
      <h3 className={"heading"}>Add Invoice</h3>

      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate(INVOICES.uri)}>Invoices</BreadcrumbItem>
        <BreadcrumbItem active>Add</BreadcrumbItem>
      </Breadcrumb>

      <OrderForm type={OrderType.INVOICE} />
    </AppPage>
  );
};

export default AddInvoice;