import ApiClient from "../api/ApiClient";
import {PagedResource} from "../api/common/Types";

export interface MaterialBase {
    id?: string
    name: string
    price: number

}

export interface Material extends MaterialBase {
    id: string
    displayPrice: string
}

export interface PagedMaterial extends PagedResource {
    resource: Material[]
}

export const MaterialService = () => {

    const getAll = (async (): Promise<PagedMaterial> => {
        return ApiClient.getInstance().post<PagedMaterial>(`/api/1/materials-search`, {
            size: 100
        });
    });

    const get = (async (materialId: string): Promise<Material> => {
        return ApiClient.getInstance().get<Material>(`/api/1/materials/${materialId}`);
    });

    const create = (async (name: string, price: number): Promise<Material> => {
        return ApiClient.getInstance().post<Material>('/api/1/materials', {
            name,
            price: price * 100
        });
    });

    const updatePrice = (async (materialId: string, price: number): Promise<Material> => {
        return ApiClient.getInstance().put<Material>(`/api/1/materials/${materialId}/price`, {
            price: price * 100
        });
    });

    return {
        getMaterials: getAll,
        getMaterial: get,
        createMaterial: create,
        updateMaterialPrice: updatePrice
    }
}
