import React from "react";
import {Customer} from "../../../../../services/customer/CustomerService";

interface CustomerInfoProps {
    customer: Customer;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({customer}) => {
    return (
        <>
            <div>
                <h4>Customer Information</h4>
                <p>
                    <strong>Name:</strong> {customer.name}
                </p>
                <p>
                    <strong>Code:</strong> {customer.code}
                </p>
            </div>
            <div>
                <h4>Balance Information</h4>
                <p>
                    <strong>Current Balance:</strong> {customer.customerBalance}
                </p>
            </div>
            <div>
                <h4>Credit Information</h4>
                <p>
                    <strong>Current Credit Limit:</strong> {customer.creditLimitDisplayString}
                </p>
            </div>
        </>

    );
};

export default CustomerInfo;
