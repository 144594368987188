import React from 'react';
import {CenteredCardPage} from "../../../components/layout/centeredCardPage/CenteredCardPage";
import CreateAccountForm from "../parts/createaccount/CreateAccountForm";

interface Props {

}

const CreateAccount = ({}: Props) => {
    return (
        <CenteredCardPage>
            <CreateAccountForm />
        </CenteredCardPage>
    )
}

export default CreateAccount;