import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {NEW_PAYMENT,} from "../../../PlatformStack"
import RefreshableSearchableTable
    from "../../../../../components/table/refreshableSearchableTable/RefreshableSearchableTable"
import {PaymentRow, PaymentsTableService} from "../../../../../services/payments/PaymentsTableService";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";

interface Props {
    customerId?: string
    customerName?: string
}

const AllPaymentsTable = ({customerId, customerName}: Props) => {
    const navigate = useNavigate()

    const {getPaymentsForTable, getCustomerPaymentsForTable, filter, columns} = PaymentsTableService()

    const [loading, setLoading] = useState<boolean>(false)

    const [payments, setPayments] = useState<PaymentRow[]>([])
    const [data, setData] = useState<PaymentRow[]>([])
    const [showContactModal, setShowContactModal] = useState<boolean>(false);
    const [cusId, setCustomerId] = useState<string>();
    const [paymentId, setPaymentId] = useState<string>();

    useEffect(() => {
        loadTable()
    }, [])

    useEffect(() => {
        setData(payments)
    }, [payments])

    const toAddNewPayment = () => {
        if (customerId && customerName) {
            const urlParams = [customerId]
            urlParams.push(customerName)
            navigate(NEW_PAYMENT.navigate(urlParams))
        }
    }
    const displayCustomerContactModal = (custId: string, paymentId: string) => {
        setShowContactModal(true)
        setCustomerId(custId)
        setPaymentId(paymentId)
    }

    const loadTable = async () => {
        setPayments([])
        if (customerId) {
            let paymentTablePage = await getCustomerPaymentsForTable(setLoading, customerId, displayCustomerContactModal)
            setPayments(paymentTablePage)
        } else {
            let paymentsTablePage = await getPaymentsForTable(setLoading)
            setPayments(paymentsTablePage.rows)
        }
    }

    return (
        <>
            <RefreshableSearchableTable
                data={data}
                columns={columns}
                loading={loading}
                refresh={loadTable}
                filterFunction={(searchValue) => setData(filter(payments, searchValue))}
                navigateToAddNewForm={toAddNewPayment}
            />
            <ConfirmDeleteModal
                show={showContactModal}
                close={() => setShowContactModal(false)}
                deletePayData={{paymentId: paymentId, customerId: customerId, reason: ""}}
                customerId={customerId}
                loadTable={ loadTable}
            />
        </>
    )
}

export default AllPaymentsTable
