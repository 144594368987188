import React from 'react';
import * as yup from "yup";
import {Alert, Card, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {
    CustomerFormAction,
    CustomerFormActionType,
    CustomerFormInfoState
} from "../../../../../services/customer/CustomerFormService";

interface Props {
    info: CustomerFormInfoState
    dispatch: (x: CustomerFormAction) => void
}

const CustomerInfoForm = ({ info, dispatch }: Props) => {

    const schema = yup.object().shape({
        code: yup.string().required("Code is required"),
        name: yup.string().required("Name is required"),
        creditLimit: yup.number().required("Credit Limit is required")
    });

    return (
        <Card className="justify-content-md-center productForm_card">
            <Card.Body className="productForm_card_sectionContainer">Customer Info</Card.Body>
            <Card.Body>
                <Formik
                    onSubmit={() => {}}
                    validationSchema={schema}
                    initialValues={{
                        code: info.code,
                        name: info.name,
                        creditLimit:info.creditLimit
                    }}
                >
                    {({
                          handleChange,
                          handleBlur,
                          values={
                              code: info.code,
                              name: info.name,
                              creditLimit:info.creditLimit
                          },
                          touched,
                          errors
                      }) => (
                        <Form className="customerForm_card_form" noValidate>
                            <Form.Group as={Row} className="mb-3" controlId="categoryForm.title">
                                <Form.Label>
                                    Name
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="form-input"
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={info.name}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        dispatch({
                                            type: CustomerFormActionType.NAME_UPDATE,
                                            value: e.target.value
                                        });
                                        handleChange(e);
                                    }}
                                />
                                {touched.name && errors.name && (
                                    <Alert variant="danger">
                                        {errors.name}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="categoryForm.title">
                                <Form.Label>
                                    Code
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="form-input"
                                    type="text"
                                    name="code"
                                    placeholder="Code"
                                    value={info.code}
                                    onBlur={handleBlur}
                                    disabled={!info.overrideCode}
                                    onChange={(e) => {
                                        dispatch({
                                            type: CustomerFormActionType.CODE_UPDATE,
                                            value: e.target.value
                                        })
                                        handleChange(e);
                                    }}
                                />
                                <Form.Check type="checkbox"
                                            label="Override customer code"
                                            className="customerForm_overrideCheckbox"
                                            checked={info.overrideCode}
                                            onChange={(e) => {
                                                dispatch({
                                                    type: CustomerFormActionType.CODE_OVERRIDE_CHECK,
                                                    value: e.target.checked
                                                })
                                            }}/>
                                {touched.code && errors.code && (
                                    <Alert variant="danger">
                                        {errors.code}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="categoryForm.title">
                                <Form.Label>
                                    Credit Limit
                                </Form.Label>
                                <Form.Control
                                    required
                                    className="form-input"
                                    type="number"
                                    name="creditLimit"
                                    placeholder="Credit Limit"
                                    value={info.creditLimit}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        dispatch({
                                            type: CustomerFormActionType.CREDIT_LIMIT_UPDATE,
                                            value: parseFloat(e.target.value)
                                        });
                                        handleChange(e);
                                    }}
                                />
                                {touched.name && errors.name && (
                                    <Alert variant="danger">
                                        {errors.creditLimit}
                                    </Alert>
                                )}
                            </Form.Group>

                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    )
}

export default CustomerInfoForm;