import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logApiError } from "../../../../../services/api/ApiUtils";
import { Alert, Button, Row } from "react-bootstrap";
import { Product } from "../../../../../services/product/ProductService";
import ProductInfoForm from "./ProductInfoForm";

import "./ProductForm.scss";
import { ProductFormActionType, ProductFormService } from "../../../../../services/product/ProductFormService";
import { PRODUCTS } from "../../../PlatformStack";
import ProductEquationForm from "./ProductEquationForm";
import {
    ProductEquation,
    ProductEquationPriceCheck,
    ProductEquationService
} from "../../../../../services/product/ProductEquationService";
import { Material, MaterialService } from "../../../../../services/materials/MaterialService";
import { TaxRuleService } from "../../../../../services/taxrules/TaxRuleService";

interface Props {
    product?: Product;
    equation?: ProductEquation;
}

const ProductForm = ({ product, equation }: Props) => {

    const { getMaterials } = MaterialService();
    const { runPriceChecker } = ProductEquationService();
    const { searchTaxRuleForProduct } = TaxRuleService();

    const { saveProduct, reducer, initialState } = ProductFormService();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [materials, setMaterials] = useState<Material[]>([]);
    const [priceCheck, setPriceCheck] = useState<ProductEquationPriceCheck>();

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getMaterials().then(m => {
            if (m != null) {
                setMaterials(m.resource);
            }
        });
    }, []);

    useEffect(() => {
        if (!product) {
            return;
        }

        dispatch({
            type: ProductFormActionType.PRODUCT_LOADED,
            value: product
        });

        onEquationUpdate()

        if (product.id) {
            searchTaxRuleForProduct(product.id)
                .then(resp => {
                    let ruleId;
                    if (resp && resp.resource && resp.resource.length === 1) {
                        ruleId = resp.resource[0].id
                    }

                    dispatch({
                        type: ProductFormActionType.TAX_RULE_LOADED,
                        value: {
                            hasVat: !!ruleId,
                            taxRuleId: ruleId
                        }
                    });
                })
        }
    }, [product]);

    useEffect(() => {
        if (!equation) {
            return;
        }

        dispatch({
            type: ProductFormActionType.EQUATION_LOADED,
            value: equation
        });
        onEquationUpdate()
    }, [equation]);

    const save = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            await saveProduct(state);
            navigate(PRODUCTS.uri);
        } catch (e) {
            logApiError("Error saving product.", e);
        } finally {
            setLoading(false);
        }
    };

    const onEquationUpdate = (type?: string, equation?: string) => {
        if (!product?.id || (!state.equation.fullLength && !state.equation.perFoot && !equation)) {
            return;
        }

        let fullLength = type === "fullLength" ? equation : state.equation.fullLength;
        let perFoot = type === "perFoot" ? equation : state.equation.perFoot;

        runPriceChecker(product.id, fullLength, perFoot)
            .then(r => setPriceCheck(r));
    };

    const handleVatChange = (checked: boolean) => {
        dispatch({
            type: ProductFormActionType.VAT_RULE_UPDATE,
            value: checked
        });
    }

    return (
        <>
            <Row style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <Button
                    variant={"outline-danger"}
                    style={{ marginRight: "0.5em", width: "auto" }}
                    onClick={() => navigate(PRODUCTS.uri)}>
                    Cancel
                </Button>
                <Button style={{ width: "auto" }} onClick={save}>{product ? "Update" : "Create"}</Button>
            </Row>
            <Row>
                <ProductInfoForm info={state.info} dispatch={dispatch} />
            </Row>
            <Row>
                <h3>Product VAT</h3>
                <div style={{ marginBottom: '1em' }}>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={(e) => handleVatChange(e.target.checked)}
                        checked={state.info.hasVat}
                        style={{ marginRight: '0.75em' }}
                    />
                    Product is Eligible for VAT
                </div>
            </Row>
            <Row>
                <h3>Cost Price Equation</h3>
                <ProductEquationForm equationState={state.equation} dispatch={dispatch}
                                     onEquationUpdate={onEquationUpdate} />
                {priceCheck &&
                    <Alert variant={"primary"}>
                      <table style={{ width: "100%" }}>
                          <thead style={{ borderBottom: '1px solid black' }}>
                          <tr>
                            <td style={{ width: '40%'}}>Variant</td>
                            <td style={{ width: '30%'}}>Full Length</td>
                            <td style={{ width: '30%'}}>Per Foot</td>
                          </tr>
                          </thead>
                            {priceCheck.rows.map(r => {
                                return <tr>
                                    <td>{r.variantTitle}</td>
                                    <td>{r.fullLength}</td>
                                    <td>{r.perFoot}</td>
                                </tr>;
                            })}
                        </table>
                    </Alert>
                    }
                <Alert variant={"secondary"}>
                    <b>Equation Help</b>
                    <br />
                    <i>What should the result of the equation be?</i>
                    <ul>
                        <li>The result of the equation should be the cost price of the variant in pennies.</li>
                    </ul>
                    <i>How do you make the equation unique for each variant?</i>
                    <ul>
                        <li>You use the variant's attributes like width, height etc in the equation.</li>
                        <li>For example, using the equation "{"{{WIDTH}}"} * 12" will result in each variant's width
                            being inputted into the equation to calculate the cost price.
                        </li>
                    </ul>
                    <i>How do you add variables into the equation?</i>
                    <ul>
                        <li>You just wrap the variable name in {"{{"} ... {"}}"}</li>
                        <li>For example, to add a variant's height you would do: {"{{HEIGHT}}"}.</li>
                    </ul>
                    <i>What are the available variables?</i>
                    <ul>
                        <li>You can use each of the variable's attributes:</li>
                        <ul>
                            <li>WIDTH</li>
                            <li>HEIGHT</li>
                            <li>DEPTH</li>
                            <li>LENGTH</li>
                            <li>DIAMETER</li>
                            <li>THICKNESS</li>
                            <li>WEIGHT_PER_METRE</li>
                            <li>MESH_HOLE_WIDTH</li>
                            <li>MESH_HOLE_HEIGHT</li>
                        </ul>
                        <li>You can use each of the materials you have added, which currently are:</li>
                        <ul>
                            {materials.map(m => {
                                return <li>{m.name.toUpperCase()} - {m.displayPrice}</li>;
                            })}
                        </ul>
                    </ul>
                    <ul>

                    </ul>
                </Alert>
            </Row>
        </>
    );
};

export default ProductForm;