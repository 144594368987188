import React from 'react';
import AppPage from "../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import AllProductsTable from "./parts/alltable/AllProductsTable";

interface Props {

}

const Products = ({}: Props) => {
    return (
        <AppPage>
            <>
                <h3 className={"heading"}>Products</h3>

                <Breadcrumb>
                    <BreadcrumbItem active>Products</BreadcrumbItem>
                </Breadcrumb>
                <AllProductsTable />
            </>
        </AppPage>
    )
}

export default Products;