import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RefreshableSearchablePageableTable from "../../../../../components/table/refreshableSearchablePageableTable/RefreshableSearchablePageableTable";
import { CustomerStatementTableService } from "../../../../../services/customer/CustomerStatementTableService";
import { StatementTableRow } from "../../../../../services/statements/StatementService";
import { VIEW_STATEMENT_CUSTOMER } from "../../../PlatformStack";

interface Props {
  customerId: string;
  customerName: string;
}

const CustomerStatementTable = ({ customerId, customerName }: Props) => {
  const navigate = useNavigate();

  const { getStatementsForTable, columns, filter } =
    CustomerStatementTableService();

  const [loading, setLoading] = useState<boolean>(false);
  const [statements, setStatements] = useState<StatementTableRow[]>([]);
  const [data, setData] = useState<StatementTableRow[]>([]);
  const [cursor, setCursor] = useState<object>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    setData(statements);
  }, [statements]);

  const onRowClick = (row: Object) => {
    let statement = row as StatementTableRow;
    // Create an array with statement.id as the first parameter and customerId as the second parameter
    const urlParams = [statement.statementId];
    urlParams.push(customerName);
    urlParams.push(customerId);
    navigate(VIEW_STATEMENT_CUSTOMER.navigate(urlParams));
  };

  const loadTable = async (size: number) => {
    setStatements([]);
    let statementTable = await getStatementsForTable(
      setLoading,
      size,
      customerId,
      cursor
    );
    setStatements(statementTable.resource.rows);
    setTotalCount(statementTable.totalCount);
    setPageCount(statementTable.pageCount);
    setCursor(statementTable.cursor);
  };

  const search = (val: string) => {
    setData(filter(statements, val));
  };

  return (
    <RefreshableSearchablePageableTable
      totalCount={totalCount}
      pageCount={pageCount}
      data={data}
      columns={columns}
      loading={loading}
      loadTable={loadTable}
      filterFunction={(searchValue) => search(searchValue)}
      onRowClick={onRowClick}
    />
  );
};

export default CustomerStatementTable;
