import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Customer } from "../../../../services/customer/CustomerService";
import CustomerInfo from "./customerDetails/CustomerInfo";
import CustomerContacts from "./customerDetails/CustomerContacts";
import { PagedCustomerContacts } from "../../../../services/customer/CustomerContactService";
import { PagedCustomerAddress } from "../../../../services/customer/CustomerAddressService";
import CustomerAddresses from "./customerDetails/CustomerAddresses";
import AllStatementsTable from "../../statements/parts/alltable/AllStatementsTable";
import AllPaymentsTable from "../../payments/parts/allltable/AllPaymentsTable";
import CustomerStatementTable from "./statementtable/CustomerStatementTable";

interface Props {
  customer: Customer;
  customerContact: PagedCustomerContacts;
  customerAddresses: PagedCustomerAddress;
  activeKey?: string;
}

const CustomerTabs = ({
  customer,
  customerContact,
  customerAddresses,
  activeKey,
}: Props) => {
  return (
    <Tabs defaultActiveKey={activeKey} id="customer-tabs" className="mb-3">
      <Tab eventKey="info" title="Customer Info" style={{ padding: "0 1em" }}>
        <CustomerInfo customer={customer} />
      </Tab>
      <Tab eventKey="contacts" title="Contacts" style={{ padding: "0 1em" }}>
        <CustomerContacts
          customerContacts={customerContact}
          currentCustomerId={customer.id}
          defaultContactId={customer.defaultContactId}
        />
      </Tab>
      <Tab eventKey="Addresses" title="Addresses" style={{ padding: "0 1em" }}>
        <CustomerAddresses
          customerAddresses={customerAddresses}
          currentCustomerId={customer.id}
          defaultBillingAddressId={customer.defaultBillingAddressId}
          defaultShippingAddressId={customer.defaultShippingAddressId}
        />
      </Tab>
      <Tab
        eventKey="Statements"
        title="Statements"
        style={{ padding: "0 1em" }}
      >
        <CustomerStatementTable customerId={customer.id} customerName={customer.name} />
      </Tab>
      <Tab eventKey="payments" title="Payments" style={{ padding: "0 1em" }}>
        <AllPaymentsTable
          customerId={customer.id}
          customerName={customer.name}
        />
      </Tab>
    </Tabs>
  );
};

export default CustomerTabs;
