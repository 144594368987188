import React from 'react';
import {Tab, Tabs} from "react-bootstrap";
import PurchasesItemTab from "./tabs/PurchasesItemTab";
import PurchasesPurchaseHistoryTab from "./tabs/PurchasesPurchaseHistoryTab";

interface Props {

}

const PurchasesTabs = ({}: Props) => {
    return (
        <Tabs defaultActiveKey="items" id="purchasing-tabs" className="mb-3">
            <Tab eventKey="items" title="Items" style={{ padding: '0 1em' }}>
                <PurchasesItemTab />
            </Tab>
            <Tab eventKey="purchaseHistory" title="Purchase History" style={{ padding: '0 1em' }}>
                <PurchasesPurchaseHistoryTab />
            </Tab>
        </Tabs>
    )
}

export default PurchasesTabs;