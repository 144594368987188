import React, { useState } from "react";
import { Pencil, PencilFill, Trash, TrashFill } from "react-bootstrap-icons";
import {
  CustomerContact,
  CustomerContactService,
  PagedCustomerContacts,
} from "../../../../../services/customer/CustomerContactService";
import AddCustomerContactModal from "../modal/AddCustomerContactModal";
import { toast } from "react-toastify";

interface CustomerContactsProps {
  customerContacts: PagedCustomerContacts;
  currentCustomerId: string;
  defaultContactId: string;
}

const CustomerContacts: React.FC<CustomerContactsProps> = ({
  customerContacts,
  currentCustomerId,
  defaultContactId,
}) => {
  const [showAddCustomerContactModal, setAddCustomerContactModal] =
    useState(false);

  // Initialize selectedContact with initial values
  const [selectedContact, setSelectedContact] = useState<CustomerContact>({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [currentCustomerContacts, setCustomerContacts] =
    useState<PagedCustomerContacts>(customerContacts);

  const openModal = (contact: CustomerContact) => {
    setSelectedContact(contact);
    setAddCustomerContactModal(true);
  };
  const { getContacts, deleteContact, updateDefaultContact } =
    CustomerContactService();

  const updateContactList = async () => {
    const updatedContactList = await getContacts(currentCustomerId);
    setCustomerContacts(updatedContactList);
  };
  const deleteCustomerContact = async (contactId: string) => {
    await deleteContact(currentCustomerId, contactId);
    updateContactList();
    toast.success("Contact deleted successfully");
  };
  const updateCurrentCustomerDefaultContact = async (contactId: string) => {
    try {
      await updateDefaultContact(currentCustomerId, contactId);
      updateContactList();
      toast.success("Default contact updated successfully");
    } catch (e) {
      toast.error("Error updating default contact");
      return;
    }
  };
  const [selectedRadio, setSelectedRadio] = useState(defaultContactId);

  const handleRadioChange = async (contactId: string) => {
    setSelectedRadio(contactId);
    try {
      await updateCurrentCustomerDefaultContact(contactId);
      updateContactList();
    } catch (e) {
      setSelectedRadio(defaultContactId); // Revert to the original selection in case of error
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-success"
          onClick={() => openModal(selectedContact)}
        >
          + Add Contact
        </button>
      </div>
      {currentCustomerContacts.resource.map((contact, index) => (
        <ul className="list-group" key={index}>
          <li className="list-group-item mt-4 d-flex justify-content-between align-items-center">
            <div>
              {contact.name}
              {contact.email && ", "}
              {contact.email}
              {contact.phoneNumber && ", "}
              {contact.phoneNumber}
            </div>
            <div className="d-flex">
              <span className="mr-3" onClick={() => openModal(contact)}>
                <Pencil cursor={"pointer"} />
              </span>
              <span className="ms-3">
                <Trash
                  cursor={"pointer"}
                  onClick={() => deleteCustomerContact(contact.id!)}
                />
              </span>
            </div>
          </li>
        </ul>
      ))}
      <h6 className="mt-4" style={{ marginBottom: 0 }}>
        Default Contact
      </h6>
      <span style={{ fontSize: "0.75em" }}>
        <i>
          This is the contact who will be emailed the monthly statement
          automatically
        </i>
      </span>
      {currentCustomerContacts.resource.map((contact, index) => (
        <ul className="list-group" key={index}>
          <li className="list-group-item mt-3" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name="defaultContactRadios"
              id={`exampleRadios${index}`}
              value={contact.id}
              checked={contact.id === selectedRadio}
              onChange={() => handleRadioChange(contact.id!)}
              style={{ marginRight: "1em" }}
            />
            <label
              className="form-check-label"
              htmlFor={`exampleRadios${index}`}
            >
              {contact.name}
              {contact.email && ", "}
              {contact.email}
              {contact.phoneNumber && ", "}
              {contact.phoneNumber}
            </label>
          </li>
        </ul>
      ))}
      <AddCustomerContactModal
        show={showAddCustomerContactModal}
        close={() => setAddCustomerContactModal(false)}
        contacts={selectedContact}
        customerId={currentCustomerId}
        onContactChange={updateContactList}
      />
    </div>
  );
};

export default CustomerContacts;
