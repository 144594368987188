import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, ModalBody, ModalFooter, Spinner} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Item} from "../../../../../services/purchases/ItemService";
import {ItemModalService} from "../../../../../services/purchases/ItemModalService";

interface Props {
    item: Item | undefined
    show: boolean
    close: (shouldReload?: boolean) => void
}

const ItemModal = ({item, show, close}: Props) => {

    const {initItem, submit} = ItemModalService();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        initItem(item)
    }, [item]);

    return (
        <Modal show={show} onHide={close}>
            <ModalHeader>
                {item ? item.title : 'New Item'}
            </ModalHeader>
            <ModalBody style={{paddingLeft: '5em', paddingRight: '5em'}}>
                <Form className="itemForm_card_form" noValidate>

                </Form>
            </ModalBody>
            <ModalFooter>
                {loading && <Spinner animation='border'/>}
                {!loading &&
                <>
                    <Button variant="outline-danger" onClick={() => close()}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setLoading(true);
                        submit()
                            .then((success) => {
                                close(success);
                            })
                            .finally(() => {
                                setLoading(false);
                            })
                    }}>
                        {item ? 'Update' : 'Save'}
                    </Button>
                </>
                }
            </ModalFooter>
        </Modal>
    )
}

export default ItemModal;