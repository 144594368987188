import React, {ChangeEvent} from 'react';
import {Badge, Card, Form, Spinner} from "react-bootstrap";
import {
    CustomerFormAction,
    CustomerFormActionType,
    CustomerFormPricingState
} from "../../../../../services/customer/CustomerFormService";

interface Props {
    pricing?: CustomerFormPricingState
    dispatch: (x: CustomerFormAction) => void
}

const CustomerPricingForm = ({ pricing, dispatch }: Props) => {

    const checkRadio = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        if (e.target.checked) {
            dispatch({
                type: CustomerFormActionType.ASSIGN_BAND,
                value: id
            })
        }
    }

    return (
        <Card className="justify-content-md-center productForm_card">
            <Card.Body className="productForm_card_sectionContainer">Pricing</Card.Body>
            <Card.Body>
                {(!pricing || !pricing.priceBands) && <Spinner animation='border' />}
                {(pricing && pricing.priceBands) && <>
                    <h6>Price Bands</h6>
                    <p>Select the price band this customer is assigned to in order to calculate their invoices.</p>
                    {pricing.priceBands.map((priceBand) => {
                        return <>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form.Check type="radio"
                                        name="customerForm.pricing.bands.default"
                                        id={`priceForm.default.${priceBand.id}`}
                                        label={priceBand.name}
                                        checked={priceBand.id === pricing?.assignedPriceBand}
                                        style={{ marginRight: '0.5em' }}
                                        onChange={(e) => {
                                            checkRadio(e, priceBand.id);
                                        }}
                            />
                            {priceBand.isDefault && <Badge><span style={{ verticalAlign: 'sub' }}>Default</span></Badge>}
                            </div>
                            <br />
                        </>
                    })}
                </>}
            </Card.Body>
        </Card>
    )
}

export default CustomerPricingForm;