import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RefreshableSearchablePageableTable from "../../../../../components/table/refreshableSearchablePageableTable/RefreshableSearchablePageableTable";
import {
  StatementTablePage,
  StatementTableRow,
} from "../../../../../services/statements/StatementService";
import { StatementTableService } from "../../../../../services/statements/StatementTableService";
import { VIEW_STATEMENT } from "../../../PlatformStack";
import SendStatementToContactModal from "../../modals/SendStatementToContactModal";

interface Props {}

const AllStatementsTable = ({}: Props) => {
  const navigate = useNavigate();

  const { getStatementsForTable, columns, filter } = StatementTableService();

  const [loading, setLoading] = useState<boolean>(false);
  const [statements, setStatements] = useState<StatementTableRow[]>([]);
  const [data, setData] = useState<StatementTableRow[]>([]);
  const [cursor, setCursor] = useState<object>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>();
  const [statementId, setStatementId] = useState<string>();

  useEffect(() => {
    setData(statements);
  }, [statements]);

  const onRowClick = (row: Object) => {
    let statement = row as StatementTableRow;

    const urlParams = [statement.statementId];
    navigate(VIEW_STATEMENT.navigate(urlParams));
  };

  const loadTable = async (size: number) => {
    setStatements([]);
    let statementTablePage: StatementTablePage = await getStatementsForTable(
      setLoading,
      displayCustomerContactModal,
      size,
      cursor
    );
    setStatements(statementTablePage.resource.rows);
    setTotalCount(statementTablePage.totalCount);
    setPageCount(statementTablePage.pageCount);
    setCursor(statementTablePage.cursor);
  };
  const displayCustomerContactModal = (custId: string, statementId: string) => {
    setShowContactModal(true);

    setCustomerId(custId);
    setStatementId(statementId);
  };
  return (
    <>
      <RefreshableSearchablePageableTable
        totalCount={totalCount}
        pageCount={pageCount}
        data={data}
        columns={columns}
        loading={loading}
        loadTable={loadTable}
        onRowClick={onRowClick}
        filterFunction={(searchValue) => filter(data, searchValue)}
      />
      <SendStatementToContactModal
        show={showContactModal}
        close={() => setShowContactModal(false)}
        customerId={customerId}
        statementId={statementId}
      />
    </>
  );
};
export default AllStatementsTable;
