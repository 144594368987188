import {ReactComponentElement} from "react";
import {useGlobalState} from "./useGlobalState";
import {Context} from "./Context";

interface Props {
    children: ReactComponentElement<any>
}

export const GlobalStateProvider = ({children}: Props) => {

    const store = useGlobalState();

    return (
        <Context.Provider value={store}>
            {children}
        </Context.Provider>
    )

};