import React, {CSSProperties, useState} from "react";
import {Button, Col, Row, Spinner, Stack, Table} from "react-bootstrap";
import {
    ArrowClockwise,
    CaretDownFill,
    CaretRightFill,
} from "react-bootstrap-icons";

import {LineItemsResource} from "../../../services/statements/StatementService";

export interface Props {
    data: Data[];
    loading: boolean;
    refresh?: () => void;
}

export interface Column {
    title: string;
    key: string;
    style?: CSSProperties;
}


export interface Data {
    "statementType": string;
    "ref": string;
    "createdDate": string;
    "price": string;
    "balance": string;
    "lineItemsResources": LineItemsResource[];
}


const RefreshableCollapsableTable = ({
                                         data,
                                         loading,
                                         refresh,
                                     }: Props) => {
    const [expandedRows, setExpandedRows] = useState<number[]>([]);

    const toggleRow = (index: number) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };

    return (
        <>
            <Row>
                <Col xs={11} lg={11}/>
                <Col xs={1} lg={1}>
                    <Stack
                        direction="horizontal"
                        gap={3}
                        className={"refreshableSearchableTable_actions"}
                    >
                        {refresh && (
                            <Button onClick={refresh} disabled={loading}>
                                {loading && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                {!loading && <ArrowClockwise />}
                            </Button>
                        )}
                    </Stack>

                </Col>
            </Row>
            <Table striped hover>
                <thead>
                <tr>
                    <th></th>
                    <th>Statement Type</th>
                    <th>Reference</th>
                    <th>Created Date</th>
                    <th>Price</th>
                    <th>Balance</th>
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td>
                                <Button
                                    variant="link"
                                    onClick={() => toggleRow(index)}
                                    className="toggle-button"
                                >
                                    {expandedRows.includes(index) ? (
                                        <CaretDownFill size={24}/>
                                    ) : (
                                        <CaretRightFill size={24}/>
                                    )}
                                </Button>
                            </td>
                            <td>{item.statementType}</td>
                            <td>{item.ref}</td>
                            <td>{item.createdDate}</td>
                            <td>{item.price}</td>
                            <td>{item.balance}</td>
                        </tr>
                        {expandedRows.includes(index) && (
                            <tr className="child-row">
                                <td colSpan={6}>
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th>Line Item</th>
                                            <th>Unit Price</th>
                                            <th>Quantity</th>
                                            <th>Length</th>
                                            <th>Total Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {item.lineItemsResources.map((lineItem, i) => (
                                            <tr key={i}>
                                                <td>{lineItem.title}</td>
                                                <td>{lineItem.unitPrice}</td>
                                                <td>{lineItem.quantity}</td>
                                                <td>{lineItem.length}</td>
                                                <td>{lineItem.totalPrice}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export default RefreshableCollapsableTable;
