import React, {useEffect, useState} from "react";
import AppPage from "../../components/page/AppPage";
import {Breadcrumb, BreadcrumbItem, Spinner} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {logApiError} from "../../../../services/api/ApiUtils";
import {CUSTOMERS, STATEMENTS, VIEW_CUSTOMER, VIEW_CUSTOMER_STATEMENT} from "../../PlatformStack";
import {StatementDetails, StatementService,} from "../../../../services/statements/StatementService";
import RefreshableCollapsableTable
    from "../../../../components/table/refreshableCollapsableTable/RefreshableCollapsableTable";
import dateFormat from "dateformat";


interface Props {
}

const SpecificStatement = ({}: Props) => {

    const {id} = useParams();
    const {customerName} = useParams();
    const {customerId} = useParams();
    const navigate = useNavigate();
    const {getStatement} = StatementService();
    const [loading, setLoading] = useState<boolean>();
    const [statement, setStatement] = useState<StatementDetails>();

    useEffect(() => {
        if (loading || !id) {
            return;
        }

        const loadStatement = async (statementId: string) => {
            setLoading(true);
            try {
                const resp = await getStatement(statementId);
                setStatement(resp);
            } catch (e) {
                logApiError("Error loading Statement.", e);
            } finally {
                setLoading(false);
            }
        };

        loadStatement(id);
    }, []);

    return (
        <AppPage>
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className={"heading"}>Statements</h3>
                        </div>
                    </div>
                    {loading && (
                        <>
                            <Spinner animation={"border"}/>
                            <p>Loading...</p>
                        </>
                    )}
                    {statement && !loading && (
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    {customerId && customerName ? (
                                        <Breadcrumb>
                                            <BreadcrumbItem onClick={() => navigate(CUSTOMERS.uri)}>
                                                Customers
                                            </BreadcrumbItem>
                                            <BreadcrumbItem
                                                onClick={() => navigate(VIEW_CUSTOMER.navigate([customerId]))}>
                                                {customerName}
                                            </BreadcrumbItem>
                                            <BreadcrumbItem
                                                onClick={() => navigate(VIEW_CUSTOMER_STATEMENT.navigate([customerId]))}>
                                                Statements
                                            </BreadcrumbItem>
                                            <BreadcrumbItem active>{statement.ref}</BreadcrumbItem>
                                        </Breadcrumb>) : <Breadcrumb>
                                        <BreadcrumbItem onClick={() => navigate(STATEMENTS.uri)}>
                                            Statement
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>{statement.ref}</BreadcrumbItem>
                                    </Breadcrumb>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className={"customer"}>{statement.customerName}</h4>
                                </div>
                                <div className="col-md-6">

                                    <b>From: </b>{dateFormat(new Date(statement.fromDate), 'dddd, mmmm dS yyyy')}
                                    <br />
                                    <b>To: </b>{dateFormat(new Date(statement.toDate), "dddd, mmmm dS yyyy")}
                                    <br />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ marginRight: '3em' }}>
                                    <ul className="customer-address">
                                        {statement.shippingAddress && (
                                            <>
                                                <b>Shipping Address</b>
                                                {statement.shippingAddress.lineOne && (
                                                    <li>{statement.shippingAddress.lineOne}</li>
                                                )}
                                                {statement.shippingAddress.lineTwo && (
                                                    <li>{statement.shippingAddress.lineTwo}</li>
                                                )}
                                                {statement.shippingAddress.lineThree && (
                                                    <li>{statement.shippingAddress.lineThree}</li>
                                                )}
                                                {statement.shippingAddress.lineFour && (
                                                    <li>{statement.shippingAddress.lineFour}</li>
                                                )}
                                                {statement.shippingAddress.lineFive && (
                                                    <li>{statement.shippingAddress.lineFive}</li>
                                                )}
                                                {statement.shippingAddress.postCode && (
                                                    <li>{statement.shippingAddress.postCode}</li>
                                                )}
                                            </>
                                        )}
                                    </ul>
                                </div>
                                <div>
                                    <ul className="customer-address">
                                        {statement.billingAddress && (
                                            <>
                                                <b>Billing Address</b>
                                                {statement.billingAddress.lineOne && (
                                                    <li>{statement.billingAddress.lineOne}</li>
                                                )}
                                                {statement.billingAddress.lineTwo && (
                                                    <li>{statement.billingAddress.lineTwo}</li>
                                                )}
                                                {statement.billingAddress.lineThree && (
                                                    <li>{statement.billingAddress.lineThree}</li>
                                                )}
                                                {statement.billingAddress.lineFour && (
                                                    <li>{statement.billingAddress.lineFour}</li>
                                                )}
                                                {statement.billingAddress.lineFive && (
                                                    <li>{statement.billingAddress.lineFive}</li>
                                                )}
                                                {statement.billingAddress.postCode && (
                                                    <li>{statement.billingAddress.postCode}</li>
                                                )}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {statement && (
                        <RefreshableCollapsableTable
                            data={statement.lineItems}
                            loading={false}
                        />
                    )}
                    <br />
                    <div className="row justify-content-end">
                        {statement && (
                            <div className="col-md-3 ml-auto">
                                <p className="fw-bold">Carry Over Balance: {statement.carryOverBalance}</p>
                            </div>
                        )}
                    </div>
                    <div className="row justify-content-end">
                        {statement && (
                            <div className="col-md-3 ml-auto">
                                <p className="fw-bold">Invoice Total: {statement.invoiceTotal}</p>
                            </div>
                        )}
                    </div>
                    <div className="row justify-content-end">
                        {statement && (
                            <div className="col-md-3 ml-auto">
                                <p className="fw-bold">Payments Total: {statement.paymentTotal}</p>
                            </div>
                        )}
                    </div>
                    <hr />
                    <div className="row justify-content-end">
                        {statement && (
                            <div className="col-md-3 ml-auto">
                                <p className="fw-bold">New Balance: {statement.newBalance}</p>
                            </div>
                        )}
                    </div>
                </div>
            </>
        </AppPage>
    );
};

export default SpecificStatement;
