import {AxiosResponse} from "axios";
import {basicAuthHeaderForCredentials} from "../api/ApiUtils";
import {SessionService} from "../session/SessionService";
import ApiClient from "../api/ApiClient";

export type Credentials = {
    email: string
    password: string
}

export const CredentialsService = () => {

    const { createSession } = SessionService();

    const createCredentials: (credentials: Credentials) => Promise<AxiosResponse<void>> = (credentials: Credentials) => {
        return ApiClient.getInstance().post<void>(`/api/1/credentials`, {}, {
            withCredentials: true,
            headers: {
                Authorization: basicAuthHeaderForCredentials(credentials)
            }
        });
    }

    const confirmEmail: (credentials: Credentials, code: string) => Promise<void> =
        // TODO :: This does not work as expected - no time to look into it now.
        // this should really pass the promise of the session back to the component.
        // i.e it's possible to navigate to the next page without having a valid session
        // which may cause problems if we need to hit the API immediately on the next page.
        async (credentials: Credentials, code: string) => {
        return ApiClient.getInstance().put<void>(`/api/1/confirmation?confirmationCode=${code}`, {}, {
            withCredentials: true,
            headers: {
                Authorization: basicAuthHeaderForCredentials(credentials)
            }
        })
            .then(() => {
                createSession(credentials);
            });
    }

    return {
        createCredentials,
        confirmEmail
    }
}
