import {useEffect, useState} from "react";
import {LocalStorageConfig, LocalStorageService} from "./LocalStorageService";

export interface Store {
    token: string;
    updateToken: (token: string) => void;

    accountId: string;
    updateAccountId: (accountId: string) => void;

    isLoaded: boolean;

    clear: () => void;
}

export const useGlobalState: () => Store = () => {

    const { getConfig, updateConfig, deleteConfig } = LocalStorageService();

    const [config, setConfig] = useState<LocalStorageConfig>();
    const [token, setToken] = useState<string>('');
    const [accountId, setAccountId] = useState<string>('');
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        loadConfig();
    }, []);

    useEffect(() => {
        if (!config) {
            return;
        }

        setToken(config?.token ? config.token : '');
        setAccountId(config?.accountId ? config.accountId : '');
        setLoaded(true);
    }, [config]);

    const loadConfig = () => {
        let loadedConfig = getConfig();
        setConfig(loadedConfig);
        if (!loadedConfig) {
            setLoaded(true);
        }
    }

    const updateToken = (token: string) => {
        updateLocalStorage({
            token
        });
    }

    const updateAccountId = (accountId: string) => {
        updateLocalStorage({
            accountId
        })
    }

    const clear = () => {
        deleteConfig();
        setConfig(undefined);
    }

    const updateLocalStorage: (updatedConfig: LocalStorageConfig) => void = (updatedConfig: LocalStorageConfig) => {
        const newConfig = updateConfig(updatedConfig);
        setConfig(newConfig);
    }

    return {
        token,
        updateToken,

        accountId,
        updateAccountId,

        isLoaded: loaded,

        clear
    };
};
