import ApiClient from "../api/ApiClient";

export interface Order {
    customerId: string;
    lines: OrderLine[];
}

export interface BaseOrderLine {
    variantId?: string;
    quantity: number;
    length?: number;
    lengthMeasurement?: string;
    fullLength?: boolean;
    type: string;
    title?: string;
    unitPrice?: number;
}

export interface TaxResource {
    id: string;
    code: string;
    name: string;
    description: string;
}

export interface OrderLine extends BaseOrderLine {
    created: string;
    unitPrice: number;
    unitPriceDisplayString: string;
    totalPriceDisplayString: string;
    lengthDisplayString: string;
}

export const OrderService = () => {

    const create = async (
        orderType: string,
        customerId: string,
        lines: BaseOrderLine[],
        cashOrder: boolean,
        defaultBillingAddressId?: string,
        defaultShippingAddressId?: string,
        contactId?: string,
        taxId?: string
    ): Promise<Order> => {
        return ApiClient.getInstance().post<Order>(`/api/1/orders`, {
            orderType,
            customerId,
            lines,
            cashOrder,
            defaultBillingAddressId,
            defaultShippingAddressId,
            contactId,
            taxId
        });
    };

    const createFromQuote = async (
        quoteId: string,
        cashOrder: boolean
    ): Promise<Order> => {
        return ApiClient.getInstance().post<Order>(`/api/1/orders`, {
            quoteId,
            cashOrder
        });
    };

    return {
        createOrder: create,
        createOrderFromQuote: createFromQuote,
    };
};
