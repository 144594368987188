import ApiClient from "../api/ApiClient";

export interface CustomerBase {
  id?: string;
  code: string;
  name: string;
  creditLimit?: number;
  creditLimitDisplayString?: string;
  customerBalance?: string;
  defaultContactId: string;
  defaultShippingAddressId: string;
  defaultBillingAddressId: string;
}

export interface Customer extends CustomerBase {
  id: string;
}

export interface PagedCustomer {
  page: number;
  size: number;
  totalCount: number;
  pageCount: number;
  cursor: object;
  resource: Customer[];
}

export const CustomerService = () => {
  const getAll = async (
    size: number,
    cursor?: object
  ): Promise<PagedCustomer> => {
    return ApiClient.getInstance().post<PagedCustomer>(
      `/api/1/customers-search`,
      {
        size: size,
        cursor: cursor ? cursor : undefined,
      }
    );
  };

  const search = async (value: string): Promise<PagedCustomer> => {
    return ApiClient.getInstance().post<PagedCustomer>(
      `/api/1/customers-search?search=${value}`
    );
  };

  const get = async (id: string): Promise<Customer> => {
    return ApiClient.getInstance().get<Customer>(`/api/1/customers/${id}`);
  };

  const create = async (
    code: string,
    name: string,
    creditLimit?: number
  ): Promise<Customer> => {
    return ApiClient.getInstance().post<Customer>(`/api/1/customers`, {
      code,
      name,
      creditLimit,
    });
  };

  const update = async (
    id: string,
    code: string,
    name: string,
    creditLimit?: number
  ): Promise<Customer> => {
    return ApiClient.getInstance().put<Customer>(`/api/1/customers/${id}`, {
      code,
      name,
      creditLimit,
    });
  };

  return {
    getCustomers: getAll,
    searchCustomers: search,
    getCustomer: get,
    createCustomer: create,
    updateCustomer: update,
  };
};
