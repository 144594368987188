import {AxiosResponse} from "axios";
import ApiClient from "../api/ApiClient";

export type Account = {
    id: string
    name: string
    stage: string
    status: string
    users: AccountUser[]
}

export type AccountUser = {
    userId: string
    accountId: string
    role: string
}

export const AccountService = () => {

    const getAllAccounts: () => Promise<AxiosResponse<Account[]>> = () => {
        return ApiClient.getInstance().get<Account[]>('/api/1/accounts');
    }

    const createAccount: (name: string) => Promise<AxiosResponse<Account>> = (name: string) => {
        return ApiClient.getInstance().post<Account>('/api/1/account', {
            name
        });
    }

    return {
        getAllAccounts,
        createAccount
    }
}