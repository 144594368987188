import React from "react";
import { Alert, Button, Form, Row } from "react-bootstrap";
import { InvykLogo } from "../../../../components/logo/InvykLogo";
import { Link, useNavigate } from "react-router-dom";
import { ACCOUNT_LIST, REGISTER } from "../../OnBoardingStack";
import * as yup from "yup";
import { logApiError } from "../../../../services/api/ApiUtils";
import { SessionService } from "../../../../services/session/SessionService";
import { Formik } from "formik";

export const LoginForm = () => {

    const navigate = useNavigate();
    const { createSession } = SessionService();

    const schema = yup.object().shape({
        email: yup.string().email().required("Email is required"),
        password: yup.string()
            .required("Password is required")
    });

    const submitForm = async (values: any) => {
        createSession({
            email: values.email,
            password: values.password
        }).then(() => {
            navigate(ACCOUNT_LIST.uri);
        }).catch((err) => {
            logApiError("Error logging in.", err);
        });
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={submitForm}
            initialValues={{
                email: "",
                password: ""
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <InvykLogo />

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />

                        {touched.email && errors.email && (
                            <Alert variant="danger">
                                {errors.email}
                            </Alert>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {touched.password && errors.password && (
                            <Alert variant="danger">
                                {errors.password}
                            </Alert>
                        )}
                    </Form.Group>

                    <Row>
                        <Link to={REGISTER.navigate([])}>
                            <Button variant="link">Not registered? Create an account.</Button>
                        </Link>
                    </Row>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};