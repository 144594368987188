import { useEffect, useReducer, useState } from "react";
import { Button, Card, Container, Row, Spinner } from "react-bootstrap";
import { logApiError } from "../../../../services/api/ApiUtils";
import { Customer } from "../../../../services/customer/CustomerService";
import {
    CutMeasurement,
    OrderFormActionType,
    OrderFormService,
    OrderType
} from "../../../../services/order/OrderFormService";
import { PriceBandService } from "../../../../services/pricing/PriceBandService";
import { PriceCalculatorService } from "../../../../services/pricing/PriceCalculatorService";
import CustomerOverCreditModal from "../modals/CustomerOverCreditLimitModal";
import CustomerBandSelection from "./CustomerBandSelection";
import CustomerSearch from "./CustomerSearch";
import OrderLines from "./Lines";


interface Props {
    type: OrderType
}

const OrderForm = ({ type }: Props) => {
    const { getPriceBands } = PriceBandService();
    const { getPrice } = PriceCalculatorService();
    const {
        reducer,
        initialState,
        submitForm,
    } = OrderFormService();

    const [state, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModel, setShowModel] = useState<boolean>(false);
    const [isCashOrder, setIsCashOrder] = useState<boolean>(false);

    useEffect(() => {
        getPriceBands().then((priceBands) =>
            dispatch({
                type: OrderFormActionType.PRICE_BANDS_LOADED,
                value: priceBands.resource
            })
        );
    }, []);

    const calculateDefaultPrice = (variantId: string) => {
        getPrice({
            variantId: variantId,
            customerId: state.customer?.id,
            quantity: 1,
            fullLength: true,
            lengthMeasurement: CutMeasurement.FEET
        }).then((price) => {
            dispatch({
                value: {
                    variantId: variantId,
                    price: price
                },
                type: OrderFormActionType.VARIANT_DEFAULT_PRICE_LOADED
            });
        });
    };

    const calculatePrice = (
        variantId: string,
        quantity: number,
        isFullLength: boolean,
        cutLength?: number,
        lengthMeasurement?: CutMeasurement
    ) => {
        getPrice({
            variantId: variantId,
            customerId: state.customer?.id,
            quantity: quantity,
            fullLength: isFullLength,
            length: cutLength,
            lengthMeasurement: lengthMeasurement
        }).then((price) => {
            dispatch({
                value: {
                    variantId: variantId,
                    cut: {
                        isFullLength: isFullLength,
                        length: cutLength,
                        measurement: lengthMeasurement
                    },
                    price: price
                },
                type: OrderFormActionType.CUT_PRICE_LOADED
            });
        });
    };

    const preProcessSend = () => {
        if (state.customer && isCustomerOverCreditLimit(state.customer)) {
            setShowModel(true);
            return;
        }

        submit();
    };

    const isCustomerOverCreditLimit = (customer: Customer): boolean => {
        let customerBalance =
            parseFloat((customer.customerBalance ?? "0").replace(/[^\d.-]/g, "")) ||
            0;

        let currentOrderValue = state.orderItems
            .flatMap((item) => item.lines?.map((line) => line.totalPrice ?? 0) ?? [])
            .reduce(
                (accumulator, currentValue) => (accumulator ?? 0) + (currentValue ?? 0),
                0
            );
        let currentOrderValueInPounds = currentOrderValue / 100;
        let creditLimit = customer.creditLimit ?? 0;
        return customerBalance + currentOrderValueInPounds > creditLimit;
    };

    const cancelOrder = () => {
        dispatch({
            type: OrderFormActionType.CLEAR
        });
        setShowModel(false);
    };

    const submit = async () => {
        setLoading(true);
        try {
            if (!state.customer) {
                return;
            }

            await submitForm(
                type,
                state.customer.id,
                state.orderItems,
                () => clear(),
                state.miscOrderItems,
                state.billingAddressId,
                state.shippingAddressId,
                state.contactId,
                state.taxId,
                isCashOrder
            );
        } catch (error) {
            logApiError("Error sending quote.", error);
        } finally {
            setLoading(false);
            setShowModel(false);
        }
    };

    const handleCashOrderUpdate = (cashOrderSelected: boolean) => {
        setIsCashOrder(cashOrderSelected);
        dispatch({
            type: OrderFormActionType.CASH_ORDER_SELECTED,
            value: cashOrderSelected
        });
    };

    const clear = () => {
        setLoading(true);
        try {
            dispatch({
                type: OrderFormActionType.CLEAR
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <Row
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginBottom: "1em"
                }}
            >
                {loading && <Spinner animation="border" />}
                {!loading && (
                    <>
                        <Button
                            variant={"outline-danger"}
                            style={{ marginRight: "0.5em", width: "auto" }}
                            onClick={clear}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={!state.canSend}
                            style={{ width: "auto" }}
                            onClick={() => preProcessSend()}
                        >
                            Create {type === OrderType.INVOICE ? "Invoice" : "Quote"}
                        </Button>
                    </>
                )}
            </Row>

            {!state.customer && <CustomerSearch dispatch={dispatch} />}
            {state.customer && (
                <CustomerBandSelection dispatch={dispatch} customer={state.customer} />
            )}
            <br />

            {type === OrderType.INVOICE && state.customer && <div>
              <Card>
                <Card.Body className="productForm_card_sectionContainer">
                  Payment
                </Card.Body>
                <Card.Body>
                  <div className="form-check">
                    <input
                        type="radio"
                        className="form-check-input"
                        onChange={(e) => handleCashOrderUpdate(!e.target.checked)}
                        checked={!isCashOrder}
                    />
                    Add to Account
                  </div>
                  <div className="form-check">
                    <input
                        type="radio"
                        className="form-check-input"
                        onChange={(e) => handleCashOrderUpdate(e.target.checked)}
                        checked={isCashOrder}
                    />
                    Cash
                  </div>
                </Card.Body>
              </Card>
            </div>}

            <OrderLines
                orderItems={state.orderItems}
                miscOrderItems={state.miscOrderItems}
                dispatch={dispatch}
                getDefaultPrice={calculateDefaultPrice}
                getPrice={calculatePrice}
            />
            <CustomerOverCreditModal
                show={showModel}
                close={() => setShowModel(false)}
                cancelOrder={cancelOrder}
                processOrder={() => {
                    setShowModel(false);
                    submit();
                }}
            />
        </Container>
    );
};

export default OrderForm;
