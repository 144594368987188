import ApiClient from "../api/ApiClient";
import {PagedResource} from "../api/common/Types";
import {Product} from "../product/ProductService";

export interface CategoryBase {
    id?: string
    name: string
    description: string
    colour: string
}

export interface Category extends CategoryBase {
    id: string
    products: Product[]
}

export interface PagedCategory extends PagedResource {
    resource: Category[]
}

export const CategoryService = () => {

    const getCategories = (async (size: number, cursor?: object): Promise<PagedCategory> => {
        return ApiClient.getInstance().get<PagedCategory>(`/api/1/categories?size=${size}`);
    });

    const search: (searchValue: string, page?: number, size?: number) => Promise<PagedCategory> = (async (searchValue: string,
                                                                                                          page,
                                                                                                          size) => {
        const pagingFilter: string = page && size ? `page=${page}&size=${size}` : '';
        return ApiClient.getInstance().get<PagedCategory>(`/api/1/categories?${pagingFilter}&filter=${searchValue}`);
    });

    const getCategory: (categoryId: string) => Promise<Category> = (async (categoryId: string) => {
        return ApiClient.getInstance().get<Category>(`/api/1/categories/${categoryId}`);
    });

    const createCategory: (category: CategoryBase) => Promise<Category> = (async (category: CategoryBase) => {
        return ApiClient.getInstance().post<Category>('/api/1/categories', category);
    });

    const updateCategory: (categoryId: string, category: CategoryBase) => Promise<Category> =
        (async (categoryId: string, category: CategoryBase) => {
        return ApiClient.getInstance().put<Category>(`/api/1/categories/${categoryId}`, category);
    });

    return {
        getCategories,
        searchCategories: search,
        getCategory,
        createCategory,
        updateCategory,
    }
}
