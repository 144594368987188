import { ChangeEvent, useEffect, useState } from "react";
import {
    Badge,
    Card,
    Col,
    Collapse,
    Form,
    Row,
    Spinner
} from "react-bootstrap";
import { CaretDownFill, CaretRightFill, X } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { logApiError } from "../../../../services/api/ApiUtils";
import { Customer } from "../../../../services/customer/CustomerService";
import {
    OrderFormAction,
    OrderFormActionType
} from "../../../../services/order/OrderFormService";
import { PriceBandAssignmentService } from "../../../../services/pricing/PriceBandAssignmentService";
import {
    PriceBand,
    PriceBandService
} from "../../../../services/pricing/PriceBandService";
import CustomerAddressSelection from "./CustomerAddressSelection";
import CustomerContactSelection from "./CustomerContactSelection";

interface Props {
    dispatch: (x: OrderFormAction) => void;
    customer: Customer;
}

const CustomerBandSelection = ({ dispatch, customer }: Props) => {

    const { getPriceBands } = PriceBandService();
    const { getPriceBandAssignmentForCustomer, createPriceBandAssignment } =
        PriceBandAssignmentService();

    const [pricing, setPricing] = useState<PriceBand[]>();
    const [assignedBandId, setAssignedBandId] = useState<string>();
    const [open, setOpen] = useState<boolean>(false);
    const [addressTabOpen, setAddressTabOpen] = useState<boolean>(false);
    const [contactTabOpen, setContactTabOpen] = useState<boolean>(false);

    useEffect(() => {
        loadBands();
        setAddressTabOpen(!customer.defaultBillingAddressId || !customer.defaultShippingAddressId)
        setContactTabOpen(!customer.defaultContactId)
    }, [customer]);

    const checkRadio = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        if (e.target.checked) {
            createPriceBandAssignment(id, "CUSTOMER", customer.id)
                .then(() => {
                    toast.success("Customer successfully assigned to band.");
                    dispatch({
                        type: OrderFormActionType.CUSTOMER_ASSIGNED_BAND,
                        value: id
                    });
                })
                .catch((e) => logApiError("Error assigning customer to band.", e))
                .finally(() => loadBands());
        }
    };

    const loadBands = () => {
        getPriceBands()
            .then(async (bands) => {
                setPricing(bands.resource);
                let priceBandAssignment = await getPriceBandAssignmentForCustomer(
                    customer.id
                );
                if (priceBandAssignment) {
                    dispatch({
                        type: OrderFormActionType.CUSTOMER_ASSIGNED_BAND,
                        value: priceBandAssignment.band.id
                    });
                    setAssignedBandId(priceBandAssignment.band.id);
                    setOpen(false);
                } else {
                    setOpen(true);
                }
            })
            .catch((e) => logApiError("Error loading customer pricing.", e));
    };

    return (
        <Card>
            <Card.Body className="productForm_card_sectionContainer">
                <Row>
                    <Col xs={10}>{customer.name}</Col>
                    <Col xs={2}>
                        <X
                            style={{ float: "right", cursor: "pointer" }}
                            size={25}
                            onClick={() =>
                                dispatch({
                                    type: OrderFormActionType.CUSTOMER_REMOVE
                                })
                            }
                        />
                    </Col>
                </Row>
            </Card.Body>
            <Card.Body
                className="productForm_card_sectionContainer"
                style={{ paddingLeft: "5em", paddingRight: "5em" }}
            >
        <span onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
          {open && <CaretDownFill />}
            {!open && <CaretRightFill />}
            <b>Pricing Assignment</b>
        </span>
                <Collapse in={open}>
                    <div style={{ marginTop: "2em" }}>
                        {!pricing && <Spinner animation="border" />}
                        {pricing && (
                            <>
                                <h6>Price Bands</h6>
                                <p>
                                    Select the price band this customer is assigned to in order to
                                    calculate their invoices.
                                </p>
                                {pricing.map((priceBand: PriceBand) => {
                                    return (
                                        <>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <Form.Check
                                                    type="radio"
                                                    name="customerForm.pricing.bands.default"
                                                    id={`priceForm.default.${priceBand.id}`}
                                                    label={priceBand.name}
                                                    checked={priceBand.id === assignedBandId}
                                                    style={{ marginRight: "0.5em" }}
                                                    onChange={(e) => {
                                                        checkRadio(e, priceBand.id);
                                                    }}
                                                />
                                                {priceBand.isDefault && <Badge>Default</Badge>}
                                            </div>
                                            <br />
                                        </>
                                    );
                                })}
                            </>
                        )}
                    </div>
                </Collapse>
                <div className="mt-3">
          <span
              onClick={() => setAddressTabOpen(!addressTabOpen)}
              style={{ cursor: "pointer" }}
          >
            {addressTabOpen && <CaretDownFill />}
              {!addressTabOpen && <CaretRightFill />}
              <b>Address</b>
          </span>
                    <Collapse in={addressTabOpen}>
                        <div style={{ marginTop: "2em" }}>
                            <CustomerAddressSelection
                                currentCustomerId={customer.id}
                                defaultBillingAddressId={customer.defaultBillingAddressId}
                                defaultShippingAddressId={customer.defaultShippingAddressId}
                                dispatch={dispatch}
                            />
                        </div>
                    </Collapse>
                </div>
                <div className="mt-3">
          <span
              onClick={() => setContactTabOpen(!contactTabOpen)}
              style={{ cursor: "pointer" }}
          >
            {contactTabOpen && <CaretDownFill />}
              {!contactTabOpen && <CaretRightFill />}
              <b>Contact</b>
          </span>
                    <Collapse in={contactTabOpen}>
                        <div style={{ marginTop: "2em" }}>
                            <CustomerContactSelection
                                currentCustomerId={customer.id}
                                defaultContactId={customer.defaultContactId}
                                dispatch={dispatch}
                            />
                        </div>
                    </Collapse>
                </div>
            </Card.Body>
        </Card>
    );
};

export default CustomerBandSelection;
