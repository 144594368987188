import ApiClient from "../api/ApiClient";
import {PriceBand} from "./PriceBandService";

export interface PriceBandAssignment {
    band: PriceBand
    parentType: string
    parentId: string
}

export const PriceBandAssignmentService = () => {

    const getForCustomer = (async (id: string): Promise<PriceBandAssignment> => {
        return ApiClient.getInstance().get<PriceBandAssignment>(`/api/1/price-band-assignments?customerId=${id}`);
    });

    const create = (async (bandId: string, parentType: string, parentId: string): Promise<PriceBandAssignment> => {
        return ApiClient.getInstance().post<PriceBandAssignment>(`/api/1/price-band-assignments`, {
            band: {
                id: bandId
            },
            parentType,
            parentId
        });
    });

    return {
        getPriceBandAssignmentForCustomer: getForCustomer,
        createPriceBandAssignment: create
    }
}