import {Data} from "../../components/table/refreshableSearchableTable/RefreshableSearchableTable";
import {logApiError} from "../api/ApiUtils";
import {Item, ItemService, PagedItem} from "./ItemService";

export interface ItemRow extends Data {
    id: string
    title: string
    code: string
    lastPurchaseWeight: string
    lastPurchasePrice: string
}

export interface ItemTablePage {
    totalCount: number
    pageCount: number
    page: number
    rows: ItemRow[]
}

export const ItemsTableService = () => {

    const { getItems } = ItemService();

    const getItemsForTable: (setLoading: (value: boolean) => void, page: number, size: number)
        => Promise<ItemTablePage> = (async (setLoading: (value: boolean) => void, page: number, size: number) => {
        setLoading(true);
        try {
            const pagedItem: PagedItem = await getItems(page, size);
            return {
                totalCount: pagedItem.totalCount,
                pageCount: pagedItem.pageCount,
                page: pagedItem.page,
                rows: pagedItem.resource.map((item: Item) => {
                    return {
                        id: item.id,
                        title: item.title,
                        code: item.code ? item.code : '',
                        lastPurchaseWeight: '',
                        lastPurchasePrice: ''
                    }
                })
            }
        } catch (e) {
            logApiError('Error loading items.', e);
        } finally {
            setLoading(false);
        }
        return new Promise(() => []);
    });

    const itemsToRows: (items: Item[]) => ItemRow[] = (items: Item[]) => {
        return items.map((item: Item) => {
            return {
                id: item.id,
                title: item.title,
                code: item.code ? item.code : '',
                lastPurchaseWeight: '',
                lastPurchasePrice: ''
            }
        })
    }

    const filter = ((items: ItemRow[], searchValue: string): ItemRow[] => {
        if (!searchValue) {
            return items;
        }
        return items.filter((item: ItemRow) =>
            item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.code.toLowerCase().includes(searchValue.toLowerCase()))
    });

    return {
        getItemsForTable,
        filter,
        itemsToRows,
        columns: [
            {
                title: 'Title',
                key: 'title'
            },
            {
                title: 'Code',
                key: 'code'
            },
            {
                title: 'Last Purchase Weight',
                key: 'lastPurchaseWeight'
            },
            {
                title: 'Last Purchase Price',
                key: 'lastPurchasePrice'
            },
        ]
    }
}
