import React, {ReactElement} from 'react';
import AppNavBar from "../navbar/AppNavBar";
import {useStore} from "../../../../services/state/useStore";
import {useNavigate} from "react-router-dom";
import {LOGIN} from "../../../onboarding/OnBoardingStack";

interface Props {
    children: ReactElement | ReactElement[]
}

const AppPage = ({children}: Props) => {

    const navigate = useNavigate();
    const { clear } = useStore();

    const logOut = () => {
        clear();
        navigate(LOGIN.uri);
    }

    return (
        <>
            <AppNavBar logOut={logOut} />
            <div style={{padding: '1.5em'}}>
                {children}
            </div>
        </>
    )
}

export default AppPage;