import {ProductVariantService, Variant, VariantAttributeBase} from "./ProductVariantService";
import {useState} from "react";
import {toast} from "react-toastify";
import {logApiError} from "../api/ApiUtils";

export interface Attribute {
    name: string
    value: string | undefined
    updateValue: (x: string | undefined) => void
    measurement: string | undefined
    updateMeasurement: (x: string | undefined) => void
}

export const VariantModalService = () => {

    const {
        createVariant,
        updateVariant
    } = ProductVariantService();

    const [variant, setVariant] = useState<Variant>();
    const [productId, setProductId] = useState<string>();

    const [width, setWidth] = useState<string>();
    const [widthMeasurement, setWidthMeasurement] = useState<string>();

    const [height, setHeight] = useState<string>();
    const [heightMeasurement, setHeightMeasurement] = useState<string>();

    const [thickness, setThickness] = useState<string>();
    const [thicknessMeasurement, setThicknessMeasurement] = useState<string>();

    const [diameter, setDiameter] = useState<string>();
    const [diameterMeasurement, setDiameterMeasurement] = useState<string>();

    const [weightPerMetre, setWeightPerMetre] = useState<string>();
    const [weightPerMetreMeasurement, setWeightPerMetreMeasurement] = useState<string>();

    const [meshHoleWidth, setMeshHoleWidth] = useState<string>();
    const [meshHoleWidthMeasurement, setMeshHoleWidthMeasurement] = useState<string>();

    const [meshHoleHeight, setMeshHoleHeight] = useState<string>();
    const [meshHoleHeightMeasurement, setMeshHoleHeightMeasurement] = useState<string>();

    const [depth, setDepth] = useState<string>();
    const [depthMeasurement, setDepthMeasurement] = useState<string>();

    const [length, setLength] = useState<string>();
    const [lengthMeasurement, setLengthMeasurement] = useState<string>();

    const attributes = [
        {
            name: 'Width',
            value: width,
            updateValue: setWidth,
            measurement: widthMeasurement,
            updateMeasurement: setWidthMeasurement
        },
        {
            name: 'Height',
            value: height,
            updateValue: setHeight,
            measurement: heightMeasurement,
            updateMeasurement: setHeightMeasurement
        },
        {
            name: 'Depth',
            value: depth,
            updateValue: setDepth,
            measurement: depthMeasurement,
            updateMeasurement: setDepthMeasurement
        },
        {
            name: 'Thickness',
            value: thickness,
            updateValue: setThickness,
            measurement: thicknessMeasurement,
            updateMeasurement: setThicknessMeasurement
        },
        {
            name: 'Weight per Metre',
            value: weightPerMetre,
            updateValue: setWeightPerMetre,
            measurement: weightPerMetreMeasurement,
            updateMeasurement: setWeightPerMetreMeasurement
        },
        {
            name: 'Mesh Hole Width',
            value: meshHoleWidth,
            updateValue: setMeshHoleWidth,
            measurement: meshHoleWidthMeasurement,
            updateMeasurement: setMeshHoleWidthMeasurement
        },
        {
            name: 'Mesh Hole Height',
            value: meshHoleHeight,
            updateValue: setMeshHoleHeight,
            measurement: meshHoleHeightMeasurement,
            updateMeasurement: setMeshHoleHeightMeasurement
        },
        {
            name: 'Diameter',
            value: diameter,
            updateValue: setDiameter,
            measurement: diameterMeasurement,
            updateMeasurement: setDiameterMeasurement
        },
        {
            name: 'Length',
            value: length,
            updateValue: setLength,
            measurement: lengthMeasurement,
            updateMeasurement: setLengthMeasurement
        },
    ];

    const initVariant = async (setLoading: (x: boolean) => void, productId: string, variant?: Variant) => {
        setLoading(true)
        try {
            setProductId(productId)
            setVariant(variant)

            setWidth(getAttributeValueFromVariant('WIDTH', variant))
            setWidthMeasurement(getAttributeMeasurementFromVariant('WIDTH', variant))

            setHeight(getAttributeValueFromVariant('HEIGHT', variant))
            setHeightMeasurement(getAttributeMeasurementFromVariant('HEIGHT', variant))

            setThickness(getAttributeValueFromVariant('THICKNESS', variant))
            setThicknessMeasurement(getAttributeMeasurementFromVariant('THICKNESS', variant))

            setDiameter(getAttributeValueFromVariant('DIAMETER', variant))
            setDiameterMeasurement(getAttributeMeasurementFromVariant('DIAMETER', variant))

            setWeightPerMetre(getAttributeValueFromVariant('WEIGHT_PER_METRE', variant))
            setWeightPerMetreMeasurement(getAttributeMeasurementFromVariant('WEIGHT_PER_METRE', variant))

            setMeshHoleWidth(getAttributeValueFromVariant('MESH_HOLE_WIDTH', variant))
            setMeshHoleWidthMeasurement(getAttributeMeasurementFromVariant('MESH_HOLE_WIDTH', variant))

            setMeshHoleHeight(getAttributeValueFromVariant('MESH_HOLE_HEIGHT', variant))
            setMeshHoleHeightMeasurement(getAttributeMeasurementFromVariant('MESH_HOLE_HEIGHT', variant))

            setDepth(getAttributeValueFromVariant('DEPTH', variant))
            setDepthMeasurement(getAttributeMeasurementFromVariant('DEPTH', variant))

            setLength(getAttributeValueFromVariant('LENGTH', variant))
            setLengthMeasurement(getAttributeMeasurementFromVariant('LENGTH', variant))
        } finally {
            setLoading(false)
        }

    }

    const submit = async (): Promise<boolean> => {
        if (variant) {
            return update();
        }
        return create();
    }

    const create = async (): Promise<boolean> => {
        if (!productId) {
            return false;
        }

        let variantAttributes: VariantAttributeBase[] = []
        for (const attribute of attributes) {
            if (!attribute.value) {
                continue;
            }
            if (!attribute.measurement) {
                toast.error(`Measurement type missing for ${attribute.name}`)
                return false;
            }
            variantAttributes.push({
                type: toUppercaseNoSpaces(attribute.name),
                measurement: toUppercaseNoSpaces(attribute.measurement),
                value: attribute.value,
            })
        }
        try {
            await createVariant(productId, variantAttributes);
        } catch (e) {
            logApiError("Error creating new variant.", e);
            return false;
        }
        return true;
    }

    const update = async (): Promise<boolean> => {
        if (!variant || !productId) {
            toast.error('Something isn\'t right. Please refresh the page and try again.')
            return false;
        }

        let variantAttributes: VariantAttributeBase[] = []
        for (const attribute of attributes) {
            if (!attribute.value) {
                continue;
            }
            if (!attribute.measurement) {
                toast.error(`Measurement type missing for ${attribute.name}`)
                return false;
            }
            variantAttributes.push({
                type: toUppercaseNoSpaces(attribute.name),
                measurement: toUppercaseNoSpaces(attribute.measurement),
                value: attribute.value,
            })
        }
        try {
            await updateVariant(productId, variant.id, variantAttributes);
        } catch (e) {
            logApiError("Error creating new variant.", e);
            return false;
        }
        return true;
    }

    const getAttributeValueFromVariant = (attribute: string, variant?: Variant) => {
        if (!variant || !variant.attributes) {
            return undefined;
        }

        let matchingVariantAttributes = variant.attributes.filter((a) => a.type === attribute);
        if (matchingVariantAttributes.length !== 1) {
            return undefined;
        }

        return matchingVariantAttributes[0].value
    }

    const getAttributeMeasurementFromVariant = (attribute: string, variant?: Variant) => {
        if (!variant || !variant.attributes) {
            return undefined;
        }

        let matchingVariantAttributes = variant.attributes.filter((a) => a.type === attribute);
        if (matchingVariantAttributes.length !== 1) {
            return undefined;
        }

        return matchingVariantAttributes[0].measurement
    }

    function toUppercaseNoSpaces(str: string) {
        return str.toUpperCase().replaceAll(' ', '_');
    }

    function priceToPriceRule(v: Variant, price: string, type: string) {
        return {
            parentId: v.id,
            parentType: 'VARIANT',
            priceType: type,
            currency: 'GBP',
            amount: parseFloat(price) * 100
        };
    }

    return {
        initVariant,
        submit,
        attributes,
    }
}