import {Formik} from "formik";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import * as yup from 'yup';
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {CredentialsService} from "../../../../services/credientials/CredentialsService";
import {toast} from "react-toastify";
import {logApiError} from "../../../../services/api/ApiUtils";
import {ACCOUNT_LIST, LOGIN} from "../../OnBoardingStack";

export const ConfirmEmailForm = () => {

    const location: any = useLocation();
    const { confirmEmail } = CredentialsService();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        code: yup.string().required("Code is required"),
    });

    useEffect(() => {
        if (location.state === null || location.state.email === undefined) {
            navigate(LOGIN.uri);
        }
    }, []);

    const submitForm = async (values: any) => {
        confirmEmail({
            email: location.state.email,
            password: location.state.password
        }, values.code)
            .then(r => {
                toast.success('Email confirmed.');
                navigate(ACCOUNT_LIST.uri);
            })
            .catch((err) => logApiError('Error confirming email.', err));
    }

    return <>
        <h1 className="cardHeading">Confirm email</h1>
        <p>We've sent a confirmation code to: {location.state?.email}</p>

        <Formik
            validationSchema={schema}
            onSubmit={submitForm}
            initialValues={{
                code: "",
            }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
              }) => (
                <Form
                    style={{
                        marginTop: "10px",
                        padding: "2rem",
                    }}
                    noValidate
                    onSubmit={handleSubmit}
                >

                    <Form.Group as={Row} className="mb-3" controlId="detailsForm.Code">
                        <Form.Label column xs="4">
                            Code:
                        </Form.Label>
                        <Col xs="8">
                            <Form.Control
                                required
                                className="form-input"
                                type="text"
                                name="code"
                                placeholder="xxxx"
                                value={values.code}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Col>
                        {touched.code && errors.code && (
                            <Alert variant="danger">
                                {errors.code}
                            </Alert>
                        )}
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Confirm
                    </Button>
                </Form>
            )}
        </Formik>
    </>
}